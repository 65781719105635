import type { EditorProps } from 'types/tinyMCE'
import hasCaseDetailAttribute from 'utils/caseDetails/hasCaseDetailAttribute'

const remindUserToEditCaseDetailsInSidebar = (editorProps: EditorProps) => {
  const { editor, setToast } = editorProps

  if (!setToast) return

  let lastToastTime = 0
  const TOAST_COOLDOWN = 5000

  editor.on('click', (e: MouseEvent) => {
    const now = Date.now()
    // prevent spamming the user with toasts
    const isWithinCooldown = now - lastToastTime < TOAST_COOLDOWN
    if (isWithinCooldown) return

    const { target } = e
    const isCaseDetail = hasCaseDetailAttribute(target)

    if (isCaseDetail) {
      lastToastTime = now
      setToast({
        icon: 'info',
        text: 'Edit case details in the case tab sidebar.',
      })
    }
  })
}

export default remindUserToEditCaseDetailsInSidebar
