import { useEffect } from 'react'

const useOutsideClick = (
  ref: React.RefObject<HTMLElement>,
  active: boolean,
  callback: () => void,
  type: 'mousedown' | 'click',
) => {
  const handleClick = (e: MouseEvent | TouchEvent) =>
    active &&
    ref.current &&
    !ref.current.contains(e.target as Node) &&
    callback()
  useEffect(() => {
    document.addEventListener(type || 'click', handleClick)
    return () => document.removeEventListener(type || 'click', handleClick)
  })
}

export default useOutsideClick
