import { useState } from 'react'
import Flex from 'components/priority/Flex/Flex'
import { InsertCustomVerse, UpdateCustomVerse } from './'

export default ({ verse, setShow }) => {
  const [editing, setEditing] = useState(false)

  return (
    <Flex column margin="24px" align="flex-start">
      {editing ? (
        <UpdateCustomVerse
          verse={verse}
          setShow={setShow}
          setEditing={setEditing}
        />
      ) : (
        <InsertCustomVerse
          verse={verse}
          setShow={setShow}
          setEditing={setEditing}
        />
      )}
    </Flex>
  )
}
