import { useState } from 'react'
import { Button } from 'components'
import { useSetToast } from 'hooks'
import { migrateVerses } from 'api/admin'

export default () => {
  const [loading, setLoading] = useState(false)
  const setToast = useSetToast()

  const launchThemes = async () => {
    setLoading(true)

    try {
      await migrateVerses()

      setToast({
        icon: 'check',
        text: 'Verses transfer requested successfully',
      })
    } catch (error) {
      setToast({
        text:
          error.message === 'Request timed out'
            ? 'Operation timed out. Please try again later.'
            : 'Failed to transfer verses',
      })
    } finally {
      setLoading(false)
    }
  }

  const buttonText = loading
    ? '🎨 Requesting transfer...'
    : '🎨 Request to transfer over verses from S3'

  return (
    <Button primary onClick={launchThemes} disabled={loading}>
      {buttonText}
    </Button>
  )
}
