import styled, { css } from 'styled-components'
import propsToCSS from 'utils/styling/propsToCSS'
import elements from 'styles/elements'
import sizing from 'styles/sizing'
import spacing from 'styles/spacing'
import type { ButtonStyledProps } from 'types/button'

const propStyles = {
  default: elements.default,
  primary: elements.primary,
  secondary: elements.secondary,
  outline: elements.outline,
  destructive: elements.destructive,
} as const

/**
 * Styled button component that combines multiple style functions:
 * elements, sizing, spacing, and propsToCSS.
 */
export const Button = styled.button<ButtonStyledProps>(
  ({ center, ...props }) => css`
    display: flex;
    justify-content: ${center ? 'center' : 'space-between'};
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    padding: 14px;
    user-select: none;
    cursor: pointer;
    height: 42px;
    white-space: nowrap;

    ${propsToCSS(props, propStyles)}
    ${sizing(props)}
    ${spacing(props)}
  `,
)
