import * as S from './RedMargin.styled'
import { useRecoilValue } from 'recoil'
import { showRedMarginAtom } from 'atoms'

type RedMarginProps = {
  height: number
  width: number
  thickness: number
  color: string
  showVerticalCenter?: boolean
  showHorizontalCenter?: boolean
}

const RedMargin = ({
  height,
  width,
  thickness,
  color,
  showVerticalCenter = false,
  showHorizontalCenter = false,
}: RedMarginProps) => {
  const showRedMargin = useRecoilValue(showRedMarginAtom)
  if (!showRedMargin) return null

  // add possibility of horizontal center margin and vertical center margin
  return (
    <S.Container height={height} width={width}> 
      <S.TopFrame color={color} thickness={thickness} />
      <S.RightFrame color={color} thickness={thickness} />
      <S.BottomFrame color={color} thickness={thickness} />
      <S.LeftFrame color={color} thickness={thickness} />
      <S.TopLeftCorner color={color} thickness={thickness} />
      <S.TopRightCorner color={color} thickness={thickness} />
      <S.BottomLeftCorner color={color} thickness={thickness} />
      <S.BottomRightCorner color={color} thickness={thickness} />
      {showVerticalCenter && (
        <S.VerticalCenterFrame color={color} thickness={thickness} />
      )}
      {showHorizontalCenter && (
        <S.HorizontalCenterFrame color={color} thickness={thickness} />
      )}
    </S.Container>
  )
}

export default RedMargin
