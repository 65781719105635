type RegExPatterns = {
  email: RegExp
  externalLink: RegExp
  protocolLink: RegExp
  urlPath: RegExp
  oneOrTwo: RegExp
  threeOrFour: RegExp
  fiveOrSix: RegExp
  lettersOnly: RegExp
  colorFormatIdentifier: RegExp
  // match a specific part of a URL, specifically the portion that follows the protocol (like http:// or https://
  urlDomainExtractor: RegExp
  // match URLs that start with either http:// or https://
  urlScheme: RegExp
  // match a specific URL path that includes the /auth/ segment followed by a 36-character string that consists of hexadecimal digits and hyphens
  authUUID: RegExp
  // match any uppercase letter from A to Z
  uppercaseLetter: RegExp
  // match single or double quotation marks in a string
  singleDoubleQuote: RegExp
  // match text that is enclosed within parentheses
  enclosedInParentheses: RegExp
  // Example:
  // const str = "This   is a   test.\nNew line here.";
  // const result = str.replace(/\s+/g, ' '); // Replaces multiple whitespace with a single space
  // console.log(result); // Output: "This is a test. New line here."
  multipleWhiteSpace: RegExp
  // matches hexadecimal color codes and RGBA color values
  // example matches #FFF, #FFFFFF, #123456, rgba(255,255,255,0.5), rgb(255,255,255)
  // example invalid matches #GGG (invalid hex code), rgb(255,0) (missing values), rgba(255,0,0,0,0) (too many values)
  validColor: RegExp
  // matches variable placeholders in a string like ${variable}
  dollarCurlyBraces: RegExp
  validInlineFontSize: RegExp
  validInlineTextAlign: RegExp
  // matches a single comma surrounded by zero or more whitespace characters
  // ^ - Start of string
  // [\s]* - Zero or more whitespace characters
  // , - Exactly one comma
  // [\s]* - Zero or more whitespace characters
  // $ - End of string
  hasSingleComma: RegExp
}

// TODO: Utilize this file for all regEx patterns in the app
const regEx: RegExPatterns = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  externalLink: /((http|https):\/[\w\\.\\/\-=?#]+)/,
  protocolLink: /^(#|mailto:|tel:)/,
  urlPath: /[^/]+$/,
  urlScheme: /^https?:\/\//i,
  dollarCurlyBraces: /{\$([^}]*)}/g,
  validColor: /^(#[0-9A-Fa-f]{3,8}|rgba?\(.*\))$/,
  multipleWhiteSpace: /\s+/g,
  enclosedInParentheses: /\((.*?)\)/,
  singleDoubleQuote: /('|")/g,
  uppercaseLetter: /[A-Z]/g,
  authUUID: /\/auth\/([0-9a-fA-F-]{36})/,
  urlDomainExtractor: /:\/\/([^\\/]+)/,
  lettersOnly: /^[a-z]+$/i,
  colorFormatIdentifier: /^(#|rgb|rgba|hsl|hsla)/,
  oneOrTwo: /[1-2]/,
  threeOrFour: /[3-4]/,
  fiveOrSix: /[5-6]/,
  validInlineFontSize: /font-size\s*:\s*([^;]+);?/i,
  validInlineTextAlign: /text-align\s*:\s*([^;]+);?/i,
  hasSingleComma: /^[\s]*,[\s]*$/,
}

export default regEx
