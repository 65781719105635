import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import { getOrder } from 'api/orders'
import { getCase } from 'api/cases'
import { caseAtom, pageLoadingAtom } from 'atoms'
import { PageLoading } from 'components/page'
import { useSetToast } from 'hooks'
import regEx from 'utils/global/regEx'

export const extractUUID = (path) => {
  const match = path.match(regEx.authUUID)
  return match ? match[1] : null
}

const Auth = () => {
  const setCaseInfo = useSetRecoilState(caseAtom)
  const setPageLoading = useSetRecoilState(pageLoadingAtom)
  const setToast = useSetToast()

  useEffect(() => {
    const location = window.location.pathname
    const messengerOrderIdTokenFromURL = extractUUID(location)

    const checkIfOrderExists = async () => {
      if (!messengerOrderIdTokenFromURL) {
        setPageLoading(false)
        window.location.href = '/'
        return
      }

      try {
        setPageLoading(true)
        // Set the token in localStorage
        localStorage.setItem(
          'messengerOrderIdToken',
          messengerOrderIdTokenFromURL,
        )

        // Verify that the token is set
        const storedTokenInLocalStorage = localStorage.getItem(
          'messengerOrderIdToken',
        )
        let orderResponse = null
        if (storedTokenInLocalStorage === messengerOrderIdTokenFromURL) {
          // Call getOrder only if the token is correctly set
          orderResponse = await getOrder(messengerOrderIdTokenFromURL)
        } else {
          console.error('Failed to set messengerOrderIdToken in localStorage')
        }

        if (orderResponse.status === 200) {
          const caseResponse = await getCase(orderResponse.data.case_id)
          setCaseInfo(caseResponse.data)

          const currentUrlParams = new URLSearchParams(window.location.search)
          const newUrlParams = new URLSearchParams()

          currentUrlParams.forEach((value, key) => {
            newUrlParams.append(key, value)
          })

          // Add a fake loading delay
          setTimeout(() => {
            setPageLoading(false)
            window.location.href = `/preview/${messengerOrderIdTokenFromURL}?${newUrlParams.toString()}`
          }, 1000)
        } else {
          throw new Error('Order does not exist')
        }
      } catch (error) {
        setToast({ text: 'There was a problem validating the order' })
        console.error('Error:', error.message)

        setTimeout(() => {
          localStorage.removeItem('messengerOrderIdToken')
          setPageLoading(false)
          window.location.href = '/'
        }, 2000)
      }
    }

    checkIfOrderExists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCaseInfo, setPageLoading])

  return (
    <>
      <PageLoading />
    </>
  )
}

export default Auth
