const casesMockTable = new Array(10).fill(undefined).map(() => ({
  id: '',
  created_at: '',
  editable_case_id: '',
  name_of_deceased: '',
  date_of_death: '',
  family_contact_name: '',
  archived: '',
}))

export default casesMockTable
