import { useState, useEffect } from 'react'
import { toastsAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import Flex from 'components/priority/Flex/Flex'
import remove from 'lodash/remove'
import Toast from './Toast'
import zLayers from 'utils/styling/zLayers'
import { v4 as uuidv4 } from 'uuid'

const MAX_TOASTS = 5

export default () => {
  const [toasts, setToasts] = useRecoilState(toastsAtom)
  const [queue, setQueue] = useState([])

  useEffect(() => {
    if (toasts.length > 0) {
      const toastsWithValidIds = toasts.map(toast => {
        const shouldGenerateNewId = !toast.uuid || toast.uuid === ''
        if (shouldGenerateNewId) {
          return { ...toast, uuid: uuidv4() }
        }
        return toast
      })
      const currentlyDisplayedToastIds = new Set(queue.map(toast => toast.uuid))
      const newUniqueToasts = toastsWithValidIds.filter(toast => !currentlyDisplayedToastIds.has(toast.uuid))
      const allToastsToDisplay = [...queue, ...newUniqueToasts]
      const mostRecentToasts = allToastsToDisplay.slice(-MAX_TOASTS)
      setQueue(mostRecentToasts)
      setToasts([])
    }
    // eslint-disable-next-line
  }, [toasts])

  const timerFinished = (uuid) => {
    const newQueue = [...queue]
    remove(newQueue, (toast) => toast.uuid === uuid)
    setQueue(newQueue)
  }

  return (
    <Flex
      column
      position="fixed"
      bottom="12px"
      left="12px"
      zindex={zLayers.toast}
    >
      {queue
        ?.slice(0)
        ?.reverse()
        ?.map(({ uuid, ...props }, i) => (
          <Toast
            key={`toast-${uuid}-${i}`}
            uuid={uuid}
            index={i}
            timerFinished={timerFinished}
            {...props}
          />
        ))}
    </Flex>
  )
}
