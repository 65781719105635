import { useRef, useState, useEffect, useCallback } from 'react'
import { useActions, useFillCaseImages, useSetToast } from 'hooks'
import { uploadCaseImage } from 'api/cases'
import {
  caseAtom,
  itemsLockedAtom,
  objectSelector,
  pageLoadingAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import {
  DesignAssetModal,
  FileUploadHandler,
  ToolbarIcon,
  useFileUpload,
} from './'
import { FullscreenModal } from 'components'
import Flex from 'components/priority/Flex/Flex'
import DesignerGate from 'components/auth/DesignerGate'
import Divider from 'components/global/Divider/Divider'

const useToolbarActions = (action) => {
  return {
    addText: useCallback(() => action.insertText(), [action]),
    addShape: useCallback(() => action.insertShape(), [action]),
    addQRCode: useCallback(() => action.insertQRCode(), [action]),
    addLine: useCallback(() => action.insertLine(), [action]),
    zoomOut: useCallback(() => action.zoomOut(), [action]),
    zoomIn: useCallback(() => action.zoomIn(), [action]),
    previousPage: useCallback(() => action.previousPage(), [action]),
    nextPage: useCallback(() => action.nextPage(), [action]),
  }
}

// Components
const ToolbarButton = ({ icon, tooltip, onClick, disabled }) => (
  <ToolbarIcon
    icon={icon}
    tooltip={tooltip}
    onClick={onClick}
    disabled={disabled}
  />
)

// Main component
export default ({ setShowSlideout }) => {
  const handleError = useErrorHandler('EditorToolbar')
  const setToast = useSetToast()
  const inputFile = useRef(null)
  const action = useActions()
  const [_case, setPersonalImages] = useRecoilState(
    objectSelector({ atom: caseAtom, property: 'personal_images' }),
  )
  const [pageLoading, setPageLoading] = useRecoilState(pageLoadingAtom)
  const itemsLocked = useRecoilValue(itemsLockedAtom)
  const fillCaseImages = useFillCaseImages()
  const [newImage, setNewImage] = useState(null)
  const [showDesignAssetModal, setShowDesignAssetModal] = useState(false)

  const uploadFunction = (fd) => {
    try {
      setPageLoading(true)
      return uploadCaseImage(_case.id, fd)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
      setPageLoading(false)
    }
  }

  const setImages = (data) => {
    setPageLoading(true)
    setPersonalImages(data.personal_images)
    fillCaseImages(data.personal_images)
    setNewImage({
      caseImage: data.personal_images.length - 1,
      url: data.personal_images[data.personal_images.length - 1],
    })
    setPageLoading(false)
  }

  const handleCaseImageUpload = useFileUpload({
    uploadFunction,
    setImages,
  })

  const toolbarActions = useToolbarActions(action)

  useEffect(() => {
    if (!newImage) return

    if (!itemsLocked) {
      action.insertImage(newImage)
    } else {
      setToast({
        icon: 'check',
        text: 'Your image was saved to the photo collection but not inserted due to fast-track mode.',
      })
    }

    setNewImage(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_case.filledImages, action, newImage])

  const designAssetModalProps = {
    setShowDesignAssetModal,
  }

  return (
    <>
      <Flex
        center
        padding="0 12px"
        position="absolute"
        bottom="24px"
        height="48px"
        radius="4px"
        background="lockedAlmostBlack"
      >
        <FileUploadHandler
          inputRef={inputFile}
          onChange={handleCaseImageUpload}
        >
          <ToolbarButton
            icon="editorImage"
            tooltip="Add image"
            onClick={() => inputFile.current.click()}
          />
        </FileUploadHandler>

        <DesignerGate>
          <ToolbarButton
            icon="designAssets"
            tooltip="Add asset"
            onClick={() => setShowDesignAssetModal(true)}
          />
        </DesignerGate>

        <ToolbarButton
          icon="editorText"
          tooltip="Add text"
          onClick={toolbarActions.addText}
          disabled={itemsLocked}
        />
        <ToolbarButton
          icon="editorShape"
          tooltip="Add opacity box"
          onClick={toolbarActions.addShape}
          disabled={itemsLocked}
        />
        <ToolbarButton
          icon="qrCode"
          tooltip="Add QR Code"
          onClick={toolbarActions.addQRCode}
          disabled={itemsLocked}
        />
        <ToolbarButton
          icon="editorLine"
          tooltip="Add line"
          onClick={toolbarActions.addLine}
          disabled={itemsLocked}
        />

        <Divider vertical fill="lockedGray2" margin="0 6px" />

        <ToolbarButton
          icon="editorZoomOut"
          tooltip="Zoom out"
          onClick={toolbarActions.zoomOut}
        />
        <ToolbarButton
          icon="editorZoomIn"
          tooltip="Zoom in"
          onClick={toolbarActions.zoomIn}
        />

        <Divider vertical fill="lockedGray2" margin="0 6px" />

        <ToolbarButton
          icon="editorPreviousPage"
          tooltip="Previous page"
          onClick={toolbarActions.previousPage}
          disabled={pageLoading}
        />
        <ToolbarButton
          icon="editorShuffler"
          tooltip="Shuffle pages"
          onClick={() => setShowSlideout(true)}
          disabled={pageLoading}
        />
        <ToolbarButton
          icon="editorNextPage"
          tooltip="Next page"
          onClick={toolbarActions.nextPage}
          disabled={pageLoading}
        />
      </Flex>
      <FullscreenModal show={[showDesignAssetModal, setShowDesignAssetModal]}>
        <DesignAssetModal {...designAssetModalProps} />
      </FullscreenModal>
    </>
  )
}
