import { SelectedTheme } from 'types/theme'

const persistFontColorUponEmptiedTextBox = (
  editor: Editor,
  fontColor: string,
) => {
  setTimeout(() => {
    const selectedNode: any = editor?.selection?.getNode()
    // textContent is a getter, so we need to check if it's empty
    // without assigning it to a variable
    if (selectedNode.textContent?.length === 0) {
      selectedNode.style.color = fontColor
    }
  }, 500)
}

/**
 * Plugin to persist styling in TinyMCE editor.
 */
const helpPersistStyling = (editorProps: {
  selectedTheme: SelectedTheme
  editor: Editor
}) => {
  const { editor, selectedTheme } = editorProps

  // TinyMCE loses styling when user deletes all text and presses backspace one more time.
  // Tell selected node to keep its styling when HTML element is removed.
  const fontColor = selectedTheme?.font_color
  if (!fontColor) return
  editor.on('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      persistFontColorUponEmptiedTextBox(editor, fontColor)
    }
  })
}

export default helpPersistStyling
