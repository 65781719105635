import { textAutosizeTriggerAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { sanitizeHtmlWithoutFontSizes } from 'utils'

export default (props) => {
  const { setItemState } = props
  setItemState('loading')

  autosize(props)
}

const MODIFIER_PERCENTAGE = 1
const SMALLEST_FONT_ALLOWED = 8
const GRACE_DISTANCE = 10
const DEBUG_MODE = false

const autosize = (props) => {
  const {
    internalWidth,
    internalHeight,
    setItemState,
    TinyMCERef,
    applyText,
    resizingState,
  } = props

  if (!TinyMCERef?.current) return

  const textNotInitialized = !internalWidth || !internalHeight
  if (textNotInitialized) return

  setItemState('loading')

  const contentWidth = TinyMCERef.current.bodyElement?.offsetWidth
  const contentHeight = TinyMCERef.current.bodyElement?.offsetHeight
  const content = { width: contentWidth, height: contentHeight }

  const wrapper = { width: internalWidth, height: internalHeight }
  const internal = { width: internalWidth, height: internalHeight }
  const sizes = { content, wrapper, internal }

  const resizeStrategyProps = { ...props, sizes }
  const resizeStrategy = determineResizeStrategy(resizeStrategyProps)

  const isWithinGraceBoundaries = resizeStrategy === 'within-grace-boundaries'
  const isGrowing = resizeStrategy === 'increase-font-size'
  const isIdle = resizingState === 'idle'
  const shouldNotGrow = isGrowing && isIdle
  const shouldNotAutosize = isWithinGraceBoundaries || shouldNotGrow

  if (shouldNotAutosize) {
    setItemState('idle')
    return
  }

  const initialContent = TinyMCERef.current.getContent()
  const isInitialContentEmpty = initialContent.trim() === ''

  if (isInitialContentEmpty) {
    setItemState('idle')
    applyText(initialContent)
    return
  }

  const dimensionProps = { html: initialContent, elementWidth: internal.width }
  const initialDimensions = measureTextDimensions(dimensionProps)

  const updateHTMLProps = {
    ...props,
    initialContent,
    initialDimensions,
    resizeStrategy,
    sizes,
    iteration: 1,
    currentContent: initialContent,
  }

  resizeText(updateHTMLProps)
}

const determineResizeStrategy = (props) => {
  const { sizes } = props
  const { content, wrapper } = sizes

  // determine if the content is within the grace boundaries
  const widthDifference = Math.abs(content.width - wrapper.width)
  const heightDifference = Math.abs(content.height - wrapper.height)

  const widthWithinGraceBoundaries = widthDifference < GRACE_DISTANCE
  const heightWithinGraceBoundaries = heightDifference < GRACE_DISTANCE

  const withinGraceBoundaries =
    heightWithinGraceBoundaries && widthWithinGraceBoundaries

  if (withinGraceBoundaries) return 'within-grace-boundaries'

  // determine if the content is larger or smaller than the wrapper
  const contentWidthIsLarger = content.width > wrapper.width
  const contentHeightIsLarger = content.height > wrapper.height

  const contentIsLargerThanWrapper =
    contentWidthIsLarger || contentHeightIsLarger

  if (contentIsLargerThanWrapper) return 'decrease-font-size'

  // determine if the content is smaller than the wrapper
  const wrapperWidthIsLarger = content.width < wrapper.width
  const wrapperHeightIsLarger = content.height < wrapper.height

  const contentIsSmallerThanWrapper =
    wrapperWidthIsLarger || wrapperHeightIsLarger

  if (contentIsSmallerThanWrapper) return 'increase-font-size'

  // if none of the above conditions are met, return unknown
  return 'unknown'
}

const resizeText = (props) => {
  const { resizeStrategy, sizes, applyText, setItemState } = props
  const { currentContent, iteration } = props
  const { internal, wrapper } = sizes

  const emergencyStop = iteration >= 1000
  if (emergencyStop) {
    setItemState('idle')
    applyText(currentContent)
    return
  }

  const isShrinking = resizeStrategy === 'decrease-font-size'
  const isGrowing = resizeStrategy === 'increase-font-size'

  const adjustFontSizesProps = {
    html: currentContent,
    type: resizeStrategy,
    modifier: iteration,
  }

  const adjustedFontSizes = updateHtml(adjustFontSizesProps)
  const { updatedHtml: resizedContent, smallestFontSize } = adjustedFontSizes

  const dimensionProps = { html: resizedContent, elementWidth: internal.width }
  const resizedDimensions = measureTextDimensions(dimensionProps)

  const reachedSmallestFontSize = smallestFontSize <= SMALLEST_FONT_ALLOWED
  const isOverflowing = reachedSmallestFontSize && isShrinking

  if (isOverflowing) {
    setItemState('content-overflowing')
    applyText(currentContent)
    return
  }

  if (isGrowing) {
    const contentWidthIsLarger = resizedDimensions.width > wrapper.width
    const contentHeightIsLarger = resizedDimensions.height > wrapper.height
    const contentIsLargerThanWrapper =
      contentWidthIsLarger || contentHeightIsLarger

    if (contentIsLargerThanWrapper) {
      applyText(currentContent)
      setItemState('idle')
      return
    }
  }

  if (isShrinking) {
    const contentWidthIsSmaller = resizedDimensions.width <= wrapper.width
    const contentHeightIsSmaller = resizedDimensions.height <= wrapper.height
    const contentIsSmallerThanWrapper =
      contentWidthIsSmaller && contentHeightIsSmaller

    if (contentIsSmallerThanWrapper) {
      applyText(resizedContent)
      setItemState('idle')
      return
    }
  }

  const nextIteration = iteration + 1
  const updateHTMLProps = {
    ...props,
    currentContent: resizedContent,
    iteration: nextIteration,
  }

  resizeText(updateHTMLProps)
}

const styleMeasurementDiv = () => {
  const measureContainerCss = `
  .measure-html-container {
    position: absolute;
    max-width: var(--element-width, auto);
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow-x: visible;
    margin: 0px;
    padding: 0 2px;
  }

  .measure-html-container p {
    margin: 0;
  }
`

  const styleId = 'measure-html-container-styles'

  const existingStyleTag = document.getElementById(styleId)
  if (!existingStyleTag) {
    const styleTag = document.createElement('style')
    styleTag.id = styleId
    styleTag.innerHTML = measureContainerCss
    document.head.appendChild(styleTag)
  }
}

const measureTextDimensions = (props) => {
  const { html, elementWidth } = props
  if (!html) return { width: 0, height: 0 }

  const container = document.createElement('div')

  styleMeasurementDiv()
  container.style.setProperty('--element-width', `${elementWidth}px`)
  container.classList.add('measure-html-container')

  if (DEBUG_MODE) {
    container.style.top = '80px'
    container.style.left = '260px'
    container.style.backgroundColor = 'rgba(255, 255, 255, 0.25)'
  }

  if (!DEBUG_MODE) {
    container.style.visibility = 'hidden'
    container.style.pointerEvents = 'none'
  }

  container.innerHTML = html
  document.body.appendChild(container)

  const width = container?.offsetWidth
  const height = container?.offsetHeight

  const timeoutTimer = DEBUG_MODE ? 5000 : 0
  setTimeout(() => {
    container.remove()
  }, timeoutTimer)

  return { width, height }
}

const updateHtml = (props) => {
  const { type, modifer = 1 } = props
  let { html } = props

  const isGrowing = type === 'increase-font-size'
  const isShrinking = type === 'decrease-font-size'
  const isUnknown = !isGrowing && !isShrinking

  if (isUnknown) {
    const errorMessage = `Type must be either "increase-font-size" or "decrease-font-size"`
    throw new Error(errorMessage)
  }

  const increasePercentage = 1 + (MODIFIER_PERCENTAGE * modifer) / 100
  const decreasePercentage = 1 - (MODIFIER_PERCENTAGE * modifer) / 100

  const multiplier = isGrowing ? increasePercentage : decreasePercentage

  const fontSizeRegex = /font-size\s*:\s*([\d.]+)pt/gi
  const lineHeightRegex = /line-height\s*:\s*([\d.]+)([a-zA-Z%]*)/gi
  const matches = [...html.matchAll(fontSizeRegex)]

  const noMatchesFound = matches.length === 0
  if (noMatchesFound) html = sanitizeHtmlWithoutFontSizes(html)

  const fontSizes = matches.map((match) => parseFloat(match[1]))
  const smallestFontSize = fontSizes.length ? Math.min(...fontSizes) : null

  let updatedHtml = html.replace(fontSizeRegex, (fullMatch, numStr) => {
    const newValue = (parseFloat(numStr) * multiplier).toFixed(2)
    return `font-size: ${newValue}pt`
  })

  updatedHtml = updatedHtml.replace(
    lineHeightRegex,
    (fullMatch, numStr, unit) => {
      const newValue = (parseFloat(numStr) * multiplier).toFixed(2)
      return `line-height: ${newValue}${unit}`
    },
  )

  return { updatedHtml, smallestFontSize }
}

export const useResizeObserver = (props) => {
  const { resizingState } = props

  if (!resizingState) return
  if (resizingState === 'stop') autosize(props)
}

export const useTriggerAutosize = () => {
  const setTextAutosizeTrigger = useSetRecoilState(textAutosizeTriggerAtom)
  const triggerUpdate = () => setTextAutosizeTrigger(Date.now())

  return triggerUpdate
}
