import * as S from './DropIcon.styled'
import * as icons from 'assets/icons'

type DropIconProps = {
  up: boolean
  icon: keyof typeof icons
}

const DropIcon = ({ up, icon, ...props }: DropIconProps) => (
  <S.DropIcon up={up ? 1 : 0} icon={icon || 'chevronDown'} {...props} />
)

export default DropIcon
