const DEFAULT_DYNAMIC_ATTRIBUTES = [
  'data-case-detail',
  'data-case-detail-table',
  'data-template',
  'data-template-item',
] as const

/**
 * Checks if an HTML element has any of the specified attributes
 * @param target The HTML element target
 * @param attributes Array of attribute names to check for
 * @returns boolean indicating if any attribute is present
 */
const hasAnyAttribute = (
  target: EventTarget | null,
  attributes = DEFAULT_DYNAMIC_ATTRIBUTES,
) => {
  if (!(target instanceof HTMLElement)) return false
  return attributes.some(
    (attr) =>
      target.hasAttribute?.(attr) ||
      target.querySelector?.(`[${attr}]`) ||
      target.closest?.(`[${attr}]`),
  )
}

export default hasAnyAttribute
