const ordersMockTable = new Array(10).fill(undefined).map(() => ({
  case: {
    name_of_deceased: '',
    id: '',
    date_of_death: '',
    editable_case_id: '',
  },
  sortable_date_of_death: '',
  collection_name: '',
  created_by_name: '',
  created_at: '',
  sortable_created_at: '',
}))

export default ordersMockTable
