import { useState, useEffect } from 'react'
import { Navigation, Tabs } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import {
  AccountSettings,
  InfoAndLogos,
  Preferences,
  PrintSettings,
  StaffMembers,
  DefaultTemplates,
} from './'
import {
  getFuneralHomeSettings,
  updateFuneralHomeSettings,
} from 'api/funeralHomeSettings'
import { useRecoilCallback } from 'recoil'
import { settingsFieldsAtom, settingsValuesAtom } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler('Settings')
  const [activeTab, setActiveTab] = useState('Staff members')

  const [pairs, setPairs] = useState([])

  const getSettings = async () => {
    try {
      const resp = await getFuneralHomeSettings()
      mapValuePairs(resp.data)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const loadInitialData = useRecoilCallback(({ set }) => (pairs) => {
    const ids = []
    for (const field of pairs) {
      //pairs is an array of object from case => [{name_of_deceased: "john smith"}]
      //first create an array of 'ids' these will be unique keys stored in the caseFieldsAtom atom
      ids.push(field.id)
      //then store the object on the caseValuesAtom atom family
      set(settingsValuesAtom(field.id), () => field)
    }
    set(settingsFieldsAtom, ids)
  })

  useEffect(() => {
    loadInitialData(pairs)
  }, [loadInitialData, pairs])

  const mapValuePairs = (settings) => {
    //map the case object into an array of object for each key/value
    const mapped = settings
      ? Object.keys(settings).map((key) => ({
          id: key,
          value: settings[key],
        }))
      : []
    setPairs(mapped)
  }

  useEffect(
    () => {
      getSettings()
    },
    // eslint-disable-next-line
    [],
  )

  const saveSettings = useRecoilCallback(({ snapshot }) => async () => {
    try {
      //get a snapshot of the recoil state (this is fuzzy to me still)
      //get the ids from the caseFieldsAtom atom
      const ids = await snapshot.getPromise(settingsFieldsAtom)
      //create an empty array to store the returned values
      const allValues = []
      //extract the case_id for the patch
      let settings_id = ''
      //this code came from the same sample listed above
      //for each id get the caseValue - which is the key/value object
      //push that to the array and save the case_id seperately
      for (const field of ids) {
        const settingsValue = await snapshot.getPromise(
          settingsValuesAtom(field),
        )
        if (settingsValue.id === 'id') settings_id = settingsValue.value
        allValues.push(settingsValue)
      }
      //map the array of key/value objects back into one flat object
      var mapped = allValues.map((item) => ({ [item.id]: item.value }))
      const payload = Object.assign({}, ...mapped)
      //prep to send
      //the server will reject a payload with certain properties, this will remove those and then send the payload.
      delete payload.id
      const { data } = await updateFuneralHomeSettings(settings_id, payload)
      //on response update the pairs which triggers the data load
      mapValuePairs(data)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  })

  return (
    <Flex column>
      <Navigation />
      <Flex
        padding="0 40px"
        margin="0 0 48px 0"
        height="94px"
        background="paleSecondary"
        align="center"
      >
        <Text size="34px" weight="600">
          Settings
        </Text>
      </Flex>
      <Tabs
        tabs={[
          { title: 'Staff members', content: <StaffMembers /> },
          { title: 'Funeral home info and logos', content: <InfoAndLogos /> },
          { title: 'Account settings', content: <AccountSettings /> },
          {
            title: 'Preferences',
            content: <Preferences saveSettings={saveSettings} />,
          },
          {
            title: 'Templates',
            content: <DefaultTemplates saveSettings={saveSettings} />,
          },
          {
            title: 'Print settings',
            content: <PrintSettings saveSettings={saveSettings} />,
          },
        ]}
        selected={[activeTab, setActiveTab]}
      />
    </Flex>
  )
}
