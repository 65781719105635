const e = {
  personalInfo: "personalInfo",
  familyInfo: "familyInfo",
  additionalFamily: "additionalFamily",
  serviceInfo: "serviceInfo",
  musicSelections: "musicSelections",
  pallBearersSocieties: "pallBearersSocieties",
  intermentInfo: "intermentInfo",
  memorialContributions: "memorialContributions",
  branchOfService: "branchOfService",
  firingParty: "firingParty",
  honors: "honors",
  station: "station"
}, i = {
  prefix: "prefix",
  first: "first",
  nickname: "nickname",
  middle: "middle",
  last: "last",
  suffix: "suffix",
  maiden: "maiden"
}, t = {
  prefix: "Prefix",
  first: "First Name",
  nickname: '"Nickname"',
  middle: "Middle Name",
  last: "Last Name",
  suffix: "Suffix",
  maiden: "(Maiden Name)"
}, r = {
  sectionLabel: "sectionLabel",
  input: "input",
  date: "date",
  array: "array",
  mappedArray: "mappedArray",
  nestedArray: "nestedArray",
  textarea: "textarea",
  check: "check",
  blank: "blank"
}, a = {
  sectionLabel: "section_label",
  editableCaseId: "editable_case_id",
  prefix: "prefix_dec",
  firstName: "first_dec",
  nickName: "nick_name_dec",
  middleName: "middle_dec",
  maidenName: "maiden_dec",
  lastName: "lastdec",
  // TODO: backend uses lastdec, so have to use this for now (should be last_dec)
  suffix: "suffix_dec",
  dateOfBirth: "date_of_birth",
  dateOfDeath: "date_of_death",
  placeOfBirth: "place_of_birth",
  placeOfDeath: "place_of_death",
  motherOfDeceased: "mother_of_deceased",
  fatherOfDeceased: "father_of_deceased",
  spouse: "spouse",
  siblings: "siblings",
  children: "children",
  additionalFamily: "additional_family",
  services: "services",
  orderOfService: "order_of_service",
  clergy: "clergy",
  songs: "songs",
  specialSongs: "special_songs",
  pallbearers: "pallbearers",
  organizations: "organizations",
  memorialContributions: "memorial_contributions",
  intermentTime: "interment_time",
  intermentDate: "interment_date",
  intermentPlace: "interment_place",
  intermentAddress: "interment_address",
  intermentCity: "interment_city",
  intermentState: "interment_state",
  intermentPostalCode: "interment_postal_code",
  branchOfService: "branch_of_service",
  placeEnteredService: "place_entered_service",
  rankDischargedFromService: "rank_discharged_from_service",
  serviceNumber: "service_number",
  dateEnteredService: "date_entered_service",
  dateDischargedFromService: "date_discharged_from_service",
  ncoicName: "ncoic_name",
  chaplain: "chaplain",
  oicName: "oic_name",
  buglarAndOrColorGuard: "buglar_and_or_color_guard",
  firingPartiesForFuneralService: "firing_parties_for_funeral_service",
  distinguishedServiceMedal: "distinguished_service_medal",
  purpleHeart: "purple_heart_medal",
  silverStar: "silver_star_medal",
  medalOfHonor: "medal_of_honor",
  airforceCross: "air_force_cross_medal",
  navyCross: "navy_cross_medal",
  bronzeStar: "bronze_star_medal",
  blank: "blank",
  personProvidingHonors: "person_providing_honors",
  otherMedals: "other_medals",
  usStationsWhereServed: "us_stations_where_served"
}, n = {
  personalInfo: "Personal Information",
  editableCaseId: "Custom case ID",
  prefix: "Prefix name",
  firstName: "First name",
  nickName: "Nickname",
  middleName: "Middle name",
  maidenName: "Maiden name",
  lastName: "Last name",
  suffix: "Suffix name",
  dateOfBirth: "Date of birth *",
  dateOfDeath: "Date of death",
  placeOfBirth: "Place of birth",
  placeOfDeath: "Place of death",
  parents: "Parents",
  motherOfDeceased: "Mother full name",
  fatherOfDeceased: "Father full name",
  spouse: "Spouse full name",
  siblings: "Siblings",
  sibling: "Sibling",
  children: "Children",
  child: "Child",
  additionalFamily: "Additional Family",
  precededBySurvivedByGrandchildren: "Preceded By, Survived By, Grandchildren etc.",
  addAnotherGroup: "Add Another Group",
  orderOfService: "Order of service",
  clergy: "Clergy",
  musicSelections: "Music Selections",
  specialSongs: "Special Songs",
  song: "Song",
  intermentInfo: "Interment Info",
  intermentTime: "Interment Time",
  intermentDate: "Interment Date",
  intermentPlace: "Interment Place",
  intermentAddress: "Interment Address",
  intermentCity: "Interment City",
  intermentState: "Interment State",
  intermentPostalCode: "Interment Postal Code",
  branchOfService: "Branch of Service",
  placeEnteredService: "Place entered service",
  rankDischargedFromService: "Rank discharged",
  serviceNumber: "Service number",
  dateEnteredService: "Date entered service",
  dateDischargedFromService: "Date discharged",
  firingParty: "Firing Party",
  ncoicName: "NCOIC",
  chaplain: "Chaplain",
  oicName: "OIC",
  buglarAndOrColorGuard: "Bugler/color guard",
  firingPartiesForFuneralService: "Firing Parties for Funeral Service",
  party: "Party",
  honors: "Honors",
  distinguishedServiceMedal: "Distinguished Service Medal",
  purpleHeart: "Purple Heart",
  silverStar: "Silver Star",
  medalOfHonor: "Medal of Honor",
  airforceCross: "Airforce Cross",
  navyCross: "Navy Cross",
  bronzeStar: "Bronze Star",
  personProvidingHonors: "Person providing honors",
  addOtherMedal: "Add other medal",
  medal: "Medal"
}, l = {
  visitation: "visitation",
  service: "service",
  pallbearer: "pallbearer",
  honoraryPallbearer: "honorary pallbearer",
  organization: "organization",
  memorialContribution: "memorial contribution",
  stations: "Stations"
}, s = {
  additionalFamily: ["name", "relation", "city", "state", "country"],
  services: [
    { field: "location", type: "input" },
    { field: "city", type: "input" },
    { field: "state", type: "input" },
    { field: "date", type: "date" },
    { field: "start_time", type: "input" },
    { field: "end_time", type: "input" },
    { field: "clergy_place", type: "input" },
    { field: "room_chapel", type: "input" },
    { field: "address", type: "input" },
    { field: "postal_code", type: "input" },
    { field: "country", type: "input" },
    { field: "default", type: "check" }
  ],
  pallbearers: [
    { field: "name", type: "input" },
    { field: "sequence", type: "input" }
  ],
  organizations: [
    { field: "name", type: "input" },
    { field: "address1", type: "input" },
    { field: "address2", type: "input" },
    { field: "city", type: "input" },
    { field: "state", type: "input" },
    { field: "zip_code", type: "input" },
    { field: "country", type: "input" },
    { field: "website_url", type: "input" },
    { field: "sequence", type: "input" }
  ],
  memorialContributions: [
    { field: "charity_of_choice", type: "check" },
    { field: "name", type: "input" },
    { field: "sequence", type: "input" }
  ],
  stations: [
    { field: "station_name", type: "input" },
    { field: "departure_date", type: "date" },
    { field: "departure_place", type: "input" },
    { field: "return_date", type: "date" },
    { field: "return_place", type: "input" }
  ]
}, o = {
  service: {
    location: null,
    city: null,
    state: null,
    date: "",
    time: null,
    start_time: "",
    end_time: null,
    clergy_place: null,
    room_chapel: null,
    address: null,
    postal_code: null,
    country: null,
    default: !1
  },
  additionalFamily: {
    name: null,
    relation: null,
    city: null,
    state: null,
    country: null
  },
  pallbearers: {
    name: null,
    sequence: null
  },
  organizations: {
    name: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip_code: null,
    country: null,
    website_url: null,
    sequence: null
  },
  memorialContributions: {
    charity_of_choice: !1,
    name: null,
    sequence: null
  },
  station: {
    station_name: null,
    departure_date: /* @__PURE__ */ new Date(),
    departure_place: null,
    return_date: /* @__PURE__ */ new Date(),
    return_place: null
  }
}, d = {
  hidden: {
    sequence: "sequence"
  }
};
export {
  e as caseFormSections,
  n as caseFormSectionsLabels,
  s as formFieldAllowedInputs,
  a as formFieldKeys,
  r as formFieldTypes,
  i as nameComponents,
  t as nameComponentsFormatted,
  o as newObjects,
  d as specialFieldBehaviors,
  l as typeKeys
};
