import { useState, useEffect } from 'react'
import {
  ThemeCard,
  NewThemeModal,
  FullscreenModal,
  Button,
  DigitalAgreement,
} from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import {
  getCustomThemes,
  createCustomTheme,
  archiveCustomTheme,
} from 'api/customThemes'
import { useSetToast, useNavigate } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler('CustomThemesDashboard')
  const [customThemes, setCustomThemes] = useState([])
  const [showNewThemeModal, setShowNewThemeModal] = useState(false)
  const [showFullModal, setShowFullModal] = useState(false)
  const setToast = useSetToast()
  const navigate = useNavigate()

  const getThemes = async () => {
    try {
      const { data } = await getCustomThemes()
      setCustomThemes(data)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const editTheme = (theme) => {
    navigate(`/customThemes/${theme.id}`)
  }

  const deleteTheme = async (theme) => {
    try {
      const resp = await archiveCustomTheme(theme.id)
      if (resp.status === 200) {
        setToast({
          text: 'Theme Deleted',
          icon: 'check',
        })
      }
      getThemes()
    } catch (err) {
      handleError(err?.response?.data?.message || err.message, err)
    }
  }

  const createTheme = async (payload) => {
    try {
      const resp = await createCustomTheme(payload)
      if (resp.status === 201) {
        setToast({
          text: 'Theme Created',
          icon: 'check',
        })
        navigate(`/customThemes/${resp.data.id}`)
      }
      getThemes()
    } catch (err) {
      handleError(err?.response?.data?.message || err.message, err)
    }
  }

  const agreeToTerms = () => {
    setShowFullModal(false)
    setShowNewThemeModal(true)
  }

  useEffect(() => {
    getThemes()
    // eslint-disable-next-line
  }, [])

  return (
    <Flex full column>
      <Flex
        padding="24px 40px"
        margin="0 0 48px 0"
        height="94px"
        background="paleSecondary"
        align="center"
        justify="space-between"
        full
      >
        <Text size="34px" weight="600">
          Custom Themes
        </Text>
        <Button primary icon="plus" onClick={() => setShowFullModal(true)}>
          New Custom Theme
        </Button>
        <FullscreenModal show={[showFullModal, setShowFullModal]}>
          <DigitalAgreement
            setShowFullModal={setShowFullModal}
            agreeToTerms={agreeToTerms}
          />
        </FullscreenModal>
        <FullscreenModal show={[showNewThemeModal, setShowNewThemeModal]}>
          <NewThemeModal
            createTheme={createTheme}
            setShowNewThemeModal={setShowNewThemeModal}
          />
        </FullscreenModal>
      </Flex>
      <Flex full wrap="wrap" justify="center">
        {customThemes.map((theme) => (
          <ThemeCard
            key={theme.id}
            theme={theme}
            editTheme={editTheme}
            deleteTheme={deleteTheme}
            noHover={showFullModal || showNewThemeModal}
          />
        ))}
      </Flex>
    </Flex>
  )
}
