import { useRecoilValue } from 'recoil'
import {
  roleAtom,
  caseAtom,
  orderAtom,
  selectedProductsAtom,
  layoutAtom,
  funeralHomeAtom,
  selectedThemeAtom,
  stylizedVersesAtom,
  itemsLockedAtom,
  editorOrderPageSetAtom,
  editorPagesAtom,
  showPinturaAtom,
  showRedMarginAtom,
  insetRulerAtom,
  showRulerAtom,
} from 'atoms'

export type RecoilLogData = {
  role: unknown
  case: unknown
  order: unknown
  selectedProducts: unknown
  layout: unknown
  funeralHome: unknown
  selectedTheme: unknown
  stylizedVerses: unknown
  itemsLocked: unknown
  editorOrderPageSet: unknown
  editorPages: unknown
  showPintura: unknown
  showRedMargin: unknown
  insetRuler: unknown
  showRuler: unknown
}

export const useRecoilLogData = (): RecoilLogData => {
  return {
    role: useRecoilValue(roleAtom),
    case: useRecoilValue(caseAtom),
    order: useRecoilValue(orderAtom),
    selectedProducts: useRecoilValue(selectedProductsAtom),
    layout: useRecoilValue(layoutAtom),
    funeralHome: useRecoilValue(funeralHomeAtom),
    selectedTheme: useRecoilValue(selectedThemeAtom),
    stylizedVerses: useRecoilValue(stylizedVersesAtom),
    itemsLocked: useRecoilValue(itemsLockedAtom),
    editorOrderPageSet: useRecoilValue(editorOrderPageSetAtom),
    editorPages: useRecoilValue(editorPagesAtom),
    showPintura: useRecoilValue(showPinturaAtom),
    showRedMargin: useRecoilValue(showRedMarginAtom),
    insetRuler: useRecoilValue(insetRulerAtom),
    showRuler: useRecoilValue(showRulerAtom),
  }
}
