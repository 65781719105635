import { useState, useEffect } from 'react'
import { CustomizePrint, Navigation } from 'components'
import { caseAtom, funeralHomeAtom } from 'atoms'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { themeCategories } from 'mocks'
import { getFuneralHome } from 'api/funeralHomes'
import { useDynamicStyles, useStonlyWidget } from 'hooks'
import previewGlobal from 'styles/previewGlobal'
import formatDate from 'utils/dates/formatDate'
import { useLocation } from 'react-router-dom'

export default () => {
  const caseInfo = useRecoilValue(caseAtom)
  const [caseHeader, setCaseHeader] = useState('')
  const setFh = useSetRecoilState(funeralHomeAtom)
  const location = useLocation()

  useDynamicStyles(previewGlobal)
  useStonlyWidget()

  // Force fresh data fetch on mount and when URL changes
  useEffect(() => {
    const getUserFuneralHome = async () => {
      try {
        const response = await getFuneralHome()
        return response
      } catch (error) {
        throw error
      }
    }

    setFh({})

    getUserFuneralHome()
      .then((res) => {
        setFh(res)
      })
      .catch((err) => {
        console.error(err)
      })
    // eslint-disable-next-line
  }, [location.key])

  useEffect(() => {
    caseInfo?.name_of_deceased
      ? setCaseHeader(
          `${caseInfo?.name_of_deceased}, ${
            caseInfo?.editable_case_id ||
            formatDate(caseInfo?.date_of_death, 'PP')
          }`,
        )
      : setCaseHeader('')
  }, [caseInfo])

  return (
    <>
      <Navigation type="package" />

      <CustomizePrint
        caseInfo={caseHeader}
        categories={themeCategories}
        key={location.key}
      />
    </>
  )
}
