import { useState, useMemo } from 'react'
import {
  LoadingInsertImages,
  SearchBar,
  AssetDrawers,
  Button,
} from 'components'
import Flex from 'components/priority/Flex/Flex'

export default ({ setShow, setCreatingVerse, verses, loading }) => {
  const [search, setSearch] = useState('')

  const filteredVerses = useMemo(() => {
    if (search.trim() === '') {
      return verses
    } else {
      const escapedSearch = search.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
      const searchRegex = new RegExp(escapedSearch, 'i')
      return verses.filter((verse) => searchRegex.test(verse.reference))
    }
  }, [verses, search])

  return loading ? (
    <LoadingInsertImages type="verses" />
  ) : (
    <>
      <Flex margin="12px 0 24px 0">
        <SearchBar
          placeholder="Search"
          value={[search, setSearch]}
          margin="0 12px 0 24px"
          width="400px"
        />
        <Button
          icon="plus"
          primary
          margin="0 24px 0 0"
          onClick={() => setCreatingVerse(true)}
        >
          Add a verse
        </Button>
      </Flex>
      <Flex style={{ overflow: 'auto', minHeight: 'calc(100% - 66px)' }} column>
        <Flex full center>
          <AssetDrawers
            drawers={filteredVerses}
            categoryKey="html"
            categoryNameKey="reference"
            nameKey="html"
            setShow={setShow}
            type="customVerse"
          />
        </Flex>
      </Flex>
    </>
  )
}
