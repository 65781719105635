import * as S from './ResizeControls.styled'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import {
  selectedItemAtom,
  itemAtomFamily,
  itemSelector,
  openSwapImageMenuAtom,
  itemsLockedAtom,
} from 'atoms'
import { ResizeHandleElement } from 'components/page/Items/ResizeHandle'

export default ({
  x,
  y,
  z,
  width,
  height,
  rotate,
  internalWidth: [internalWidth, setInternalWidth] = [0, () => {}],
  internalHeight: [internalHeight, setInternalHeight] = [0, () => {}],
  type,
  selected,
  setWidth,
  setHeight,
  disabled,
  isEditing,
  resizingState: [resizingState, setResizingState] = ['idle', () => {}],
  children,
}) => {
  const selectedItem = useRecoilValue(selectedItemAtom)
  const itemLocked = useRecoilValue(itemsLockedAtom)
  const deleteItem = useResetRecoilState(itemAtomFamily(selectedItem))
  const [openSwapImageMenu, setOpenSwapImageMenu] = useRecoilState(
    openSwapImageMenuAtom,
  )
  const [imageEdits, setImageEdits] = useRecoilState(
    itemSelector({ id: selectedItem, path: 'image.edits' }),
  )

  const handlePlacements = ['se']

  const minConstraints = type === 'line' ? [2, 2] : [20, 20]
  const axis = type === 'line' ? 'x' : 'both'
  const isImage = type === 'image'
  const showSwapImage = isImage

  const setImageEditsState = (width, height) => {
    const imageEditsTmp = { ...imageEdits }
    const updatedWidth = {
      width,
      height,
    }
    imageEditsTmp.targetSize = updatedWidth
    setImageEdits(imageEditsTmp)
  }

  const _openSwapImageMenu = () => {
    setOpenSwapImageMenu(!openSwapImageMenu)
  }

  const onResizeStart = () => {
    setResizingState('start')
  }

  const onResize = (event, { size: { width, height } }) => {
    setInternalWidth(width)
    setInternalHeight(height)
    setResizingState('resizing')
  }

  const onResizeStop = (event, { size: { width, height } }) => {
    const roundedWidth = Math.round(width)
    const roundedHeight = Math.round(height)
    setWidth(roundedWidth)
    setHeight(roundedHeight)
    setImageEditsState(roundedWidth, roundedHeight)
    setResizingState('stop')
    setTimeout(() => setResizingState('idle'), 0)
  }

  const lockedAspectRatioItem = [
    'image',
    'watermark',
    'designAsset',
    'qrCode',
  ].includes(type)

  return (
    <S.ResizeControls
      height={internalHeight || 0}
      width={internalWidth || 0}
      rotate={rotate}
      onResizeStart={onResizeStart}
      onResize={onResize}
      onResizeStop={onResizeStop}
      minConstraints={minConstraints}
      selected={selected}
      resizeHandles={handlePlacements}
      lockAspectRatio={lockedAspectRatioItem}
      axis={axis}
      disabled={disabled}
      handle={(position, ref) => {
        return (
          <ResizeHandleElement
            innerRef={ref}
            itemLocked={itemLocked}
            x={x}
            y={y}
            z={z}
            height={height}
            width={width}
            position={position}
            selected={selected}
          />
        )
      }}
    >
      <>
        {!isEditing && (
          <>
            <S.DeleteItem
              z={z}
              selected={selected}
              onClick={() => deleteItem()}
              fill="brand"
              icon="exitModal"
            />
            {showSwapImage && (
              <S.SwapImage
                z={z}
                selected={selected}
                onClick={() => _openSwapImageMenu()}
                fill="none"
                icon="swap"
              />
            )}
            <S.LockItem
              z={z}
              selected={selected}
              $itemLocked={!itemLocked}
              fill="none"
              icon="lock"
            />
          </>
        )}
        {children}
      </>
    </S.ResizeControls>
  )
}
