type HtmlData = {
  encode: (data: any) => string
  decode: (data: string) => any
}

// TODO: Consider error handling improvements:
// 1. Add stricter type checking for input data
// 2. Add validation for encoded strings
// 3. Add custom error types for different failure cases
// 4. Consider throwing instead of silent fails
// 5. Add proper error boundaries in consuming components
const handleHtmlData: HtmlData = {
  encode: (data) => {
    try {
      const stringifiedData = JSON.stringify(data)
      return encodeURIComponent(stringifiedData)
    } catch (error) {
      console.error('Error encoding HTML data:', error, { data })
      return encodeURIComponent(String(data))
    }
  },
  decode: (data) => {
    try {
      const decodedData = decodeURIComponent(data)
      return JSON.parse(decodedData)
    } catch (error) {
      console.error('Error decoding HTML data:', error, { data })
      try {
        return JSON.parse(data)
      } catch (parseError) {
        console.error('Error parsing original data:', parseError)
        return null
      }
    }
  },
}

export default handleHtmlData
