const caseDetails = {
  id: '00000000-5a90-86ef-bae6-110300c3dce6',
  funeral_home_id: '00000000-59eb-9390-c81b-8b030040ec1a',
  created_at: '2018-02-23T21:26:07.497Z',
  updated_at: '2021-06-22T22:54:36.879Z',
  editable_case_id: '15122',
  name_of_deceased: 'Marie Booker',
  date_of_birth: '1955-10-29T00:00:00.000Z',
  place_of_birth: 'Vienna',
  date_of_death: '1993-10-12T00:00:00.000Z',
  place_of_death: 'Paris',
  religion: 'Roman Catholic',
  address_of_deceased: 'Versailles',
  mother_of_deceased: 'Maria Theresa',
  father_of_deceased: 'Francis I, Holy Roman Emperor',
  spouse: 'Louis XVI, King of France',
  marital_status: 'Widow',
  place_of_marriage: null,
  date_of_marriage: null,
  grandchildren: null,
  occupation: 'Queen',
  family_contact_name: null,
  family_contact_address: null,
  children: ['Marie Thérèse', 'Louis Joseph', 'Louis XVII', 'Princess Sophie'],
  siblings: ['Maria Corolina', 'Emperor Leopold II'],
  personal_images: [
    '4o4m8eimage.jpeg',
    '1faafimage.jpeg',
    'dg5g1simage.jpeg',
    'ifb97image.jpeg',
    '1548183326921',
  ],
  organizations: null,
  songs: ['Song One', 'Song Two', 'Song Three', 'Song Four'],
  special_songs: ['Special Song One', 'Special Song Two', 'Special Song Three'],
  pallbearers: [
    'Pallbearer One',
    'Pallbearer Two',
    'Pallbearer Three',
    'Pallbearer Four',
    'Pallbearer Five',
    'Pallbearer Six',
  ],
  honorary_pallbearers: ['Peasant One', 'Peasant Two', 'Peasant Three'],
  societies: ['Lords and Ladies of France'],
  obituary:
    'Marie Antoinette was born in 1755 in Austria and helped provoke the French Revolution. She became a symbol of the excesses of the kingdom. A consort to Louis XVI, she was beheaded after he was in 1793.\n\nLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n\nCras quis dolor vel mi elementum maximus quis quis ante. Donec maximus libero ut ante placerat, a pretium tortor placerat. Maecenas a tempor leo. Praesent sed mollis magna. Sed at dignissim leo, sed tristique orci. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Quisque laoreet gravida diam, at porta odio elementum vitae.\n\ntest one two three',
  clergy: ['Catholic Priest'],
  interment_place: 'A very nice spot',
  interment_lot: null,
  interment_postal_code: null,
  interment_section: null,
  interment_state: 'State',
  interment_block: null,
  interment_city: 'City',
  interment_date: null,
  interment_county: null,
  interment_time: '10:00AM',
  branch_of_service: null,
  place_entered_service: null,
  rank_discharged_from_service: null,
  date_entered_service: null,
  date_discharged_from_service: null,
  place_discharged_from_service: null,
  us_stations_where_served: [],
  firing_parties_for_funeral_service: null,
  service_number: null,
  chaplain: null,
  ncoic_name: null,
  oic_name: null,
  buglar_and_or_color_guard: null,
  distinguished_service_medal: null,
  purple_heart_medal: null,
  silver_star_medal: null,
  medal_of_honor: null,
  air_force_cross_medal: null,
  navy_cross_medal: null,
  bronze_star_medal: null,
  other_medals: null,
  person_providing_honors: null,
  archived: false,
  additional_family: [
    {
      id: '72eff746-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Uncle 1',
      relation: 'Uncles',
      type: 'Uncles & Aunts',
    },
    {
      id: '72efe59e-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Aunt 1',
      relation: 'Aunt',
      type: 'Uncles & Aunts',
    },
    {
      id: '72efff34-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Aunt 2',
      relation: 'Aunt',
      type: 'Uncles & Aunts',
    },
    {
      id: '72f00b82-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Cousin 1',
      relation: 'Cousin',
      type: 'Cousins',
    },
    {
      id: '72f011ea-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Cousin 2',
      relation: 'Cousin',
      type: 'Cousins',
    },
    {
      id: '72f01816-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Grandchild 1',
      relation: 'Grandson',
      type: 'Grandchildren',
    },
    {
      id: '72f01e9c-d2cc-11eb-8a7f-0e2bca7264bd',
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      name: 'Grandchild 2',
      relation: 'Granddaughter',
      type: 'Grandchildren',
    },
  ],
  services: [
    {
      id: '6fc8abcc-5ef0-11ea-be8d-12c7ad5fcb13',
      location: 'Basilica of St Denis',
      time: '10:30am',
      date: '2019-10-20T00:00:00.000Z',
      type: 'service',
      default: true,
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      created_at: '2021-06-22T22:54:36.915Z',
      created_by: '00000000-59eb-93d2-daa5-c00300280185',
      updated_at: '2021-06-22T22:54:36.915Z',
      updated_by: '00000000-59eb-93d2-daa5-c00300280185',
      city: 'St. Denis',
      state: 'France',
    },
    {
      id: 'c567f382-d2ba-11eb-9e83-0e2bca7264bd',
      location: 'Basilica of St Denis',
      time: '11:00am',
      date: '2019-10-19T00:00:00.000Z',
      type: 'service',
      default: false,
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      created_at: '2021-06-22T22:54:36.919Z',
      created_by: '00000000-59eb-93d2-daa5-c00300280185',
      updated_at: '2021-06-22T22:54:36.919Z',
      updated_by: '00000000-59eb-93d2-daa5-c00300280185',
      city: 'St. Denis',
      state: 'France',
    },
    {
      id: 'd247bf06-d2ba-11eb-b828-0e2bca7264bd',
      location: 'Basilica of St Denis',
      time: '11:00am',
      date: '2019-10-19T00:00:00.000Z',
      type: 'visitation',
      default: false,
      case_id: '00000000-5a90-86ef-bae6-110300c3dce6',
      created_at: '2021-06-22T22:54:36.921Z',
      created_by: '00000000-59eb-93d2-daa5-c00300280185',
      updated_at: '2021-06-22T22:54:36.921Z',
      updated_by: '00000000-59eb-93d2-daa5-c00300280185',
      city: 'St. Denis',
      state: 'France',
    },
  ],
} as const

export default caseDetails
