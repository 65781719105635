import { useState } from 'react'
import { Button, Input, TinyMCE, FileDropzone } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Tooltip from 'components/global/Tooltip/Tooltip'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { PersonalImageCard } from 'components/dashboard/CaseDetails'
import {
  uploadStylizedVerseImage,
  removeStylizedVerseImage,
} from 'api/customVerses'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({
  value,
  reference: [reference, setReference],
  image: [verseImage, setVerseImage],
  TinyMCERef,
  cancel,
  save,
  type,
  verseId,
  global: [isGlobalVerse, setIsGlobalVerse],
  themeVerse: [isThemeVerse, setIsThemeVerse],
}) => {
  const handleError = useErrorHandler('customVerseEditor')
  const role = useRecoilValue(roleAtom)
  const [imagesLoading, setImagesLoading] = useState(false)

  const isMessengerUser = role === 'messenger-user'

  const stylizedVerse = type === 'IMAGE'

  const canUploadVerseImage = stylizedVerse && !verseImage

  const uploadVerseImage = async (file) => {
    setImagesLoading(true)
    try {
      let fd = new FormData()
      fd.append('file', file[0], 'blob')
      const resp = await uploadStylizedVerseImage(fd)
      setImagesLoading(false)
      if (resp.data) setVerseImage(resp.data)
      return resp.data
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'uploadStylizedVerseImage',
      )
      throw error
    }
  }

  const removeVerseImage = async () => {
    try {
      const resp = await removeStylizedVerseImage(verseId, {
        filename: verseImage,
      })
      if (resp) setVerseImage(null)
    } catch (error) {
      handleError(
        error?.response?.data?.message || error.message,
        error,
        null,
        'removeVerseImage',
      )
    }
  }

  return (
    <Flex column full>
      {!stylizedVerse && (
        <Flex
          border="1px solid"
          borderColor="gray4"
          radius="2px"
          minHeight="200px"
          margin="0 0 12px 0"
          full
          background="lockedWhite"
        >
          <TinyMCE
            placeholder="Type text here"
            value={value}
            setRef={(ref) => (TinyMCERef.current = ref)}
          />
        </Flex>
      )}

      {canUploadVerseImage && (
        <Flex full column>
          <FileDropzone
            height="200px"
            margin="0 0 24px 0"
            text="Drag and drop file here to create a new verse"
            fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
            allowMultiple={false}
            onDrop={(file) => uploadVerseImage(file)}
            isLoading={imagesLoading}
          />
        </Flex>
      )}

      {stylizedVerse && verseImage && (
        <Flex full column width="100%">
          <PersonalImageCard
            image={verseImage}
            index={0}
            makeDefault={true}
            margin="0 0 24px 0"
            deleteImage={() => removeVerseImage()}
            allowDelete={true}
          />
        </Flex>
      )}

      <Input
        margin="0 0 12px 0"
        label="Reference"
        value={[reference, setReference]}
      />
      {isMessengerUser && (
        <Flex
          justify="flex-start"
          align="flex-start"
          margin="16px 0"
          gap="8px 0"
          column
        >
          <Tooltip text="Make this verse global" margin="0 12px 0 0">
            <ToggleSwitch
              label="Is global verse"
              labelRight
              value={[isGlobalVerse, setIsGlobalVerse]}
              disabled={!isMessengerUser}
            />
          </Tooltip>
          {stylizedVerse && !isGlobalVerse && (
            <Tooltip
              text="Link verse to product/theme combo"
              margin="0 12px 0 0"
            >
              <ToggleSwitch
                label="Is product/theme verse"
                labelRight
                value={[isThemeVerse, setIsThemeVerse]}
                disabled={!isMessengerUser}
              />
            </Tooltip>
          )}
        </Flex>
      )}
      <Flex>
        <Button onClick={() => cancel?.()} margin="0 12px 0 0">
          Cancel
        </Button>
        <Button primary onClick={() => save?.()}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}
