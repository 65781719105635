import type { PostHog } from 'posthog-js'
import type { UserData } from 'types/user'

/**
 * Attach User Properties to PostHog analytics
 */
export const identifyUserInPosthog = (
  posthog: PostHog | null | undefined,
  user: UserData,
  funeralHomeId?: string
): void => {
  if (!posthog) return

  const userProperties = {
    id: user.id,
    name: user.name,
    email: user.email,
    auth0_id: user.auth0Id,
    type: user.type,
    funeral_home_id: funeralHomeId,
    corporation_id: user.corporationId,
    ui_theme: user.ui_theme,
  }

  posthog.identify(user.id.toString(), userProperties)
  posthog.register(userProperties)
}
