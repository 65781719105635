import palettes from 'utils/styling/palettes'
import { themeAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { MenuButton } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import Grid from 'components/priority/Grid/Grid'

export default () => {
  const [userTheme, setUserTheme] = useRecoilState(themeAtom)

  return (
    <>
      <MenuButton
        text="Set theme"
        items={Object.keys(Object.values(palettes)[0]).map((theme) => ({
          text: theme,
          onClick: () => setUserTheme(theme),
        }))}
      />

      <Grid margin="24px" columns="4">
        {Object.entries(palettes)
          .filter(([key]) => !key.includes('locked'))
          .map(([key, value]) => (
            <Flex column center key={key}>
              <Flex
                background={key}
                radius="50%"
                width="82px"
                height="82px"
                margin="24px"
                border="3px solid"
                borderColor="almostBlack"
                borderOpacity="10"
              />
              <Text margin="0 0 4px">{key}</Text>
              <Text>{value?.[userTheme] || value?.light}</Text>
            </Flex>
          ))}
      </Grid>
    </>
  )
}
