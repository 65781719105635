const baseContentStyle = `
  /* Base text rendering improvements */
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Ensure consistent space character rendering */
  .mce-nbsp-wrap {
    display: inline-block;
  }
`

const autographyFixStyles = `
  /* Autography font fixes */
  [style*="Autography"] {
    font-kerning: none !important;
    font-variant-ligatures: none !important;
    font-feature-settings: "kern" 0, "liga" 0, "calt" 0 !important;
    word-spacing: normal !important;
    white-space: pre-wrap !important;
  }
`

export const getContentStyles = () => {
  return baseContentStyle + autographyFixStyles;
}
