const tableLiteMock = [
  { id: 'Bass-Moll-100', name: 'Esther Howard' },
  { id: 'Bass-Moll-200', name: 'Dianne Marion Russell-Fitzgerald' },
  { id: '11175-11', name: 'Bessie Cooper' },
  { id: '11175-12', name: 'Darrell Steward' },
  { id: '11175-13', name: 'Cody Fisher' },
  { id: 'Bass-Moll-300', name: 'Floyd Miles' },
  { id: 'Bass-Moll-400', name: 'Arlene McCoy' },
  { id: 'Test Case 45', name: 'Darlene Robertson' },
  { id: 'DRB', name: 'Jane Cooper' },
  { id: 'DRB-2', name: 'Theresa Webb' },
] as const

export default tableLiteMock
