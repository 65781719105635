import styled, { css } from 'styled-components'
import spacing from 'styles/spacing'
import sizing from 'styles/sizing'
import type { SpacingProps } from 'styles/spacing'
import type { SizingProps } from 'styles/sizing'

type ResponsiveGridProps = {
  autoWidth?: string
  columns?: number
}

const responsiveGrid = ({ autoWidth, columns }: ResponsiveGridProps) =>
  autoWidth
    ? css`
        width: 100%;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, ${autoWidth});
      `
    : css`
        grid-template-columns: ${columns && `repeat(${columns}, auto)}`};
      `

type GridStyledProps = ResponsiveGridProps & {
  rows?: number
  gap?: string
} & SizingProps &
  SpacingProps

export const Grid = styled.div<GridStyledProps>(
  ({ columns, rows, gap, ...props }) => css`
    display: grid;
    grid-gap: ${gap};

    ${responsiveGrid({ autoWidth: props.autoWidth, columns })}
    ${sizing(props)}
    ${spacing(props)}
  `,
)
