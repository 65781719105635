import { itemIdAtom, itemAtomFamily } from 'atoms'
import { useRecoilValue, useRecoilCallback } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { getImageDimensions } from 'utils'
import zLayers from 'utils/styling/zLayers'
import itemTypes from 'utils/editor/itemTypes'
import { logger, EventCategory } from 'utils/logger'
import { useRecoilLogData } from 'utils/recoilLogger'

export default () => {
  const recoilLogData = useRecoilLogData()
  const itemIds = useRecoilValue(itemIdAtom)

  const addToItems = useRecoilCallback(({ set }) => (item) => {
    set(itemAtomFamily(item.id), item)
    set(itemIdAtom, [...itemIds, item.id])
  })

  const insertItem = async (type, image) => {
    try {
      const uuid = uuidv4()
      const item = { ...itemTypes[type] }
      item.id = uuid
      item.z = zLayers.content

      switch (type) {
        case 'designAsset':
        case 'image':
          const d = await getImageDimensions(`${image.url}`)
          const height = 150
          const width = height * (d.width / d.height)
          item.image = {
            url: image.url,
          }
          item.properties = {
            subtype: type === 'designAsset' ? 'designAsset' : 'caseImage',
            caseImage:
              type === 'designAsset' ? image.designAsset : image.caseImage,
          }
          item.width = width
          item.height = height
          break
        case 'text':
          break
        case 'shape':
          break
        case 'line':
          break
        case 'qrCode':
          break
        default:
        //do nothing
      }

      addToItems(item)

      logger.info({
        message: 'editor_insert_item_success',
        category: EventCategory.INSERT,
        data: {
          ...recoilLogData,
          type,
          image,
        },
      })
    } catch (error) {
      logger.error({
        message: 'editor_insert_item_failed',
        category: EventCategory.ERROR,
        error: error,
        data: {
          ...recoilLogData,
          type,
          image,
        },
      })
    }
  }

  return (type, image) => insertItem(type, image)
}
