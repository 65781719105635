type CenterHeaderTextProps = {
  nameOfDeceased?: string
  productName?: string
  layoutName?: string
  themeName?: string
}

export const setEditorHeaderCenterText = ({ nameOfDeceased, productName, layoutName, themeName }: CenterHeaderTextProps) => {
  const canSetHeaderContent =
    nameOfDeceased ||
    productName ||
    layoutName ||
    themeName

  if (canSetHeaderContent) {
    const deceasedNameExists = !!nameOfDeceased
    const productNameExists = !!productName
    const layoutNameExists = !!layoutName
    const themeNameExists = !!themeName

    let layoutThemeSegment = ''
    if (layoutNameExists) {
      layoutThemeSegment = layoutName
      if (themeNameExists) {
        layoutThemeSegment += ` | ${themeName}`
      }
    } else if (themeNameExists) {
      layoutThemeSegment = themeName
    }
    const titleParts = [
      deceasedNameExists ? nameOfDeceased : null,
      productNameExists && layoutNameExists 
        ? `${productName}: ${layoutThemeSegment}`
        : productNameExists 
          ? productName 
          : layoutThemeSegment || null
    ].filter(Boolean)
    
    return titleParts.join(' | ')
  }

  return null
}
