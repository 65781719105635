import { Button } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import Divider from 'components/global/Divider/Divider'
import { NestedObjectInputs } from './'
import { useRecoilState, useRecoilValue } from 'recoil'
import { caseValuesAtom, delayNestedObjectInputAtom } from 'atoms'
import Container from './NestedObject.styled'
import orderBySequence from 'utils/caseDetails/orderBySequence'
import { useEffect } from 'react'
import { formFieldKeys } from '@mdl/shared-consts'

export default ({
  type,
  shortType,
  title,
  formField: { newObject, addCaseAndType, allowedInputs },
  width,
  margin,
}) => {
  const [isDelayOn, setIsDelayOn] = useRecoilState(delayNestedObjectInputAtom)
  const [fieldValue, setFieldValue] = useRecoilState(caseValuesAtom(type))
  const case_id = useRecoilValue(caseValuesAtom('id'))
  const caseDetailHasSequenceInput =
    type === formFieldKeys.pallbearers ||
    type === formFieldKeys.organizations ||
    type === formFieldKeys.memorialContributions

  // TODO: find a better solution in the future. While this works, I'd say it's a patch. I could use the teams help determining a better solution.
  // TLDR; users are unlikely to create a case, and immediately try to expand a nestedObjectInput within ServiceInfo.
  //       therefore, we'll delay the ability to expand a nestedObjectInput by 3 seconds.
  //
  // More Context for better solution in the future.
  // Recoil is making too many selector calls when user creates a new case, causing performance problems.
  // Problem flows from components tree as follows:
  //    <ServiceInfo /> -> <CaseFormSection /> -> <CaseInputSelector /> -> <NestedObjectWrapper /> -> <NestedObjectInputs />
  // When NestedObjectWrapper and its children try to render immediately with everything else, it causes a cascade of Recoil state requests.
  // NestedObjectWrapper requests caseValuesAtom(type) for the fieldValue.
  // NestedObjectInputs requests editCaseFieldSelector(type) which requests caseValuesAtom(type) again.
  // This creates a multiplier effect somehow when fired at the same time as everything else.
  // By delaying the render of NestedObjectWrapper, we can prevent the cascade of Recoil state requests.
  useEffect(() => {
    if (isDelayOn) {
      const timer = setTimeout(() => {
        setIsDelayOn(false)
      }, 3000)

      return () => clearTimeout(timer)
    }
  }, [isDelayOn, setIsDelayOn])

  const addArrayInput = (shortType, case_id) => {
    let updatedArray = fieldValue.value ? [...fieldValue.value] : []
    let newObj = addCaseAndType
      ? { ...newObject, type: shortType, case_id: case_id }
      : newObject
    updatedArray.push(newObj)

    // ensure the first service added is marked as the default
    const isFirstService = updatedArray?.length === 1
    const serviceType = updatedArray?.[0]?.['type']
    const isVisitOrService =
      serviceType === 'visitation' || serviceType === 'service'

    const firstDefaultService = isFirstService && isVisitOrService
    firstDefaultService &&
      (updatedArray[0] = { ...updatedArray[0], default: true })

    if (caseDetailHasSequenceInput) {
      updatedArray = orderBySequence(updatedArray)
    }

    setFieldValue({
      ...fieldValue,
      value: updatedArray,
    })
  }

  //splice out the index of the item to be removed from an array
  const removeArrayInput = (index) => {
    let newArr = [...fieldValue.value]
    const wasDefaultService = newArr?.[index]?.['default'] === true
    const serviceType = newArr?.[index]?.['type']
    const isVisitOrService =
      serviceType === 'visitation' || serviceType === 'service'

    newArr.splice(index, 1)

    const hasNoServices = newArr?.length === 0
    const defaultServiceDeleted =
      wasDefaultService && isVisitOrService && !hasNoServices
    defaultServiceDeleted && (newArr[0] = { ...newArr[0], default: true })

    if (caseDetailHasSequenceInput) {
      newArr = orderBySequence(newArr)
    }

    setFieldValue({
      ...fieldValue,
      value: newArr,
    })
  }
  return (
    <Flex
      full
      column
      width={width || '280px'}
      className="testing"
      margin={margin || '0 0 40px'}
    >
      <Flex full width={width || '280px'}>
        <Text as="h3" transform="capitalize" width={width || '280px'}>
          {title?.split('_').join(' ')}
        </Text>
      </Flex>
      {!isDelayOn &&
        fieldValue?.value?.map(
          (item, i) =>
            (item.type === shortType || !item.type) && (
              <Flex column key={`${item.id}_${i}`} width={width || '280px'}>
                <Flex align="center" margin="0 0 10px 0">
                  <Flex wrap="wrap">
                    {allowedInputs.map((object_key, index) => (
                      <NestedObjectInputs
                        key={`${object_key.field || object_key}_${index}`}
                        label={
                          object_key.field
                            ? `${object_key.field}`
                            : `${object_key}`
                        }
                        width={width || '280px'}
                        name={`${type}`}
                        index={i}
                        object_key={object_key}
                      />
                    ))}
                  </Flex>
                </Flex>
                {/* Move Delete button to the end of the item */}
                <Flex justify="flex-end" width={width || '280px'}>
                  <Container
                    onClick={() => removeArrayInput(i)}
                    align="center"
                    height="16px"
                  >
                    <Text size="12px" color="gray2" margin="0 8px 0 0">
                      Delete
                    </Text>
                    <Icon
                      icon="deleteOutline"
                      fill="gray2"
                      hoverfill="gray1"
                      height="16px"
                      width="16px"
                      onClick={() => removeArrayInput(i)}
                    />
                  </Container>
                </Flex>
                <Divider fill="gray4" margin="24px 0" />
              </Flex>
            ),
        )}
      <Button
        width={width || '280px'}
        margin="0 0 40px"
        icon="plus"
        onClick={() => addArrayInput(shortType, case_id)}
      >
        Add {shortType}
      </Button>
    </Flex>
  )
}
