import styled, { css } from 'styled-components'
import editorItem from 'styles/editorItem'
import itemFeather from 'styles/itemFeather'
import getBoxShadow from 'styles/getImageBoxShadow'

export const Image = styled.div.attrs(({ imgSrc, isFitting }) => ({
  style: {
    backgroundImage: isFitting
      ? `
        linear-gradient(to right, rgba(255,255,255,0.1) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(255,255,255,0.1) 1px, transparent 1px),
        url("${imgSrc}")
      `
      : `url("${imgSrc}")`,
    backgroundSize: isFitting ? '20px 20px, 20px 20px, cover' : 'cover',
    opacity: isFitting ? 0.5 : undefined,
  },
}))(
  ({ edits }) => css`
    ${editorItem}
    background-position: center;
    background-repeat: no-repeat;
    overflow: ${edits?.feather ? 'hidden' : 'visible'};
    box-shadow: ${getBoxShadow(edits)};
    border: ${edits?.borderThickness}px ${edits?.borderThickness && 'solid'}
      ${edits?.borderColor};
    border-radius: ${edits?.circle ? '50%' : `${edits?.borderRadius || 0}px`};
    opacity: ${edits?.opacity};
    ${itemFeather(edits)}
  `,
)
