type HandleSpecialCaseDetailArrays = {
  path: string
  items: Array<Record<string, any>> | string[] | null
}

const handleSpecialCaseDetailArrays = ({
  path,
  items,
}: HandleSpecialCaseDetailArrays): string[] | null => {
  if (path === 'pallbearers') {
    const pallbearers = items as Array<{ name: string }>
    return Array.isArray(pallbearers)
      ? pallbearers?.map((item) => item?.name)
      : null
  }

  return items as string[]
}

export default handleSpecialCaseDetailArrays
