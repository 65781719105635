import { camelCase, startCase } from 'lodash'
import regEx from './regEx'

const transformToPascalCase = (input: string): string => {
  try {
    const camelCased = camelCase(input)
    return startCase(camelCased).replace(regEx.multipleWhiteSpace, '')
  } catch (error) {
    console.error('Error transforming to pascal case:', error)
    return ''
  }
}

export default transformToPascalCase
