import { useState, useEffect, useMemo } from 'react'
import { Dropdown, Table } from 'components'
import Flex from 'components/priority/Flex/Flex'
import { getProductsByCollection } from 'api/products'
import { update, archive, getCustomPageSets } from 'api/pageSets'
import { getLayoutsByProduct } from 'api/layouts'
import { getAllCollections } from 'api/collections'
import DefaultTemplateTable from './DefaultTemplateTable'
import { useSetToast } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ width, margin }) => {
  const handleError = useErrorHandler('DefaultTemplateWrapper')
  const [allCollections, setAllCollections] = useState()
  const [selectedCollection, setSelectedCollection] = useState()
  const [allProducts, setAllProducts] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState()
  const [allLayouts, setAllLayouts] = useState(null)
  const [selectedLayout, setSelectedLayout] = useState(null)
  const [customTemplates, setCustomTemplates] = useState([])
  const setToast = useSetToast()

  const getCollections = async () => {
    try {
      const { data } = await getAllCollections()
      setAllCollections(data)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const getProducts = async () => {
    if (selectedCollection) {
      try {
        const { data } = await getProductsByCollection(selectedCollection)
        setAllProducts(data)
        setSelectedProduct(null)
        setSelectedLayout(null)
        setCustomTemplates([])
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  }

  const getLayouts = async () => {
    if (selectedProduct) {
      try {
        const { data } = await getLayoutsByProduct(selectedProduct)
        setAllLayouts(data)
        setSelectedLayout(null)
        setCustomTemplates([])
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  }

  const getCustomTemplates = async () => {
    if (selectedLayout) {
      try {
        const { data } = await getCustomPageSets(selectedLayout)
        setCustomTemplates(data)
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  }

  const deleteTemplate = async (template) => {
    try {
      await archive(template.id)
      setToast({
        text: 'Template Deleted!',
        icon: 'Check',
      })
      getCustomTemplates()
    } catch (err) {
      handleError(err?.response?.data?.message || err.message, err)
    }
  }

  const selectDefaultTemplate = async (template) => {
    try {
      const currentDefaults = customTemplates.filter(
        (template) => template.default && template.home_template,
      )
      Promise.all(
        currentDefaults.map(
          async (currentDefault) =>
            await update(currentDefault.id, {
              default: false,
            }),
        ),
      )
      if (template.home_template) {
        await update(template.id, {
          default: true,
        })
      }
      setToast({
        text: 'Default Set!',
        icon: 'Check',
      })
      getCustomTemplates()
    } catch (err) {
      handleError(err?.response?.data?.message || err.message, err)
    }
  }

  const columns = useMemo(
    () =>
      DefaultTemplateTable(
        customTemplates,
        deleteTemplate,
        selectDefaultTemplate,
      ),
    // eslint-disable-next-line
    [customTemplates],
  )

  useEffect(
    () => {
      getCollections()
    },
    //eslint-disable-next-line
    [],
  )

  useEffect(
    () => {
      getProducts()
    },
    //eslint-disable-next-line
    [selectedCollection],
  )
  useEffect(
    () => {
      getLayouts()
    },
    //eslint-disable-next-line
    [selectedProduct],
  )
  useEffect(
    () => {
      getCustomTemplates()
    },
    //eslint-disable-next-line
    [selectedLayout],
  )

  return (
    <>
      <Flex>
        <Dropdown
          label="Collections"
          width={width || '300px'}
          margin={margin || '0 24px 0 0'}
          selected={[selectedCollection, setSelectedCollection]}
          placeholder="Choose Collections"
          textKey="name"
          valueKey="id"
          items={allCollections}
        />
        <Dropdown
          label="Products"
          disabled={!allProducts}
          width={width || '300px'}
          margin={margin || '0 24px 0 0'}
          selected={[selectedProduct, setSelectedProduct]}
          placeholder="Choose Product"
          textKey="name"
          valueKey="id"
          items={allProducts}
        />
        <Dropdown
          label="Layouts"
          disabled={!allLayouts}
          width={width || '300px'}
          margin={margin || '0 24px 0 0'}
          selected={[selectedLayout, setSelectedLayout]}
          placeholder="Choose Layout"
          textKey="name"
          valueKey="id"
          items={allLayouts}
        />
      </Flex>
      <Flex width="950px" margin="20px 0 0 0">
        <Table
          columns={columns}
          data={customTemplates}
          searchPlaceholder="Search by name or email"
        />
      </Flex>
    </>
  )
}
