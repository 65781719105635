import { useState, useMemo, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  orderAtom,
  selectedThemeSelector,
  pinnedThemesAtom,
  customThemesAtom,
  themesAtom,
  pinnedThemesSelector,
  selectedProductLineAtom,
  selectedThemeAtom,
} from 'atoms'
import { PrintPackageHeader } from './'
import { useSetToast } from 'hooks'
import { createPinnedThemes, deletePinnedThemes } from 'api/themes'
import { getCustomThemes, createCustomTheme } from 'api/customThemes'
import {
  CardSelector,
  SearchBar,
  Button,
  Tabs,
  NewThemeModal,
  CustomThemeEditor,
  FullscreenModal,
} from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ caseInfo }) => {
  const handleError = useErrorHandler('Theme')
  const [search, setSearch] = useState('')
  const [showNewThemeModal, setShowNewThemeModal] = useState(false)
  const [showEditThemeModal, setShowEditThemeModal] = useState(false)
  const [newTheme, setNewTheme] = useState(null)
  const [selectedTheme, setSelectedTheme] = useRecoilState(
    selectedThemeSelector,
  )
  const setSelectedThemeAtom = useSetRecoilState(selectedThemeAtom)
  const order = useRecoilValue(orderAtom)
  const themes = useRecoilValue(themesAtom)
  const pinnedThemes = useRecoilValue(pinnedThemesAtom)
  const [customThemes, setCustomThemes] = useRecoilState(customThemesAtom)
  const [activeTab, setActiveTab] = useState(null)
  const setThisPinnedTheme = useSetRecoilState(pinnedThemesSelector)
  const setToast = useSetToast()
  const selectedProductLine = useRecoilValue(selectedProductLineAtom)

  useEffect(() => {
    setSelectedThemeAtom(null)
    //eslint-disable-next-line
  }, [selectedProductLine])

  const concatThemeArrays = () => {
    const pinnedThemesCategory = []
    const customThemesCategory = []
    let themesWithPinsParsed = [...themes]
    if (pinnedThemes.length > 0) {
      pinnedThemesCategory.push({
        name: 'Pinned Themes',
        themes: pinnedThemes,
      })
    }
    themesWithPinsParsed = themesWithPinsParsed.map((category) => {
      const theseThemes = category.themes.map((theme) => {
        const isPinned = pinnedThemes.find(
          (pinned) => pinned.theme_id === theme.id,
        )
        const obj = { ...theme, isPinned: isPinned?.theme_id ? true : false }
        return obj
      })
      const obj = { ...category, themes: theseThemes }
      return obj
    })
    //TODO: let's tidy up the collection thing
    if (
      customThemes.length > 0 &&
      order.collection_id === '00000000-57c7-4c01-caa5-ce2103a01c53'
    ) {
      customThemesCategory.push({
        name: 'Custom Themes',
        themes: customThemes,
      })
    }

    const filteredThemes = pinnedThemesCategory
      .concat(customThemesCategory)
      .concat(themesWithPinsParsed)
    return filteredThemes
  }

  const filteredCategories = useMemo(() => {
    if (search.trim() === '') {
      const filteredThemes = concatThemeArrays()
      !activeTab && setActiveTab(filteredThemes[0]?.name)
      return filteredThemes
    } else {
      let filteredCategories = []
      const searchRegex = new RegExp(`${search}`, 'ig')
      themes.map((category) => {
        //cannot overwrite category or themes, so create new ones! yay!
        const newCategory = Object.assign({ ...category })
        newCategory.themes = category.themes.filter((theme) =>
          searchRegex.test(theme.name),
        )
        newCategory.themes.length > 0 && filteredCategories.push(newCategory)
        return category
      })
      !activeTab && setActiveTab(filteredCategories[0]?.name)
      return filteredCategories
    }
    //eslint-disable-next-line
  }, [themes, pinnedThemes, customThemes, search])

  const pinTheme = async (e, theme) => {
    try {
      e.stopPropagation()
      const index = pinnedThemes.findIndex(
        (item) => item.id === theme.id || item.theme_id === theme.id,
      )
      const pinnedTheme = pinnedThemes.find(
        (item) => item.id === theme.id || item.theme_id === theme.id,
      )
      const payload = {
        theme_id: theme.id,
        collection_id: order?.collection_id,
      }
      if (index < 0) {
        const { data } = await createPinnedThemes(payload)
        data.image = theme.image
        data.name = theme.name

        setThisPinnedTheme(data)
      } else {
        await deletePinnedThemes(pinnedTheme.id)
        setThisPinnedTheme(pinnedTheme)
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const createTheme = async (payload) => {
    try {
      const resp = await createCustomTheme(payload)
      if (resp.status === 201) {
        setToast({
          text: 'Theme Created',
          icon: 'check',
        })
        setNewTheme(resp.data)
        setShowEditThemeModal(true)
      }
    } catch (err) {
      handleError(
        err?.response?.data?.message || err.message,
        err,
        'deleteOutline',
      )
    }
  }

  const closeEditThemeModal = async () => {
    try {
      const { data } = await getCustomThemes()
      setCustomThemes(data)
      setActiveTab('Custom Themes')
      setShowEditThemeModal(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return (
    <>
      <PrintPackageHeader title="Choose a theme" caseInfo={caseInfo} />
      <Flex justify="center" align="center" padding="0 40px">
        <Text as="h2">
          {selectedTheme ? 'Selected Theme: ' : 'Select a Theme'}
          {selectedTheme?.name || ''}
        </Text>
      </Flex>
      <Flex justify="space-between" align="center" padding="32px 40px">
        <SearchBar
          placeholder="Search"
          value={[search, setSearch]}
          width="600px"
        />

        <Button icon="plus" primary onClick={() => setShowNewThemeModal(true)}>
          New theme
        </Button>
        <FullscreenModal show={[showNewThemeModal, setShowNewThemeModal]}>
          <NewThemeModal
            createTheme={createTheme}
            setShowNewThemeModal={setShowNewThemeModal}
          />
        </FullscreenModal>
        <FullscreenModal show={[showEditThemeModal, setShowEditThemeModal]}>
          <CustomThemeEditor
            theme={newTheme}
            closeModal={closeEditThemeModal}
          />
        </FullscreenModal>
      </Flex>

      <Tabs
        width="250px"
        tabs={filteredCategories.map((category) => {
          return {
            title: category.name,
            content: (
              <CardSelector
                padding="16px 40px 40px 4px"
                cardAlign="flex-start"
                cardJustify="flex-start"
                cards={category.themes}
                cardWidth="328px"
                cardHeight="204px"
                cardPadding="16px 24px 16px"
                imageHeight="150px"
                imageWidth="250px"
                imageFit="cover"
                imagePosition="50% 95%"
                height="500px"
                selectedState={[selectedTheme, setSelectedTheme]}
                prependURL="https://bassmollett-theme.imgix.net/"
                cardIcon={{
                  icon: 'thumbtack',
                  clickHandler: pinTheme,
                }}
                iconHover={category.name !== 'Pinned Themes'}
              />
            ),
          }
        })}
        selected={[activeTab, setActiveTab]}
      />
    </>
  )
}
