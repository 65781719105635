import { useTinyMCESettings } from '.'
import { Editor } from '@tinymce/tinymce-react'
import { setupAutographyFontHandling } from 'utils/tinyMCE/updateDynamicText/makeBrowserAgnostic'

export default (props) => {
  const {
    value = '',
    init: additionalInit,
    inline,
    setRef,
    caseDetails,
    isVerse = false,
    setToast,
    setTinyMCERefTrigger,
    updateHeight = () => null,
    ...rest
  } = props

  const tinyMCEProps = {
    additionalInit,
    inline,
    caseDetails,
    isVerse,
    setToast,
    updateHeight,
  }

  const init = useTinyMCESettings(tinyMCEProps)

  const onInit = (evt, editor) => {
    setRef?.(editor)

    try {
      setupAutographyFontHandling(editor);
    } catch (error) {
      console.error('[Autography Fix] Error setting up handler:', error);
    }

    if (editor) setTinyMCERefTrigger?.(Date.now())
  }

  return (
    <Editor
      onInit={onInit}
      tinymceScriptSrc="https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js"
      initialValue={value}
      init={init}
      {...rest}
    />
  )
}
