import { MenuDrawer, InsertImageGrid, CustomVerse } from 'components'
import Flex from 'components/priority/Flex/Flex'

export default ({
  drawers,
  categoryKey,
  categoryNameKey,
  imageKey,
  nameKey,
  children,
  setShow,
  type,
  srcPrefix,
  isVerse,
  ...props
}) => (
  <Flex column full margin="16px" {...props}>
    {drawers?.map((list, index) => (
      <MenuDrawer
        startOpen={false}
        title={list?.[categoryNameKey]}
        subtitle={!type ? list?.[categoryKey].length : ''}
        key={index}
        full
        doNotCapitalize={type === 'customVerse' ? true : false}
      >
        {!type && (
          <InsertImageGrid
            items={list?.[categoryKey]}
            imageKey={imageKey}
            nameKey={nameKey}
            setShow={setShow}
            srcPrefix={srcPrefix}
            isVerse={isVerse}
          />
        )}
        {type === 'customVerse' && (
          <CustomVerse verse={list} setShow={setShow} key={index} />
        )}
      </MenuDrawer>
    ))}
  </Flex>
)
