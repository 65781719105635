/**
 * Orders an array of objects with sequence properties, handling sequence conflicts
 * by shifting subsequent items and ensuring continuous sequence.
 * Duane said users don't need to see or update the sequence fields
 * sequence input should be done for them.
 * @example
 * // Starting array:
 * const arr = [
 *   { sequence: "1", name: "First" },
 *   { sequence: "2", name: "Second" },
 *   { sequence: "3", name: "Third" },
 *   { sequence: "4", name: "Fourth" }
 * ]
 *
 * // User changes "Second" sequence from "2" to "3" (i.e. adds or deletes entity with sequence field)
 * // First, sort by sequence:
 * [
 *   { sequence: "1", name: "First" },
 *   { sequence: "3", name: "Second" },  // User changed to 3
 *   { sequence: "3", name: "Third" },   // Conflict!
 *   { sequence: "4", name: "Fourth" }
 * ]
 *
 * // Then resolve conflicts by shifting:
 * [
 *   { sequence: "1", name: "First" },
 *   { sequence: "3", name: "Second" },  // Stays 3
 *   { sequence: "4", name: "Third" },   // Shifted up
 *   { sequence: "5", name: "Fourth" }   // Shifted up
 * ]
 *
 * // Finally, ensure continuous sequence:
 * [
 *   { sequence: "1", name: "First" },   // 1 stays 1
 *   { sequence: "2", name: "Second" },  // 3 becomes 2
 *   { sequence: "3", name: "Third" },   // 4 becomes 3
 *   { sequence: "4", name: "Fourth" }   // 5 becomes 4
 * ]
 */
const orderBySequence = (arr: { sequence: string; [key: string]: any }[]) => {
  if (!arr?.length) return arr

  // Sort by sequence number
  const orderedArray = [...arr].sort((a, b) => {
    const aNum = Number(a.sequence) || Infinity
    const bNum = Number(b.sequence) || Infinity
    return aNum - bNum
  })

  // When adding new items, they should get the next available sequence
  const maxSequence = Math.max(...orderedArray.map(item => Number(item.sequence) || 0))
  orderedArray.forEach(item => {
    if (!item.sequence) {
      item.sequence = String(maxSequence + 1)
    }
  })

  // Ensure sequences are continuous (whether one is deleted or added)
  return orderedArray.map((obj, i) => ({
    ...obj,
    sequence: String(i + 1),
  }))
}

export default orderBySequence
