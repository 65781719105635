import {
  handleString,
  handleArray,
  handleAdditionalFamily,
  handleOrganizations,
  handleMemorialContributions,
  handleTextBlock,
} from '.'

import { formFieldKeys } from '@mdl/shared-consts'

export default (
  editor,
  caseDetails,
  key,
  templates,
  dateFormat,
  selectedTheme,
) => {
  const obj = {
    // Name of Deceased (First)
    name_of_deceased: () => {
      const nameComponents = [
        { key: formFieldKeys.prefix, format: (value) => value },
        { key: formFieldKeys.firstName, format: (value) => value },
        { key: formFieldKeys.nickName, format: (value) => `"${value}"` },
        { key: formFieldKeys.middleName, format: (value) => value },
        { key: formFieldKeys.maidenName, format: (value) => `(${value})` },
        { key: formFieldKeys.lastName, format: (value) => value },
        { key: formFieldKeys.suffix, format: (value) => value },
      ]

      const components = nameComponents
        .filter((component) => caseDetails[component.key])
        .map((component) => {
          const value = component.format(caseDetails[component.key])
          return `<span class="mceNonEditable" data-case-detail="${component.key}">${value}</span>`
        })

      const fullName = components.join(' ')

      handleString(
        editor,
        { ...caseDetails, name_of_deceased: fullName },
        key,
        templates,
        dateFormat,
        'Name of Deceased',
      )
    },

    // All other fields (Second)
    date_of_birth: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    place_of_birth: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    date_of_death: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    place_of_death: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    religion: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    address_of_deceased: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    mother_of_deceased: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    father_of_deceased: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    spouse: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    marital_status: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    occupation: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    website_obit_url: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    obituary: () =>
      handleTextBlock(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    order_of_service: () =>
      handleTextBlock(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_place: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_address: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_lot: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_section: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_state: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_postal_code: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_block: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_city: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_date: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_county: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),
    interment_time: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        null,
        selectedTheme,
      ),

    // handle arrays
    children: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    siblings: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    songs: () => handleArray(editor, caseDetails, key, templates, dateFormat),
    special_songs: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    pallbearers: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    societies: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),
    clergy: () => handleArray(editor, caseDetails, key, templates, dateFormat),
    us_stations_where_served: () =>
      handleArray(editor, caseDetails, key, templates, dateFormat),

    // handle services
    // services: () =>
    //   handleServices(editor, caseDetails, key, templates, dateFormat),
    organizations: () =>
      handleOrganizations(editor, caseDetails, key, templates, dateFormat),
    memorial_contributions: () =>
      handleMemorialContributions(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
      ),

    // handle additional family
    additional_family: () =>
      handleAdditionalFamily(editor, caseDetails, key, templates, dateFormat),

    // Individual name components (Last)
    prefix_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Prefix Name',
        selectedTheme,
      ),
    first_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'First Name',
        selectedTheme,
      ),
    nick_name_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Nickname',
        selectedTheme,
      ),
    middle_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Middle Name',
        selectedTheme,
      ),
    maiden_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Maiden Name',
        selectedTheme,
      ),
    lastdec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Last Name',
        selectedTheme,
      ),
    suffix_dec: () =>
      handleString(
        editor,
        caseDetails,
        key,
        templates,
        dateFormat,
        'Suffix Name',
        selectedTheme,
      ),
  }

  if (obj[key]) {
    obj[key]()
  }
}
