import { useState } from 'react'
import { Clipboard, MenuDrawer } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { determineItemProperties } from 'utils'
import * as Properties from '../Drawers'
import { useRecoilValue } from 'recoil'
import { selectedItemAtom, itemSelector } from 'atoms'

export default () => {
  const selectedItemId = useRecoilValue(selectedItemAtom)

  let selectedItem = useRecoilValue(
    itemSelector({ id: selectedItemId, path: 'type' }),
  )

  selectedItem =
    selectedItem && selectedItem !== 'watermark' ? selectedItem : false

  const [openDrawer, setOpenDrawer] = useState(
    selectedItem ? determineItemProperties?.[selectedItem][0] : false,
  )
  return (
    <Flex column>
      {selectedItem ? (
        determineItemProperties?.[selectedItem].map((property, index) => {
          const PropertyDrawer =
            Properties?.[`${property.replace(' ', '')}Drawer`]
          return (
            <MenuDrawer
              show={[openDrawer === property, () => setOpenDrawer(property)]}
              title={property}
              key={property}
              startOpen={index === 0}
            >
              <PropertyDrawer />
            </MenuDrawer>
          )
        })
      ) : (
        <>
          <Text margin="32px 40px" color="gray2" align="center">
            Select an element to edit in FastTrack mode or toggle to advanced
            mode in settings
          </Text>
          <Clipboard padding="0 24px 24px 24px" justify="center" />
        </>
      )}
    </Flex>
  )
}
