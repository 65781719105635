import { useState, useEffect, useMemo } from 'react'
import { InsertCategories, SlideoutMenu, MenuTabs } from 'components'
import Flex from 'components/priority/Flex/Flex'
import { useRecoilState } from 'recoil'
import { insertMenuOpenAtom } from 'atoms'
import zLayers from 'utils/styling/zLayers'

export default ({
  basePages,
  baseLayout,
  setIsLoading,
  update,
  itemsLocked,
}) => {
  const [showSlideout, setShowSlideout] = useRecoilState(insertMenuOpenAtom)
  const [selectedMenu, setSelectedMenu] = useState('')
  const [activeTab, setActiveTab] = useState('Assets')

  const setMenu = (menu) => {
    setShowSlideout(selectedMenu === menu ? !showSlideout : true)
    setSelectedMenu(menu)
  }

  const menuConfigAssets = useMemo(
    () => [
      {
        name: 'SavedLayouts',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Saved layouts',
        locked: false,
      },
      {
        name: 'PhotosOfDeceased',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Photos of deceased',
        locked: itemsLocked,
      },
      {
        name: 'StylizedVerses',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Stylized Verses',
        locked: itemsLocked,
      },
      {
        name: 'EditableVersesAndPhrases',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Editable verses and phrases',
        locked: itemsLocked,
      },
      {
        name: 'DesignAssets',
        allowedFor: ['messenger-user'],
        displayLabel: 'Design assets',
        locked: false,
      },
    ],
    [itemsLocked],
  )

  const menuConfigCase = useMemo(
    () => [
      {
        name: 'DeceasedInfo',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Deceased info',
      },
      {
        name: 'Obituary',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Obituary',
      },
      {
        name: 'ServiceInfo',
        allowedFor: ['fh-user', 'messenger-user'],
        displayLabel: 'Service info',
      },
      {
        name: 'ImagesAndFamilyMemorials',
        allowedFor: ['messenger-user'],
        displayLabel: 'Images and Family Memorials',
      },
      {
        name: 'MemorialContributions',
        allowedFor: ['messenger-user'],
        displayLabel: 'Memorial contributions',
      },
      /* {
        name: 'MilitaryVeteranHonors',
        allowedFor: ['messenger-user'],
        displayLabel: 'Military/Veteran honors',
      }, */
    ],
    [],
  )

  const [menuItems, setMenuItems] = useState(menuConfigAssets)

  useEffect(() => {
    setMenuItems(activeTab === 'Assets' ? menuConfigAssets : menuConfigCase)
    setShowSlideout(false)
  }, [activeTab, menuConfigAssets, menuConfigCase, setShowSlideout])

  return (
    <Flex
      height="100%"
      width="250px"
      background="white"
      position="relative"
      zindex={zLayers.base}
      borderRight="1px solid"
      borderColor="gray4"
      column
    >
      <MenuTabs
        activeTab={[activeTab, setActiveTab]}
        tabs={['Assets', 'Case']}
      />

      <InsertCategories
        setMenu={setMenu}
        showSlideout={showSlideout}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        setShowSlideout={setShowSlideout}
        menuConfig={menuItems}
      />

      <SlideoutMenu
        isSearchMenu={selectedMenu === 'SearchBar'}
        setIsLoading={setIsLoading}
        title={selectedMenu}
        show={[showSlideout, setShowSlideout]}
        menu={selectedMenu}
        basePages={basePages}
        baseLayout={baseLayout}
        update={update}
      />
    </Flex>
  )
}
