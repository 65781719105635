import { useEffect, useState } from 'react'
import * as S from './ImageFitter.styled'

export const ImageFitter = ({
  itemWidth,
  itemHeight,
  setItemWidth,
  setItemHeight,
  setCropX,
  setCropY,
  isFitting,
  setIsFitting,
}) => {
  const [dragStart, setDragStart] = useState({ x: 0, y: 0, position: null })

  useEffect(() => {
    if (isFitting) {
      window.addEventListener('mousemove', handleDrag)
      window.addEventListener('mouseup', handleDragEnd)
    }
    return () => {
      window.removeEventListener('mousemove', handleDrag)
      window.removeEventListener('mouseup', handleDragEnd)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFitting])

  const handleDragStart = (e, position) => {
    e.stopPropagation()
    setIsFitting(true)
    setDragStart({
      x: e.clientX,
      y: e.clientY,
      position,
    })
  }

  const handleDrag = (e) => {
    if (!isFitting) return
    const deltaX = e.clientX - dragStart.x
    const deltaY = e.clientY - dragStart.y
    let newWidth = itemWidth
    let newHeight = itemHeight
    switch (dragStart.position) {
      case 'bottom':
        newHeight = Math.max(20, itemHeight + deltaY)
        break
      case 'right':
        newWidth = Math.max(20, itemWidth + deltaX)
        break
      default:
        break
    }
    setItemWidth(newWidth)
    setItemHeight(newHeight)
  }

  const handleDragEnd = (e) => {
    const hasVerticallyMoved = dragStart.y !== e.clientY
    const hasHorizontallyMoved = dragStart.x !== e.clientX
    const hasMoved = hasVerticallyMoved || hasHorizontallyMoved
    if (!hasMoved) {
      setIsFitting(false)
      return
    }
    const deltaX = e.clientX - dragStart.x
    const deltaY = e.clientY - dragStart.y
    setCropX(deltaX)
    setCropY(deltaY)
    setIsFitting(false)
  }

  return (
    <>
      <S.CropOverlay isFitting={isFitting} />
      <S.BottomHandler
        position="s"
        onMouseDown={(e) => handleDragStart(e, 'bottom')}
      />
      <S.RightHandler
        position="e"
        onMouseDown={(e) => handleDragStart(e, 'right')}
      />
    </>
  )
}
