import { format as dateFnFormat } from 'date-fns'
import momentToDateFns from './momentToDateFns'
import isoStringToDate from './isoStringToDate'

const formatDate = (
  date: Date | string,
  format: string,
  isMomentJSFormat: boolean = false,
): string => {
  const convertedFormat = isMomentJSFormat ? momentToDateFns(format) : format

  const convertedDate = isoStringToDate(date)
  const isDate = convertedDate instanceof Date
  return isDate
    ? dateFnFormat(convertedDate, convertedFormat)
    : date === null
    ? ''
    : String(date)
}

export default formatDate
