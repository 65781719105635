import { load } from 'cheerio'

export default (html) => {
  // Load the HTML string into Cheerio
  const $ = load(html, { decodeEntities: false })

  // Process all <p> elements recursively
  $('p').each((i, el) => {
    // Get the current style attribute or use an empty string
    const currentStyle = $(el).attr('style') || ''
    // If there's no "line-height" in the style (case-insensitive)
    if (!/line-height\s*:/i.test(currentStyle)) {
      // Prepare the new style string without overwriting existing properties.
      // Trim, ensure it ends with a semicolon if not empty, then append the property.
      let newStyle = currentStyle.trim()
      if (newStyle && !newStyle.endsWith(';')) {
        newStyle += '; '
      }
      newStyle += 'line-height: 1;'
      $(el).attr('style', newStyle)
    }
  })

  // Process all <span> elements recursively
  $('span').each((i, el) => {
    const currentStyle = $(el).attr('style') || ''
    if (!/font-size\s*:/i.test(currentStyle)) {
      let newStyle = currentStyle.trim()
      if (newStyle && !newStyle.endsWith(';')) {
        newStyle += '; '
      }
      newStyle += 'font-size: 14pt;'
      $(el).attr('style', newStyle)
    }
  })

  // Return the updated HTML string
  return $.html()
}
