import { load } from 'cheerio'
// import regEx from 'utils/global/regEx'

/**
 * Sanitizes HTML content:
 * - empties elements containing only a single comma
 * - empties elements containing only "New Text Box"
 */
export default (props) => {
  const { html, environment } = props

  const isEditor = environment === 'editor'
  if (isEditor) return html

  const $ = load(html)

  $('*').each((i, el) => {
    // TODO: HOTFIX was needed - removing comment this way below was removing more commas then it should
    //       let's come back and fix this so we can remove single commas properly
    // const elementText = $(el).text().trim()
    // const spanWithOneComma = regEx.hasSingleComma.test(elementText)
    // const isNewTextBox = elementText === 'New Text Box'
    // const isUnwantedText = spanWithOneComma || isNewTextBox
    // if (isUnwantedText) $(el).empty()

    const elementText = $(el).text().trim()
    const isNewTextBox = elementText === 'New Text Box'
    const isUnwantedText = isNewTextBox
    if (isUnwantedText) $(el).empty()
  })

  return $.html()
}
