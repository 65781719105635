import { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  canvasRefAtom,
  insertMenuOpenAtom,
  isPageDeleting,
  openSwapImageMenuAtom,
  pageRefAtom,
  selectedPageAtom,
} from 'atoms'
import { useBoundingBox } from 'hooks'
import { Page, EditorToolbar, Loading, SwapImageMenu } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import * as S from './Canvas.styled'
import isEven from 'utils/global/isEven'
import zLayers from 'utils/styling/zLayers'

export default ({ loadedPages, theme, themeLayout, setShowSlideout, baseLayout }) => {
  const setCanvasRef = useSetRecoilState(canvasRefAtom)
  const setPageRef = useSetRecoilState(pageRefAtom)
  const selectedPage = useRecoilValue(selectedPageAtom)
  const canvasRef = useRef(null)
  const pageRef = useRef(null)
  const [isPMRegisterBook, setIsPMRegisterBook] = useState(false)
  const [productIsRegisterBook, setProductIsRegisterBook] = useState(false)
  const isDeleting = useRecoilValue(isPageDeleting)
  const setInsertMenuOpen = useSetRecoilState(insertMenuOpenAtom)
  const [isSwapImageMenuOpen, setIsSwapImageMenuOpen] = useRecoilState(
    openSwapImageMenuAtom,
  )

  useBoundingBox(canvasRef, pageRef)

  useEffect(() => {
    pageRef?.current && setPageRef(pageRef?.current)

    // eslint-disable-next-line
  }, [pageRef.current])

  useEffect(() => {
    canvasRef?.current && setCanvasRef(canvasRef?.current)
    // eslint-disable-next-line
  }, [canvasRef.current])

  useEffect(() => {
    const { collection, product, layout } = loadedPages[0].pageProperties
    collection === 'Precious Memories' &&
      product === 'Register Book' &&
      layout === 'Full Bleed' &&
      setIsPMRegisterBook(true)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { product } = loadedPages[0].pageProperties
    const isRegisterBook = product === 'Register Book Pages'
    setProductIsRegisterBook(isRegisterBook)
  }, [loadedPages])

  return (
    <S.Canvas
      justify="center"
      height="100%"
      flex="1"
      ref={canvasRef}
      align={isDeleting ? 'center' : undefined}
      onClick={() => setInsertMenuOpen(false)}
    >
      <Flex
        top="20px"
        full
        justify="center"
        position="absolute"
        data-bounding-box
        clickThrough
      >
        <Text zIndex={zLayers.interface}>
          {isEven(selectedPage) ? 'Back' : 'Front'} | Page {selectedPage} of{' '}
          {loadedPages.length}
        </Text>
      </Flex>
      {loadedPages && (
        <Loading isLoading={isDeleting}>
          <Page
            key={loadedPages?.[selectedPage - 1]?.product?.pageId}
            position="relative"
            top="64px"
            theme={theme}
            themeLayout={themeLayout}
            page={loadedPages?.[selectedPage - 1]}
            pageRef={pageRef}
            isPMRegisterBook={isPMRegisterBook}
            productIsRegisterBook={productIsRegisterBook}
            isEven={isEven(selectedPage)}
            environment="editor"
            baseLayout={baseLayout}
          />
        </Loading>
      )}
      <EditorToolbar setShowSlideout={setShowSlideout} />
      <SwapImageMenu
        show={isSwapImageMenuOpen}
        setShow={setIsSwapImageMenuOpen}
        className="slide-out"
      />
    </S.Canvas>
  )
}
