import { css } from 'styled-components'
import type { DefaultTheme } from 'styled-components'
import type { CSS, StyleVariantProps } from 'types/style'

type Props = StyleVariantProps & {
  children?: React.ReactNode
  theme?: DefaultTheme
}

type Styles = {
  [key: string]: CSS | undefined
  default?: CSS
}
/**
 * Converts boolean props to their corresponding CSS styles.
 *
 * @example
 * // Component usage:
 * <Button primary outline />
 *
 * // Style definitions:
 * const buttonStyles = {
 *   primary: css`background: blue;`,
 *   outline: css`border: 1px solid;`
 * }
 *
 * // In styled component:
 * ${props => propsToCSS(props, buttonStyles)}
 *
 * @param allProps - Component props object including:
 *   - Boolean flags for styles (e.g., primary: true)
 *
 * @param types - Object mapping prop names to CSS styles:
 *   - Keys match the boolean prop names
 *   - Values are CSS template literals
 *   - Can include a 'default' style applied when no props match
 *
 * @returns Combined CSS from all matching prop styles, or default style if none match
 *
 * @example
 * // Input:
 * allProps = { primary: true, outline: true }
 * types = {
 *   primary: css`color: blue;`,
 *   outline: css`border: 1px solid;`
 * }
 *
 * // Output:
 * css`
 *   color: blue;
 *   border: 1px solid;
 * `
 */
const propsToCSS = (allProps: Props, types: Styles) => {
  let totalCSS = `` as string | CSS
  const { children, theme, ...props } = allProps
  const propArray: [string, CSS | React.ReactNode][] = Object.entries(props)
  propArray.map(
    ([prop, value]) =>
      value &&
      types?.[prop] &&
      (totalCSS = css`
        ${totalCSS}${types?.[prop]}
      `),
  )

  const hasCSS = totalCSS !== ``
  const defaultStyle = types?.default || css``
  return hasCSS ? totalCSS : defaultStyle
}

export default propsToCSS
