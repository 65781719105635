import { css } from 'styled-components'
import bgBlur from 'styles/bgBlur'
import { default as itemDisabled } from 'styles/disabled'
import type { NumberRange } from 'types/utils'
import type { StyledFunction } from 'types/style'
import colors from 'utils/styling/colors'

export type DecorationProps = {
  opacity?: string | number
  radius?: string
  background?: string
  backgroundColor?: string
  bgOpacity?: NumberRange<0, 100>
  clickable?: boolean | number
  clickThrough?: boolean
  blur?: boolean
  overflow?: string
  transition?: string
  disabled?: boolean
  shadow?: string
  translateY?: string
}

const decorations: StyledFunction<DecorationProps> = ({
  opacity,
  radius,
  background = '',
  backgroundColor = '',
  bgOpacity = undefined,
  clickable,
  clickThrough,
  blur,
  overflow,
  transition,
  disabled,
  shadow,
  translateY,
}) => css`
  opacity: ${opacity};
  transform: translateY(${translateY});
  border-radius: ${radius};
  background: ${colors(background, bgOpacity)};
  background-color: ${colors(backgroundColor, bgOpacity)};
  cursor: ${clickable && `pointer`};
  pointer-events: ${clickThrough && `none`};
  transition: background-color 0.25s;
  overflow: ${overflow};
  transition: ${transition};
  box-shadow: ${shadow};
  ${blur && bgBlur}
  ${disabled && itemDisabled};
`

export default decorations
