import { useState, useEffect } from 'react'
import { Button, Input, ColorPicker, Dropdown } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import fonts from 'utils/styling/fonts'
import { updateCustomTheme } from 'api/customThemes'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ setShowNewThemeModal, createTheme, theme }) => {
  const handleError = useErrorHandler('NewThemeModal')
  const [themeName, setThemeName] = useState('')
  const [color, setColor] = useState('#000')
  const [font, setFont] = useState('Times New Roman')

  const save = async () => {
    try {
      const payload = {
        name: themeName,
        font_color: color,
        font: font,
      }
      !theme?.id && createTheme(payload)
      theme?.id && (await updateCustomTheme(theme.id, payload))
      setShowNewThemeModal(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    theme?.id && setThemeName(theme.name)
    theme?.id && setFont(theme.font)
    theme?.id && setColor(theme.font_color)
  }, [theme])
  return (
    <Flex
      column
      padding="32px"
      width="500px"
      height="500px"
      justify="space-between"
      overflow-y="auto"
    >
      <Flex column>
        <Text size="24px" weight="600" margin="0 0 20px 0">
          Theme Details
        </Text>
        <Input
          full
          label="Theme Name"
          placeholder="Theme Name"
          value={[themeName, setThemeName]}
          margin="0 0 24px 0"
        />
        <Dropdown
          selected={[font, setFont]}
          placeholder="Font"
          textKey="name"
          valueKey="name"
          label="Fonts"
          items={fonts}
          fontMenu={true}
          margin="0 0 24px 0"
        />
        <ColorPicker
          color={[color, setColor]}
          label="Font color"
          margin="0 0 24px"
        />
      </Flex>
      <Flex justify="flex-end" margin="24px 0 0 0">
        <Button margin="0 16px 0 0" onClick={() => setShowNewThemeModal(false)}>
          Cancel
        </Button>

        <Button left primary margin="0 12px 0 0" onClick={() => save()}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}
