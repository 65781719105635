import {
  insertableCaseDetails,
  insertableCaseDetailTables,
  insertableFuneralHomeDetails,
  insertableTemplates,
} from 'utils'

export default (editorProps) => {
  const { editor } = editorProps
  editor.ui.registry.addMenuButton('dynamicText', {
    text: 'Insert detail',
    fetch: (callback) => callback(populateInsertMenu(editorProps)),
  })
}

const populateInsertMenu = (editorProps) => {
  const templates = insertableTemplates(editorProps)
  const caseDetails = insertableCaseDetails(editorProps)
  const caseDetailTables = insertableCaseDetailTables(editorProps)
  const funeralHomeDetails = insertableFuneralHomeDetails(editorProps)

  // Get the name of deceased item first
  const nameOfDeceased = caseDetails.find(
    (item) => item.text === 'Name Of Deceased',
  )

  // Get all other items except name components
  const nameComponentTexts = [
    'Prefix Name',
    'First Name',
    'Nickname',
    'Middle Name',
    'Last Name',
    'Suffix Name',
    'Maiden Name',
  ]
  const otherItems = caseDetails.filter(
    (item) =>
      item !== nameOfDeceased && !nameComponentTexts.includes(item.text),
  )

  // Get name component items
  const nameComponents = caseDetails.filter((item) =>
    nameComponentTexts.includes(item.text),
  )

  return [
    // Name of Deceased first
    nameOfDeceased,
    // All other fields second
    ...otherItems,
    ...caseDetailTables,
    ...funeralHomeDetails,
    ...templates,
    // Individual name components last
    ...nameComponents,
  ].filter(Boolean) // Remove any undefined items
}
