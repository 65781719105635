import { Input, DateInput, Check } from 'components'
import { useRecoilState } from 'recoil'
import { editCaseFieldSelector } from 'atoms'
import { useState, useEffect } from 'react'
import { specialFieldBehaviors } from '@mdl/shared-consts'

export default ({ name, label, width, index, object_key }) => {
  const path = `value[${index}].${object_key.field || object_key}`
  const [localValue, setLocalValue] = useState('')
  const [recoilValue, setRecoilValue] = useRecoilState(
    editCaseFieldSelector({
      name: name,
      path: path,
    }),
  )
  const [showInput, setShowInput] = useState(true)

  label = label
    .split('_')
    .map((w) => w[0].toUpperCase() + w.substr(1))
    .join(' ')

  useEffect(() => {
    setLocalValue(recoilValue || '')
  }, [recoilValue])

  useEffect(() => {
    const hideInput = object_key?.field === specialFieldBehaviors.hidden.sequence

    if (hideInput) {
      setShowInput(false)
    }
  }, [object_key])

  const handleChange = (value) => {
    setLocalValue(value)
    setRecoilValue(value)
  }

  if (object_key.type === 'date') {
    return (
      <DateInput
        label={label}
        width={width}
        margin="0 0 24px 0"
        date={[recoilValue, setRecoilValue]}
        right
      />
    )
  } else if (object_key.type === 'check') {
    return (
      <Check
        margin="0 0 10px 0"
        width={width}
        checked={recoilValue || false}
        onClick={() => setRecoilValue(!recoilValue)}
        align="center"
        text={label}
      />
    )
  } else {
    return (
      showInput && (
        <Input
          width={width}
          margin="0 0 24px 0"
          label={label}
          value={[localValue, handleChange]}
        />
      )
    )
  }
}
