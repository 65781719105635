// this hook is meant for using colors outside of styled components

import { themeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import palettes from 'utils/styling/palettes'
import hexOpacity from 'utils/styling/hexOpacity'
import type {
  Palette,
  ColorValue,
  HexOpacityMap,
  OpacityPercentage,
} from 'types/style'

const useColors = () => {
  const theme = useRecoilValue(themeAtom) as keyof ColorValue

  return (color: keyof Palette, opacity: OpacityPercentage) => {
    const themeColor =
      palettes?.[color]?.[theme] || palettes?.[color]?.default || color
    const solidColor = themeColor
    const transparentColor = themeColor
      ? `${themeColor}${hexOpacity?.[opacity as keyof HexOpacityMap] || 100}`
      : color

    return opacity ? transparentColor : solidColor
  }
}

export default useColors
