import { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { toastsAtom } from 'atoms'
import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import isArray from 'lodash/isArray'
import type { Toast } from 'atoms'

const DEBOUNCE_TIME_MS = 300

const getUniquePrefix = () => `${Date.now()}-`;

const useSetToast = () => {
  const [toasts, setToasts] = useRecoilState(toastsAtom)
  const toastsLoadable = useRecoilValueLoadable(toastsAtom)
  const [isLoaded, setIsLoaded] = useState(false)
  const lastToastTime = useRef<number>(0)

  useEffect(() => {
    if (toastsLoadable.state === 'hasValue') setIsLoaded(true)
  }, [toastsLoadable.state])

  const addToastsFunction = (addToasts: Toast | Toast[]) => {
    if (!isLoaded) return

    const now = Date.now()
    const preventRapidFireToastCreation = now - lastToastTime.current < DEBOUNCE_TIME_MS
    if (preventRapidFireToastCreation) {
      return
    }

    lastToastTime.current = now
    const prefix = getUniquePrefix();

    if (isArray(addToasts)) {
      const newToasts = addToasts.map((toast, index) => ({
        ...toast,
        uuid: `${prefix}${index}-${uuidv4()}`,
      }))
      setToasts([...toasts, ...newToasts])
    } else {
      const newToast = { 
        ...addToasts, 
        uuid: `${prefix}-${uuidv4()}`
      }
      setToasts([...toasts, { ...newToast }])
    }
  }

  return addToastsFunction
}

export default useSetToast
