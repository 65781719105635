import applyStylingToNonEditable from 'utils/tinyMCE/plugins/applyStylingToNonEditable'
import type { EditorProps } from 'types/tinyMCE'
import regEx from 'utils/global/regEx'

type OutlineStyle = `${number}px`
type OutlineWidth = OutlineStyle | 'remove'
type OutlineColor = string
type OutlineResult = {
  width: OutlineStyle
  color: OutlineColor
}

const DEFAULT_OUTLINE: OutlineResult = {
  width: '1px' as const,
  color: 'black' as const,
} as const

// Outline stroke sizes for the menu
const OUTLINE_STROKE_SIZES: OutlineStyle[] = Array.from(
  { length: 10 },
  (_, i) => `${i + 1}px` as const,
)

const registerTextOutlinePlugin = (editorProps: EditorProps) => {
  const editorFunctions = applyStylingToNonEditable(editorProps)
  const { editor } = editorProps

  // misspelled words should allow outlines to be applied
  // This event listener runs orthogonally with applyStylingToNonEditable's NodeChange event listeners
  editor.on('NodeChange', () => {
    const node = editor.selection.getNode()
    const outlinedElement = node.classList?.contains('outline')
      ? node
      : node.closest('.outline')
    if (outlinedElement) {
      const blockParent = outlinedElement.closest('p')
      if (blockParent) {
        editor.dom.setAttribs(blockParent, { spellcheck: 'false' })
      }
      editor.dom.setAttribs(outlinedElement, { spellcheck: 'false' })
      outlinedElement.querySelectorAll('*').forEach((el: Element) => {
        editor.dom.setAttribs(el, { spellcheck: 'false' })
      })
    }
  })

  // Helper function to get the current text-stroke style of the selection
  const getTextOutline = (): OutlineResult => {
    const node = editor.selection.getNode()

    const isValidColor = (color: string) => {
      return (
        color.match(regEx.colorFormatIdentifier) ||
        color.match(regEx.lettersOnly)
      )
    }

    const getStyle = (node: Element, style: string) =>
      editor.dom.getStyle(node, style)
    const checkNodeStyles = (node: Element): OutlineResult | null => {
      // 1. Check direct styles
      const stroke = getStyle(node, '-webkit-text-stroke')
      if (stroke?.includes('px')) {
        const strokeParts = stroke.split(' ')
        const width = strokeParts[0]
        const color = strokeParts.slice(1).join(' ')
        if (isValidColor(color)) {
          return { width, color } as OutlineResult
        }
      }

      // 2. Check self or parents with outline class
      const outlineElement = node.classList?.contains('outline')
        ? node
        : node.closest('.outline')
      if (outlineElement) {
        const computed = window.getComputedStyle(outlineElement)
        const computedStroke = computed.getPropertyValue('-webkit-text-stroke')
        if (computedStroke?.includes('px')) {
          const strokeParts = computedStroke.split(' ')
          const width = strokeParts[0]
          const color = strokeParts.slice(1).join(' ')
          if (isValidColor(color)) {
            return { width, color } as OutlineResult
          }
        }
      }

      // 3. Check children with outline class
      const outlineChild = node.querySelector('.outline')
      if (outlineChild) {
        const computed = window.getComputedStyle(outlineChild)
        const computedStroke = computed.getPropertyValue('-webkit-text-stroke')
        if (computedStroke?.includes('px')) {
          const strokeParts = computedStroke.split(' ')
          const width = strokeParts[0]
          const color = strokeParts.slice(1).join(' ')
          if (isValidColor(color)) {
            return { width, color } as OutlineResult
          }
        }
      }

      return null
    }

    const result = checkNodeStyles(node)

    return result || DEFAULT_OUTLINE
  }

  const removeTextOutlineFromParent = (node: Element) => {
    let parent = node.closest('p')
    if (parent) {
      removeTextOutlineRecursive(parent)
    } else {
      removeTextOutlineRecursive(node)
    }
  }

  // Helper function to recursively remove the text-stroke from all child nodes
  const removeTextOutlineRecursive = (node: HTMLParagraphElement | Element) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      editor.dom.setStyle(node, '-webkit-text-stroke', null)
      editor.dom.setStyle(node, 'text-stroke', null)
      editor.dom.removeClass(node, 'outline')
      editor.dom.setAttribs(node, { spellcheck: 'true' })
      Array.from(node.children).forEach(removeTextOutlineRecursive)
    }
  }

  // Apply or remove the text-stroke style
  const applyTextOutline = (
    outlineWidth: OutlineWidth,
    color: OutlineColor,
  ) => {
    editor.formatter.register('customTextOutline', {
      inline: 'span',
      classes: ['outline'],
      styles: {
        '-webkit-text-stroke': `${outlineWidth} ${color}`,
        'text-stroke': `${outlineWidth} ${color}`,
      },
      attributes: {
        spellcheck: 'false',
      },
    })

    editorFunctions.unlockNonEditableBlocks()

    if (outlineWidth === 'remove') {
      editor.formatter.remove('customTextOutline')
      removeTextOutlineFromParent(editor.selection.getNode())
    } else {
      editor.formatter.apply('customTextOutline', {
        value: `${outlineWidth} ${color}`,
      })
    }

    editorFunctions.lockNonEditableBlocks()
  }

  // Add a button for removing the outline
  editor.ui.registry.addButton('removeOutline', {
    text: 'Remove Outline',
    onAction: () => {
      applyTextOutline('remove', 'black')
    },
  })

  // Add a menu button to select outline width
  editor.ui.registry.addMenuButton('outline', {
    text: 'Text Outline',
    fetch: (callback: (items: any[]) => void) => {
      const currentOutline = getTextOutline()
      const items = OUTLINE_STROKE_SIZES.map((outline) => ({
        type: 'menuitem' as const,
        text: `${outline}`,
        onAction: () => applyTextOutline(outline, currentOutline.color),
        active: currentOutline.width === outline,
      }))

      // Add an option to choose a custom outline color
      items.push({
        type: 'menuitem',
        text: 'Choose Outline Color',
        active: false,
        onAction: () => {
          // Open color picker dialog
          editor.windowManager.open({
            title: 'Pick Outline Color',
            initialData: {
              outlineColor: currentOutline.color,
            },
            body: {
              type: 'panel',
              items: [
                {
                  type: 'colorinput',
                  name: 'outlineColor',
                  label: 'Select Color',
                },
              ],
            },
            buttons: [
              {
                type: 'cancel',
                name: 'cancel',
                text: 'Cancel',
              },
              {
                type: 'submit',
                name: 'submit',
                text: 'Apply',
                primary: true,
              },
            ],
            onSubmit: (api: any) => {
              const data = api.getData()
              applyTextOutline(currentOutline.width, data.outlineColor)
              api.close()
            },
          })
        },
      })

      // Add an option to remove the outline
      items.push({
        type: 'menuitem',
        text: 'Remove Outline',
        onAction: () => applyTextOutline('remove', 'black'),
        active: !currentOutline.width,
      })

      callback(items)
    },
  })
}

export default registerTextOutlinePlugin
