const withinBoundingBox = (
  x: number,
  y: number,
  width: number,
  height: number,
  selectedRange: { top: number; right: number; left: number; bottom: number },
) => {
  const { top, right, left, bottom } = selectedRange

  const horizontalCenter = x + width / 2
  const verticalCenter = y + height / 2

  const verticalSelected = verticalCenter > top && verticalCenter < bottom
  const horizontalSelected = horizontalCenter > left && horizontalCenter < right
  const withinBoundingBox = verticalSelected && horizontalSelected

  // NOTE: Previous fully within bounding box calculation
  // const itemTop = y  //+24 to account for padding?
  // const itemRight = x + width
  // const itemBottom = y + height
  // const itemLeft = x  //+24 to account for padding?

  // const topSelected = itemTop > top
  // const rightSelected = itemRight < right
  // const bottomSelected = itemBottom < bottom
  // const leftSelected = itemLeft > left

  // const withinBoundingBox = topSelected && bottomSelected && rightSelected && leftSelected

  return withinBoundingBox
}

export default withinBoundingBox
