import { Item } from 'types/editor'

type DragElement = {
  deltaX: number
  deltaY: number
  lastX: number
  lastY: number
  node: Node
  x: number
  y: number
}

type Padding = {
  top: number
  left: number
  right: number
  bottom: number
}

type AlignmentPoints = {
  vertical: number[]
  horizontal: number[]
}

const calculateAlignmentSnap = (
  item: Item,
  dragElement: DragElement,
  alignmentPoints: AlignmentPoints,
  padding: Padding,
) => {
  const { width, height } = item
  const { vertical, horizontal } = alignmentPoints
  const itemVerticalMiddle = dragElement.x + width / 2
  const itemHorizontalMiddle = dragElement.y + height / 2
  const paddingTop = padding?.top || 0
  const paddingLeft = padding?.left || 0

  let snapVertical, snapHorizontal
  const snapInfo = {
    verticalArea: 0,
    verticalSnap: 0,
    horizontalSnap: 0,
    horizontalArea: 0,
  }
  const verticalSnapArea = 25
  const horizontalSnapArea = 10

  vertical.map((align) => {
    //get the vertical middle of the item (width divided by 2) and determine if that position is within a set distance from the vertical alignment point, if it is we snap to center and set the alignment line to be bolder (snapInfo tells the lines how to behave)
    if (
      itemVerticalMiddle > align - verticalSnapArea &&
      itemVerticalMiddle < align + verticalSnapArea
    ) {
      snapVertical = align - paddingLeft - width / 2
      snapInfo.verticalSnap = align
    }
    //for vertical alignments, we currently anticipate that the 'center' of the section (half for 2 page, third and two-third for trifolds) is not in the array (instead this is a divider). In this case each 'section' is the first align value +/- the current align point.
    //that is, the first section is for example 300px wide, the first align point is 150, the align area is 0-300 or 150 -/+ 150. the same will be true for each section, the second section is also 300px, but it's align point is 450, the align area is 300-600px or 450 -/+ 150
    //this formula does not account for oddly spaced products, see church bulletins with bookmark
    if (
      itemVerticalMiddle > align - vertical[0] &&
      itemVerticalMiddle < align + vertical[0]
    )
      snapInfo.verticalArea = align

    return align
  })
  horizontal.map((align) => {
    //get the horizontal middle of the item (height divided by 2) and determine if that position is within a set distance from the horizontal alignment point, if it is we snap to center and set the alignment line to be bolder (snapInfo tells the lines how to behave)
    if (
      itemHorizontalMiddle > align - horizontalSnapArea &&
      itemHorizontalMiddle < align + horizontalSnapArea
    ) {
      snapHorizontal = align - paddingTop - height / 2
      snapInfo.horizontalSnap = align
    }
    //for horizontal alignments, we currently anticipate that the 'center' of the section IS included in the align array (we do not have horizontal dividers). In this case each 'section' is HALF the first align value +/- the current align point.
    //that is, the first align point is 150, the align area is 75-225 or 150 -/+ 75. the same will be true for each section, the second align point is 300, the align area is 225-375px or 300 -/+ 75
    //this calculation is designed to allow the areas to split the shared internal space between align points, is does not account for edges, but for now this is an acceptable loss.

    if (
      itemHorizontalMiddle > align - horizontal[0] / 2 &&
      itemHorizontalMiddle < align + horizontal[0] / 2
    ) {
      snapInfo.horizontalArea = align
    }

    return align
  })

  return {
    snapVertical: snapVertical,
    snapHorizontal: snapHorizontal,
    snapInfo: snapInfo,
  }
}

export default calculateAlignmentSnap
