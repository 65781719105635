import { differenceInMilliseconds } from 'date-fns'

///////////////////////////////////////////////////////////////////////////////////////

const disableLogs = false
const disableNotes = false

///////////////////////////////////////////////////////////////////////////////////////

const start = Date.now()
const difference = (date: number) =>
  differenceInMilliseconds(date, start) / 1000
let counter = 1

const base = `color: white; margin: 5px 0; padding: 5px 20px; border-radius: 3px;`
const colors = {
  useEffect: `${base}background: Orange;`,
  async: `${base}background: DeepSkyBlue;`,
  function: `${base}background: Aquamarine; color: black;`,
  if: `${base}background: yellow; color: black;`,
  else: `${base}background: HotPink;`,
  recoil: `${base}background: HoneyDew; color: black;`,
  important: `${base}background: Red;`,
} as const

export const log = (message: string, type: string) => {
  // uncomment the following below to remove the type from the logs

  // if (type === 'useEffect') return
  // if (type === 'function') return
  // if (type === 'async') return
  // if (type === 'if') return
  // if (type === 'else') return
  // if (type === 'recoil') return

  !disableLogs &&
    console.log(
      `%c${counter}: ${message} (${difference(Date.now())}s)`,
      colors?.[type as keyof typeof colors] || '',
    )
  counter++
}

export const note = (message: string) =>
  !disableNotes && console.log(`%c${message}`, 'color: gray; margin: 5px 20px;')

export const logRender = console.log(
  `%c\n RERENDERING \n`,
  'color: darkred; font-weight: bold;',
)

///////////////////////////////////////////////////////////////////////////////////////
