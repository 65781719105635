import { useState, useRef } from 'react'
import { CustomVerseEditor } from '.'
import { updateCustomVerse, getCustomVerses } from 'api/customVerses'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { stylizedVersesAtom, themeLayoutAtom, editorProductIdAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'

export default ({ verse, setEditing }) => {
  const handleError = useErrorHandler('UpdateCustomVerse')
  const setStylizedVerses = useSetRecoilState(stylizedVersesAtom)
  const themeLayout = useRecoilValue(themeLayoutAtom)
  const productId = useRecoilValue(editorProductIdAtom)
  const TinyMCERef = useRef(null)
  const [editReference, setEditReference] = useState(verse.reference)
  const [verseImage, setVerseImage] = useState(verse.image_url)
  const [isGlobalVerse, setIsGlobalVerse] = useState(verse.is_global)
  const [isThemeVerse, setIsThemeVerse] = useState(
    !!verse.theme_id && !!verse.base_product_id,
  )

  const { product_id, theme_id } = verse
  const { product_id: defaultProductId, theme_id: defaultThemeId } =
    themeLayout || {}

  const patchVerse = async () => {
    try {
      const content = TinyMCERef?.current?.getContent?.()
      const updatedVerse = {
        html: content || '',
        html_for_render: verse.type === 'IMAGE' ? '' : content,
        reference: editReference,
        isGlobal: isGlobalVerse,
        image_url: verseImage,
        type: verse.type,
        base_product_id: isGlobalVerse
          ? null
          : isThemeVerse
          ? product_id ?? defaultProductId
          : null,
        theme_id: isGlobalVerse
          ? null
          : isThemeVerse
          ? theme_id ?? defaultThemeId
          : null,
        order_product_id: isThemeVerse ? null : productId,
      }
      await updateCustomVerse(verse.id, updatedVerse)
      const stylizedVersesData = await getCustomVerses(
        defaultThemeId,
        defaultProductId,
        productId,
      )
      setStylizedVerses(stylizedVersesData.data)

      setEditing(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return (
    <CustomVerseEditor
      TinyMCERef={TinyMCERef}
      value={verse.html}
      verseId={verse.id}
      type={verse.type}
      image={[verseImage, setVerseImage]}
      reference={[editReference, setEditReference]}
      cancel={() => setEditing(false)}
      save={() => patchVerse()}
      global={[isGlobalVerse, setIsGlobalVerse]}
      themeVerse={[isThemeVerse, setIsThemeVerse]}
    />
  )
}
