import styled from 'styled-components'
import colors from 'utils/styling/colors'

const Handler = styled.div.attrs(({ position }) => ({
  style: {
    cursor:
      position === 'n' || position === 's'
        ? 'ns-resize'
        : position === 'w' || position === 'e'
          ? 'ew-resize'
          : 'default',
  },
}))`
  position: absolute;
  width: 6px;
  height: 25px;
  background-color: ${colors('brand', 75)};
  z-index: 2;
  border-radius: 3px;
`

export const BottomHandler = styled(Handler)`
  bottom: -13.5px;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
`

export const RightHandler = styled(Handler)`
  right: -4px;
  top: 50%;
  transform: translateY(-50%);
`

export const CropOverlay = styled.div.attrs(({ isFitting }) => ({
  style: {
    backgroundImage: isFitting
      ? `
    linear-gradient(to right, rgba(255,255,255,0.2) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255,255,255,0.2) 1px, transparent 1px)
  `
      : 'none',
    backgroundSize: isFitting ? '20px 20px' : undefined,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 1;
`
