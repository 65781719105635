import axios from 'axios'
import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import api from 'utils/global/api'

/**
 * Axios instance for API requests
 *
 * @description
 * - Uses base URL from global api object
 * - Includes request/response interceptors
 * - Handles authentication headers automatically
 *
 * @example
 * // Component
 * const MyComponent = () => {
 *   await archive(selectedLayout.id)
 * }
 *
 * // API function
 * export const archive = async (pagesetId: string) => {
 *   const response = await axios.patch(
 *     `archivePageSet/${pagesetId}`,
 *     {},
 *   )
 *   return response
 * }
 */
const instance: AxiosInstance = axios.create({ baseURL: api })

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const messengerOrderIdToken = localStorage.getItem('messengerOrderIdToken')
    if (messengerOrderIdToken) {
      config.headers = config.headers || {}
      config.headers['x-order'] = messengerOrderIdToken
    }

    return config
  },
  (error: AxiosError) => {
    console.error('Axios Request Error', error)
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    console.error('Axios Response Error', error)
    return Promise.reject(error)
  },
)

instance.defaults.withCredentials = false

export default instance
