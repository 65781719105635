const themeCategories = {
  'Suedelux/Cloth': [
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '140 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '142 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '140 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '140 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '140 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '140 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '506 Royal Blue In Memory Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '140 Mauve Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '136 Navy Tree of Life Series',
      productLine: 'Reflections of Life',
    },
    {
      title: '315 White In Memory Series',
      productLine: 'Reflections of Life',
    },
  ],
} as const

export default themeCategories
