import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { FC, PropsWithChildren } from 'react'

const FallbackProvider: FC<PropsWithChildren> = ({ children }) => (
  <>{children}</>
)

const initLaunchDarklyClient = async () => {
  const clientSideID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID
  if (!clientSideID) {
    console.warn(
      'Launch Darkly client id is not set, feature flags will not be available',
    )
    return FallbackProvider
  }

  try {
    return await asyncWithLDProvider({
      clientSideID,
    })
  } catch (error) {
    console.error('Failed to initialize Launch Darkly client:', error)
    return FallbackProvider
  }
}

export default initLaunchDarklyClient
