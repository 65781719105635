import regEx from 'utils/global/regEx'

const camelToSnakeCase = (str: string) =>
  str.replace(regEx.uppercaseLetter, (letter) => `_${letter.toLowerCase()}`)

const convertKeysToSnakeCase = (
  obj: Record<string, any>,
): Record<string, any> => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item))
  } else if (obj !== null && typeof obj === 'object') {
    return Object.entries(obj).reduce(
      (acc: Record<string, any>, [key, value]) => {
        const newKey = camelToSnakeCase(key)
        acc[newKey] = convertKeysToSnakeCase(value)
        return acc
      },
      {},
    )
  }
  return obj
}

export default convertKeysToSnakeCase
