import { useEffect, useCallback, useState } from 'react'
import Text from 'components/priority/Text/Text'
import Icon from 'components/priority/Icon/Icon'
import ToastAction from './ToastAction'
import * as S from './Toast.styled'

const MAX_STAY_LIFETIME_SECONDS = 5

export default ({
  text,
  icon,
  timeout = 3,
  stay,
  actions,
  timerFinished,
  uuid,
  index,
  ...props
}) => {
  const [hovering, setHovering] = useState(false)
  const [counter, setCounter] = useState(timeout + 1)
  const [holdPosition, setHoldPosition] = useState(index)
  const initialStayLifetime = stay ? MAX_STAY_LIFETIME_SECONDS : 0
  const [stayLifetime, setStayLifetime] = useState(initialStayLifetime)

  const removeToast = useCallback(() => {
    timerFinished(uuid)
  }, [timerFinished, uuid])

  const stopTimer = () => {
    setHoldPosition(index)
    setHovering(true)
  }

  const resumeTimer = () => {
    setHoldPosition(index)
    if (!stay) {
      // resumes timer when no longer hovering
      setHovering(false)
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    }
  }

  useEffect(() => {
    if (!stay) {
      // begins a countdown timer
      const timer =
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)

      // pauses timer on hover
      hovering && clearTimeout(timer)

      // removes the timer when the component is not longer rendered
      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [counter, hovering])

  useEffect(() => {
    if (!stay) {
      // removes toast from queue when timer is complete
      !counter && removeToast()
    }
    // eslint-disable-next-line
  }, [counter, removeToast])

  useEffect(() => {
    const isActiveStayToast = stay && stayLifetime > 0
    if (isActiveStayToast) {
      const stayTimer = setTimeout(() => {
        setStayLifetime(stayLifetime - 1)
      }, 1000)

      const stayExpired = stayLifetime === 1
      if (stayExpired) {
        removeToast()
      }

      return () => clearTimeout(stayTimer)
    }
  }, [stay, stayLifetime, removeToast])

  return (
    <S.ToastWrapper
      position="absolute"
      index={index}
      bottom={`${(hovering ? holdPosition : index) * 60}px`}
    >
      <S.Toast
        center
        padding="18px"
        radius="2px"
        background="gray1"
        position="relative"
        height="48px"
        show={stay || counter > 1}
        onMouseEnter={() => stopTimer()}
        onMouseLeave={() => resumeTimer()}
        {...props}
      >
        <Text color="white" size="12px" weight="500" whiteSpace="nowrap">
          {text}
        </Text>
        {icon && <Icon icon={icon} margin="0 0 0 8px" fill="white" />}
        {actions?.map((action, i) => (
          <ToastAction key={i} removeToast={removeToast} {...action} />
        ))}
      </S.Toast>
    </S.ToastWrapper>
  )
}
