import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { caseAtom } from 'atoms'
import { AssetDrawers, Button, Input, LoadingInsertImages } from 'components'
import Text from 'components/priority/Text/Text'
import { getFloralArrangements } from 'api/floralArrangements'
import { Flex } from 'components/priority/Flex/Flex.styled'
import { usePageShuffler } from 'hooks'
import chunk from 'lodash/chunk'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ setShow, basePages }) => {
  const handleError = useErrorHandler('FloralArrangementsMenu')
  const srcPrefix = process.env.REACT_APP_S3BUCKET_FLORAL_PIX
  const _case = useRecoilValue(caseAtom)
  const [loading, setLoading] = useState(true)
  const [arrangements, setArrangements] = useState()
  const [arrangementsPerPage, setArrangementsPerPage] = useState(0)
  const { addPage } = usePageShuffler(basePages)

  const getItems = async () => {
    try {
      setLoading(true)
      const { data } = await getFloralArrangements(_case.id)
      if (data) {
        const sanitized = data.map(
          ({ name, cards_images, flowers_images }) => ({
            name,
            images: [...cards_images, ...flowers_images],
          }),
        )
        setArrangements(sanitized)
      }
      setLoading(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    _case.id && getItems()
    // eslint-disable-next-line
  }, [_case])

  const chunkify = (chunkSize) => {
    const chunkedArrangements = chunk(arrangements, chunkSize).map((chunk) => {
      const obj = {
        images: [],
      }

      chunk.forEach(({ images }) =>
        images.forEach((src) => obj.images.push(src)),
      )

      return obj
    })

    return chunkedArrangements
  }

  const handleAddPages = () => {
    if (arrangementsPerPage === 1) {
      return addPage(arrangements)
    }

    if (arrangementsPerPage === 2) {
      const chunkedArrangements = chunkify(2)

      return addPage(chunkedArrangements)
    }

    if (arrangementsPerPage === 3) {
      const chunkedArrangements = chunkify(3)

      return addPage(chunkedArrangements)
    }
  }

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex style={{ overflow: 'auto' }} column>
      <Flex padding="0 24px">
        <Input
          width="70px"
          value={[arrangementsPerPage, setArrangementsPerPage]}
          type="number"
          min={0}
          max={3}
        />
        <Button primary center margin="0 0 0 8px" onClick={handleAddPages}>
          <Text color="white" whiteSpace="nowrap">
            Add pages
          </Text>
        </Button>
      </Flex>
      <Flex full center>
        <AssetDrawers
          drawers={arrangements}
          categoryKey="images"
          categoryNameKey="name"
          nameKey="images"
          setShow={setShow}
          srcPrefix={srcPrefix}
        />
      </Flex>
    </Flex>
  )
}
