import { atom } from 'recoil'

export type Toast = {
  icon?: string
  text: string
  uuid?: string
}

export const toastsAtom = atom<Toast[]>({
  key: 'toasts',
  default: [],
})
