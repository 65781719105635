import { useState, useEffect } from 'react'
import Flex from 'components/priority/Flex/Flex'
import Icon from 'components/priority/Icon/Icon'
import Text from 'components/priority/Text/Text'
import startCase from 'lodash/startCase'
import capitalize from 'lodash/capitalize'
import * as Menus from '../Menus'
import * as S from './SlideoutMenu.styled'
import { hasNewCaseAtom, caseAtom } from 'atoms'
import { updateCase } from 'api/cases'
import { default as _omit } from 'lodash/omit'
import { useRecoilCallback, useResetRecoilState, useRecoilState } from 'recoil'
import { caseFieldsAtom, caseValuesAtom } from 'atoms'
import { useSetToast } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import sanitizeCaseStations from 'utils/sanitizers/sanitizeCaseStations'
import { useTriggerAutosize } from 'hooks/editor/useTextAutosize'

export default ({
  menu,
  title,
  show: [show, setShow] = [false, () => {}],
  theme,
  themeLayout,
  basePages,
  baseLayout,
  setIsLoading,
  isSearchMenu,
  update,
  ...props
}) => {
  const SelectedMenu = Menus?.[`${menu}Menu`]
  const setToast = useSetToast()
  const handleError = useErrorHandler('SlideoutMenu')
  const resetHasNewCase = useResetRecoilState(hasNewCaseAtom)
  const [_case, setCase] = useRecoilState(caseAtom)
  const [pairs, setPairs] = useState([])
  const triggerAutosize = useTriggerAutosize()

  //Borrowed from code example here:https://codesandbox.io/s/stackoverflow-63038077-recoil-family-forms-xm6p1?file=/src/App.js
  const loadInitialData = useRecoilCallback(({ set }) => (pairs) => {
    const ids = []
    for (const field of pairs) {
      //pairs is an array of object from case => [{name_of_deceased: "john smith"}]
      //first create an array of 'ids' these will be unique keys stored in the caseFieldsAtom atom
      ids.push(field.id)
      //then store the object on the caseValuesAtom atom family
      set(caseValuesAtom(field.id), () => field)
    }
    set(caseFieldsAtom, ids)
  })

  useEffect(() => {
    loadInitialData(pairs)

    //eslint-disable-next-line
  }, [pairs])

  const mapValuePairs = (_case) => {
    //map the case object into an array of object for each key/value
    const mapped = _case
      ? Object.keys(_case).map((key) => ({
          id: key,
          value: _case[key],
        }))
      : []
    setPairs(mapped)
  }

  useEffect(() => {
    const caseTmp = { ..._case }
    caseTmp.us_stations_where_served = sanitizeCaseStations(
      caseTmp?.us_stations_where_served,
    )

    if (!caseTmp?.services) {
      caseTmp.services = []
    }
    caseTmp.id && mapValuePairs(caseTmp)
  }, [_case])

  const transformNameOfDeceased = (payload) => {
    const {
      prefix_dec,
      first_dec,
      nick_name_dec,
      middle_dec,
      maiden_dec,
      lastdec,
      suffix_dec,
    } = payload
    const formattedNickName = nick_name_dec ? `"${nick_name_dec}"` : ''
    const formattedMaidenName = maiden_dec ? `(${maiden_dec})` : ''

    const name_of_deceased = [
      prefix_dec,
      first_dec,
      formattedNickName,
      middle_dec,
      formattedMaidenName,
      lastdec,
      suffix_dec,
    ]
      .filter(Boolean)
      .join(' ')

    return name_of_deceased
  }

  const saveCase = useRecoilCallback(({ snapshot }) => async () => {
    //get a snapshot of the recoil state (this is fuzzy to me still)
    //get the ids from the caseFieldsAtom atom
    const ids = await snapshot.getPromise(caseFieldsAtom)
    //create an empty array to store the returned values
    const allValues = []
    //extract the case_id for the patch
    let case_id = ''
    //this code came from the same sample listed above
    //for each id get the caseValue - which is the key/value object
    //push that to the array and save the case_id seperately
    for (const field of ids) {
      const caseValue = await snapshot.getPromise(caseValuesAtom(field))
      if (caseValue.id === 'id') case_id = caseValue.value
      allValues.push(caseValue)
    }
    //map the array of key/value objects back into one flat object
    const mapped = allValues.map((item) => ({ [item.id]: item.value }))
    const caseObj = Object.assign({}, ...mapped)
    //prep to send
    //the server will reject a payload with certain properties, this will remove those and then send the payload.
    const omit = [
      'id',
      'funeral_home_id',
      'created_at',
      'updated_at',
      'archived',
      'filledImages',
      'age',
      'age_years',
      'age_months',
      'age_days',
    ]
    const payload = _omit(caseObj, omit)
    payload.name_of_deceased = transformNameOfDeceased(payload)

    if (case_id.length > 0) {
      try {
        const { data } = await updateCase(case_id, payload)
        //on response update the pairs which triggers the data load and also update the cases table so if they close out the modal it's up-to-date
        mapValuePairs(data)
        resetHasNewCase()
        update && update(false, true)
        setCase(data)
        triggerAutosize()
        setToast({ text: 'Case updated successfully!' })
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
      }
    }
  })

  return (
    <S.SlideoutMenu
      top="0"
      left="calc(100% + 1px)"
      position="absolute"
      height="100%"
      width={show ? '470px' : '0'}
      background="white"
      transition=".25s"
      show={show}
      {...props}
    >
      <Flex column minWidth="470px" height="100%">
        <Flex
          justify="space-between"
          align="center"
          margin="24px 0"
          padding="8px 32px"
          gap="8px"
        >
          <Text weight="600" size="16px">
            {!isSearchMenu ? capitalize(startCase(title)) : 'Search Results  '}
          </Text>
          <Icon
            clickable
            onClick={() => setShow(false)}
            icon="exitModal"
            fill="gray1"
            hoverfill="gray2"
          />
        </Flex>
        <S.Content column flex="1" overflowY="auto" overflowX="hidden">
          {SelectedMenu && (
            <SelectedMenu
              setShow={setShow}
              theme={theme}
              themeLayout={themeLayout}
              basePages={basePages}
              baseLayout={baseLayout}
              setIsLoading={setIsLoading}
              show={show}
              update={update}
              saveCase={saveCase}
              case_id={_case.id}
              name_of_deceased={_case.name_of_deceased}
            />
          )}
        </S.Content>
      </Flex>
    </S.SlideoutMenu>
  )
}
