import { useRecoilState } from 'recoil'
import { caseAtom, objectSelector } from 'atoms'

export default () => {
  const [_case, setImages] = useRecoilState(
    objectSelector({ atom: caseAtom, property: 'filledImages' }),
  )

  const fillImages = (newPersonalImages = null) => {
    const personal_images = newPersonalImages || _case?.personal_images || []
    let images = []
    while (images.length < 20 && personal_images?.length > 0) {
      images = images.concat(personal_images)
    }
    setImages(images)
  }

  return fillImages
}
