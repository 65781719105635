import { EditorProps } from 'types/tinyMCE'

const skinLoadPatch = (editorProps: EditorProps) => {
  const { editor } = editorProps
  editor.on('SkinLoaded', () => {
    try {
      requestAnimationFrame(() => {
        if (editor?.ui && editor?.getBody()) {
          editor.ui.hide()
          editor.getBody().blur()
        }
      })
    } catch (error) {
      console.error(
        'useTinyMCESettings: SkinLoaded event requestAnimationFrame error:',
        error,
      )
    }
  })
}

export default skinLoadPatch
