import { useState } from 'react'
import { Button, ModalButton, TinyMCE } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { deleteCustomVerse, getCustomVerses } from 'api/customVerses'
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil'
import {
  itemAtomFamily,
  itemIdAtom,
  stylizedVersesAtom,
  themeLayoutAtom,
  editorProductIdAtom,
  roleAtom,
} from 'atoms'
import { v4 as uuidv4 } from 'uuid'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import zLayers from 'utils/styling/zLayers'
import { PersonalImageCard } from 'components/dashboard/CaseDetails'
import { getImageDimensions } from 'utils'

export default ({ verse, setEditing, setShow }) => {
  const handleError = useErrorHandler('InsertCustomVerse')
  const setStylizedVerses = useSetRecoilState(stylizedVersesAtom)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const itemIds = useRecoilValue(itemIdAtom)

  const themeLayout = useRecoilValue(themeLayoutAtom)
  const productId = useRecoilValue(editorProductIdAtom)
  const role = useRecoilValue(roleAtom)

  const { product_id: defaultProductId, theme_id: defaultThemeId } =
    themeLayout || {}

  const deleteVerse = async (verse) => {
    try {
      await deleteCustomVerse(verse.id)

      const stylizedVersesData = await getCustomVerses(
        defaultThemeId,
        defaultProductId,
        productId,
      )
      setStylizedVerses(stylizedVersesData.data)

      setConfirmOpen(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const addToItems = useRecoilCallback(({ set }) => (item) => {
    set(itemAtomFamily(item.id), item)
    set(itemIdAtom, [...itemIds, item.id])
  })

  const insertVerseHTML = async () => {
    const uuid = uuidv4()

    const textObj = {
      id: uuid,
      type: 'text',
      x: 0,
      y: 0,
      z: zLayers.content,
      width: 250,
      height: 250,

      text: {
        value: verse.html,
        edits: {},
      },
    }
    addToItems(textObj)
    setShow(false)
  }

  const insertVerseImage = async (e) => {
    const uuid = uuidv4()
    const d = await getImageDimensions(verse.image_url)
    const height = 150
    const width = height * (d.width / d.height)
    const imageObj = {
      id: uuid,
      type: 'image',
      x: 0,
      y: 0,
      z: zLayers.content,
      height: height,
      width: width,
      assetLocked: false,
      properties: {
        subtype: 'stylizedVerse',
        caseImage: '',
      },
      image: {
        url: verse.image_url,
        edits: {},
      },
    }
    addToItems(imageObj)
    setShow(false)
  }

  const isStylizedVerse = !verse.html && verse.type === 'IMAGE'
  const isMessengerUser = role === 'messenger-user'

  return (
    <Flex full column>
      <Flex
        full
        margin="0 0 12px 0"
        clickable
        onClick={() =>
          isStylizedVerse ? insertVerseImage() : insertVerseHTML()
        }
      >
        {!isStylizedVerse && (
          <Flex
            full
            clickThrough
            padding="1px"
            background={isStylizedVerse ? undefined : 'lockedWhite'}
          >
            <TinyMCE init={{ toolbar: [] }} value={verse.html} />
          </Flex>
        )}
        {isStylizedVerse && (
          <Flex full column width="100%">
            <PersonalImageCard
              image={verse.image_url}
              index={0}
              makeDefault={true}
              margin="0 0 24px 0"
              allowDelete={false}
              backgroundColor="#EDEFF0"
            />
          </Flex>
        )}
      </Flex>
      {!isStylizedVerse || isMessengerUser ? (
        <Flex>
          <ModalButton
            text="Delete"
            margin="0 12px 0 0"
            show={[confirmOpen, setConfirmOpen]}
          >
            <Flex margin="24px">
              <Flex column>
                <Text weight="600" size="16px" margin="0 0 24px 0">
                  Are you sure?
                </Text>
                <Flex>
                  <Button
                    margin="0 12px 0 0"
                    onClick={() => setConfirmOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button destructive onClick={() => deleteVerse(verse)}>
                    Confirm
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </ModalButton>
          <Button primary onClick={() => setEditing(true)} margin="0 12px 0 0">
            Edit
          </Button>
        </Flex>
      ) : null}
    </Flex>
  )
}
