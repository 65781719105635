import { Input } from 'components'
import { useRecoilState } from 'recoil'
import { editSettingsFieldSelector } from 'atoms'
import { useState, useEffect } from 'react'

export default ({ name, label, width, margin }) => {
  const [localValue, setLocalValue] = useState('')
  const [recoilValue, setRecoilValue] = useRecoilState(
    editSettingsFieldSelector({ name: name, path: 'value' }),
  )

  useEffect(() => {
    setLocalValue(recoilValue || '')
  }, [recoilValue])

  const handleChange = (value) => {
    setLocalValue(value)
    setRecoilValue(value)
  }

  return (
    <Input
      width={width}
      margin={margin || '0 24px 0 0'}
      label={label}
      value={[localValue, handleChange]}
    />
  )
}
