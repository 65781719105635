import { ThemeProps } from 'types/DBTheme'
import regEx from 'utils/global/regEx'

// TODO: Let's centralize all validations and sanatiziations
const isValidColor = (color: string): boolean => {
  return regEx.validColor.test(color)
}

const getThemeStylesForTemplate = (props: ThemeProps): string => {
  const { selectedTheme } = props
  try {
    const font = selectedTheme?.font?.trim() ?? ''
    const fontColor = selectedTheme?.font_color?.trim() ?? ''

    const themeFontFamily = font ? `font-family: ${font};` : ''
    const themeFontColor =
      fontColor && isValidColor(fontColor) ? `color: ${fontColor};` : ''
    const styles = `${themeFontFamily} ${themeFontColor}`.trim()

    return styles
  } catch (error) {
    console.error('Error generating theme styles for template:', error)
    return ''
  }
}

export default getThemeStylesForTemplate
