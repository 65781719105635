import { caseAtom } from 'atoms'
import { Button } from 'components'
import { useRecoilValue } from 'recoil'
import regEx from 'utils/global/regEx'

export default () => {
  const caseDetails = useRecoilValue(caseAtom)
  const returnUrl = caseDetails.return_url
  const showReturnButton = returnUrl

  const ensureUrlProtocol = (url) => {
    return regEx.urlScheme.test(url) ? url : `https://${url}`
  }

  if (!showReturnButton) return null

  return (
    <a href={ensureUrlProtocol(returnUrl)}>
      <Button primary>Return to order</Button>
    </a>
  )
}
