export default (props) => {
  const { editor, tinyMceEvent, selectedTheme, isVerse = false } = props
  const targetElement = tinyMceEvent.target
  const bodyElement = targetElement.bodyElement
  const textBoxValue = bodyElement.innerText ?? ''
  const hasSpan = !!bodyElement.querySelector('span')
  const defaultTextBoxValue = 'New Text Box'

  /* Skip styling if:
   * - This is not a default text box
   * - Text box already contains formatted content (span elements)
   * - Is not a verse
   */
  if (!isVerse && (textBoxValue.trim() !== defaultTextBoxValue || hasSpan))
    return

  try {
    const hasThemeFontFamily = !!selectedTheme?.font
    const hasThemeFontColor = !!selectedTheme?.font_color
    const badFontFamily = [
      'AG University',
      'Amazone',
      'Aniyah',
      'Big Caslon',
      'Eyeglass',
    ]

    const defaultFontColor = hasThemeFontColor
      ? selectedTheme.font_color
      : '#000000'
    const defaultFontFamily =
      hasThemeFontFamily && !badFontFamily.includes(selectedTheme?.font)
        ? selectedTheme.font
        : 'Times New Roman'

    if (!isVerse) {
      const bodyContent = targetElement.contentAreaContainer
      editor.selection.select(bodyContent)
    }

    const applyFormatter = () => {
      editor.formatter.apply('forecolor', {
        value: defaultFontColor,
      })
      editor.formatter.apply('fontname', {
        value: defaultFontFamily,
      })

      if (!isVerse) {
        editor.ui.hide()
        editor.selection.collapse()
        editor.getBody().blur()
      }
    }

    requestAnimationFrame(applyFormatter)
  } catch (error) {
    console.error('Failed to apply theme styles:', error)
  }
}
