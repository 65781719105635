import { Navigation, Image } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import { underConstructionImage } from 'assets'

export default () => {
  return (
    <>
      <Navigation type={null} />
      <Flex justify="space-around" align="center" padding="150px 0">
        <Flex width="476px" column>
          <Text size="40px" weight="600" margin="0 0 16px 0">
            Under Construction
          </Text>
          <Text size="28px" color="gray">
            We’re making lots of improvements and will be back soon.
          </Text>
        </Flex>

        <Image
          width="476px"
          alt="Tools Vectors"
          src={underConstructionImage}
          margin="0 100px 0 0"
        />
      </Flex>
    </>
  )
}
