import styled from 'styled-components'
import { StyledComponentType } from 'types/style'
import zLayers from 'utils/styling/zLayers'

type ContainerProps = {
  height: number
  width: number
}

type FrameProps = {
  color: string
  thickness: number
}

export const Container: StyledComponentType<
  'div',
  ContainerProps
> = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  display: flex;
  z-index: ${zLayers.overlayTop};
  pointer-events: none;
`

export const TopFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: 0;
  left: ${({ thickness }) => `${thickness}px`};
  right: ${({ thickness }) => `${thickness}px`};
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const RightFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: ${({ thickness }) => `${thickness}px`};
  right: 0;
  bottom: ${({ thickness }) => `${thickness}px`};
  width: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const BottomFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  bottom: 0;
  left: ${({ thickness }) => `${thickness}px`};
  right: ${({ thickness }) => `${thickness}px`};
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const LeftFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: ${({ thickness }) => `${thickness}px`};
  left: 0;
  bottom: ${({ thickness }) => `${thickness}px`};
  width: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const TopLeftCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ thickness }) => `${thickness}px`};
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const TopRightCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ thickness }) => `${thickness}px`};
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const BottomLeftCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${({ thickness }) => `${thickness}px`};
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const BottomRightCorner: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  bottom: 0;
  right: 0;
  width: ${({ thickness }) => `${thickness}px`};
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const VerticalCenterFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  top: ${({ thickness }) => `${thickness}px`};
  bottom: ${({ thickness }) => `${thickness}px`};
  left: 50%;
  transform: translateX(-50%);
  width: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`

export const HorizontalCenterFrame: StyledComponentType<
  'div',
  FrameProps
> = styled.div<FrameProps>`
  position: absolute;
  left: ${({ thickness }) => `${thickness}px`};
  right: ${({ thickness }) => `${thickness}px`};
  top: 50%;
  transform: translateY(-50%);
  height: ${({ thickness }) => `${thickness}px`};
  background: ${({ color }) => color};
`
