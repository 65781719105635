import { Button } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import * as S from './GeneratingModal.styled'

type GeneratingModalProps = {
  setShowGeneratingModal: (show: boolean) => void
  generationState: {
    status?: 'IN_QUEUE' | 'GENERATING' | 'COMPLETE' | 'TIMEOUT' | 'ERROR'
    progress?: number
    pdf?: string
  }
  setGenerationState: (state: GeneratingModalProps['generationState']) => void
  getOrderProducts: () => void
  isMessengerUser: boolean
  productStatus: string
  canRegularUserPrint: boolean
}

export const GeneratingModal = ({
  setShowGeneratingModal,
  generationState,
  setGenerationState,
  getOrderProducts,
  isMessengerUser,
  productStatus,
  canRegularUserPrint
}: GeneratingModalProps) => {
  const pdf = generationState.pdf || ''
  const progress = pdf ? 100 : generationState.progress || 0
  const isProductClosed = productStatus === 'CLOSED'
  const canPrint = pdf && (isMessengerUser || canRegularUserPrint)
  const statusMessages = {
    IN_QUEUE: 'Your PDF is in queue, waiting to be generated...',
    GENERATING: 'Creating your PDF...',
    COMPLETE: 'Your PDF is ready!',
    TIMEOUT: 'PDF generation is taking longer than expected. Retrying...',
    ERROR: 'Something went wrong creating your PDF. Please try again.',
  }

  const downloadPDF = () => {
    window.open(pdf)
  }

  const close = () => {
    setShowGeneratingModal(false)
    setGenerationState({})

    // This avoids to update the product status to finalize/proof_sent if current status is closed
    if (!isProductClosed) getOrderProducts()
  }

  return (
    <Flex height="200px" width="600px" column center gap="32px">
      <Text weight="500" size="24px" align="center" padding="0 8px">
        {statusMessages[generationState.status as keyof typeof statusMessages]}
      </Text>
      <Flex gap="24px">
        <S.Indicator active={progress >= 25} />
        <S.Indicator active={progress >= 50} />
        <S.Indicator active={progress >= 75} />
        <S.Indicator active={progress >= 100} />
      </Flex>
      <Flex gap="12px">
        <Button onClick={close} icon={null} iconLeft={null}>
          {progress >= 100 ? 'Close' : 'Cancel'}
        </Button>
        {canPrint && (
          <Button primary onClick={downloadPDF} icon={null} iconLeft={null}>
            Download PDF
          </Button>
        )}
      </Flex>
    </Flex>
  )
}
