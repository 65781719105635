import { useState, useMemo } from 'react'
import { CustomVerseEditorMenu, InsertCustomVerseMenu } from './CustomVerses'
import { useRecoilValue } from 'recoil'
import { stylizedVersesAtom } from 'atoms'

const InsertMenu = ({ setShow }) => {
  const verses = useRecoilValue(stylizedVersesAtom)
  const [creatingVerse, setCreatingVerse] = useState(false)

  const customVersesList = useMemo(() => verses?.customVerses || [], [verses])

  const renderContent = () => {
    return creatingVerse ? (
      <CustomVerseEditorMenu
        setCreatingVerse={(value) => setCreatingVerse(value)}
        stylizedVerse={false}
      />
    ) : (
      <InsertCustomVerseMenu
        setShow={setShow}
        setCreatingVerse={(value) => setCreatingVerse(value)}
        verses={customVersesList}
        isCustom={true}
      />
    )
  }

  return <>{renderContent()}</>
}

export default InsertMenu
