import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { PrintPackageHeader } from './'
import { CardSelector } from 'components'
import Text from 'components/priority/Text/Text'
import { getAllCollections } from 'api/collections'
import { collectionsAtom, selectedProductLineAtom } from 'atoms'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ caseInfo }) => {
  const handleError = useErrorHandler('ProductLine')
  const [collections, setCollections] = useRecoilState(collectionsAtom)
  const getCollections = async () => {
    try {
      const { data } = await getAllCollections()
      const parsedData = data.map((collection) => ({
        id: collection.id,
        image: collection.image,
      }))
      setCollections(parsedData)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    getCollections()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <PrintPackageHeader title="Choose a product line" caseInfo={caseInfo} />
      <CardSelector
        cardAlign="center"
        cardJustify="center"
        padding="32px"
        cards={collections}
        cardWidth="375px"
        cardHeight="378px"
        imageWidth="369px"
        imageHeight="378px"
        productIcon
        isProductLine={true}
        selectedState={useRecoilState(selectedProductLineAtom)}
      />
      <Text size="14px" color="gray2" align="center" justify="center">
        Note: collections may contain unique products. If you can’t find your
        product, return to this step and change collections.
      </Text>
    </>
  )
}
