import { useState, useEffect } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { getOrderProducts as getOrderProductsApi } from 'api/orderProducts'
import { getOrderLayouts } from 'api/orderLayouts'
import {
  selectedProductLineAtom,
  selectedProductsSelector,
  selectedThemeAtom,
  orderAtom,
  selectedProductsAtom,
  productTabAtom,
} from 'atoms'
import { PrintPackageHeader, CustomizeProduct } from './'
import { CustomizeTabs } from 'components'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { getLayoutById } from 'api/layouts'

export default ({ caseInfo }) => {
  const handleError = useErrorHandler('CustomizePrint')
  const selectedProductLine = useRecoilValue(selectedProductLineAtom)
  const selectedTheme = useRecoilValue(selectedThemeAtom)
  const order = useRecoilValue(orderAtom)
  const [fetchedOrderProducts, setOrderProducts] =
    useRecoilState(selectedProductsAtom)
  const [storedProductTab, setStoredProductTab] = useRecoilState(productTabAtom)
  const [selectedProducts] = useRecoilState(selectedProductsSelector)
  const [activeTab, setActiveTab] = useState('')
  const [mappedProductsArr, setMappedProductsArr] = useState([])
  const [productsTabs, setProductTabs] = useState([])

  const getOrderProducts = async () => {
    try {
      const { data: orderProductsData } = await getOrderProductsApi(
        order.collection_id,
        order.id,
      )
      setOrderProducts(orderProductsData)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const mapLayouts = async (products) => {
    try {
      let mappedProducts = []
      for (const product of products) {
        if (product.id) {
          const { data: orderLayouts } = await getOrderLayouts(product.id)
          const layoutId = orderLayouts[0].layout_id
          const { data: layout } = await getLayoutById(layoutId)
          const { background_type } = layout
          const mappedProductObject = {
            ...product,
            layouts: orderLayouts,
            background_type,
          }
          mappedProducts.push(mappedProductObject)
        }
      }
      setMappedProductsArr(mappedProducts)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    order.id && getOrderProducts()
    //eslint-disable-next-line
  }, [order])

  useEffect(() => {
    fetchedOrderProducts && mapLayouts(fetchedOrderProducts)
    //eslint-disable-next-line
  }, [
    fetchedOrderProducts,
    selectedProductLine,
    selectedTheme,
    selectedProducts,
  ])

  const getProductTitle = (product) => {
    let title = product.name.includes('Register Book:')
      ? product.name.split(': ')[1]
      : product.name

    const isFinalized = product.status === 'FINALIZED'
    if (isFinalized) title = `${title} ✓`

    return title
  }

  const getTabs = () => {
    const sortedProductsAndLayouts = mappedProductsArr
      .filter((product) => product.selected)
      .sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })

    return sortedProductsAndLayouts.map((product) => {
      const storedProductTabWithoutCheckMark = storedProductTab
        ? storedProductTab.replace('✓', '').trim()
        : null

      const storedTabExists =
        storedProductTabWithoutCheckMark === null
          ? null
          : product.name === storedProductTabWithoutCheckMark

      let title = getProductTitle(product)

      if (storedTabExists) {
        // There is a stored tab.
        // We set the stored tab as the active tab.
        setActiveTab(title)
        setStoredProductTab(title)
      } else if (storedTabExists === null) {
        // There is no stored tab or it doesn't exist:
        // We set the first tab as the active tab and the stored tab.
        const firstTabTitle = getProductTitle(sortedProductsAndLayouts[0])
        setActiveTab(firstTabTitle)
        setStoredProductTab(firstTabTitle)
      }

      return {
        title,
        content: (
          <CustomizeProduct
            productInfo={product}
            productLineInfo={selectedProductLine}
            themeInfo={selectedTheme}
            productsAndLayouts={sortedProductsAndLayouts}
            getOrderProducts={getOrderProducts}
          />
        ),
      }
    })
  }

  // const removeProduct = (tab) => {
  //   setSelectedProducts(tab.content.props.productInfo)
  // }

  const setActiveTabAndPersist = (tab) => {
    setActiveTab(tab)
    setStoredProductTab(tab)
  }

  useEffect(() => {
    const tabs = getTabs()
    setProductTabs(tabs)
    //eslint-disable-next-line
  }, [mappedProductsArr, selectedProducts])

  return (
    <>
      <PrintPackageHeader
        title="Proof/Finalize Production"
        caseInfo={caseInfo}
        margin="0 0 32px"
      />
      <CustomizeTabs
        tabs={productsTabs}
        width="250px"
        selected={[activeTab, setActiveTabAndPersist]}
      />
    </>
  )
}
