import { useState } from 'react'
import { Input, Dropdown, Button } from 'components'
import Text from 'components/priority/Text/Text'
import Flex from 'components/priority/Flex/Flex'
import { useAuth0 } from '@auth0/auth0-react'
import { create } from 'api/users'
import { createFuneralHome } from 'api/funeralHomes'
import states from 'states-us'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({
  funeralHomeNameError,
  setFuneralHomeNameError,
  funeralHomePayload,
  handleSetFuneralHomePayload,
  userPayload,
  funeralHomeNameInput,
}) => {
  const handleError = useErrorHandler('StepTwo')
  const [funeralHomePhoneNumberError, setFuneralHomePhoneNumberError] =
    useState(' ')
  const { loginWithRedirect } = useAuth0()

  const handleFuneralHomeNameBlur = () =>
    setFuneralHomeNameError(
      funeralHomePayload.name.length === 0
        ? 'Funeral home name is required'
        : ' ',
    )

  const handleFuneralHomePhoneNumberBlur = () =>
    setFuneralHomePhoneNumberError(
      funeralHomePayload.phone.length < 7
        ? 'Phone number must be at least 7 digits'
        : ' ',
    )

  const submit = async () => {
    try {
      const { data } = await createFuneralHome(funeralHomePayload)

      if (data?.id) {
        const userPayloadCopy = { ...userPayload }
        userPayloadCopy.funeral_home_id = data.id
        const { data: userData } = await create(userPayloadCopy)

        if (userData?.id) {
          loginWithRedirect({
            login_hint: userPayload?.email,
            screen_hint: 'signup',
          })
        }
      }
    } catch (e) {
      handleError(e?.response?.data?.message || e.message, e)
    }
  }
  return (
    <>
      <Text size="12px" margin="0 0 8px 0">
        Step 2 of 2
      </Text>
      <Text weight="600" size="16px" margin="0 0 32px 0">
        Funeral home information
      </Text>
      <Input
        label="Funeral home name"
        placeholder="Funeral home name"
        value={[
          funeralHomePayload.name,
          (value) => handleSetFuneralHomePayload('name', value),
        ]}
        margin="0 0 8px 0"
        ref={funeralHomeNameInput}
        onBlur={handleFuneralHomeNameBlur}
      />
      <Text size="14px" color="error" margin="0 0 10px 0">
        {funeralHomeNameError}
      </Text>
      <Input
        label="Phone number"
        placeholder="Phone number"
        value={[
          funeralHomePayload.phone,
          (value) => handleSetFuneralHomePayload('phone', value),
        ]}
        margin="0 0 8px 0"
        type="tel"
        pattern="^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
        minLength={7}
        maxLength={15}
        onBlur={handleFuneralHomePhoneNumberBlur}
      />
      <Text size="14px" color="error" margin="0 0 10px 0">
        {funeralHomePhoneNumberError}
      </Text>
      <Input
        label="Address"
        placeholder="Address"
        value={[
          funeralHomePayload.address,
          (value) => handleSetFuneralHomePayload('address', value),
        ]}
        margin="0 0 24px 0"
      />
      <Input
        label="City"
        placeholder="City"
        value={[
          funeralHomePayload.city,
          (value) => handleSetFuneralHomePayload('city', value),
        ]}
        margin="0 0 24px 0"
      />
      <Flex margin="0 0 24px 0">
        <Dropdown
          label="State"
          placeholder="Select a state"
          items={states}
          valueKey="abbreviation"
          textKey="name"
          selected={[
            funeralHomePayload.state,
            (value) => handleSetFuneralHomePayload('state', value),
          ]}
        />
        <Input
          label="Zip Code"
          placeholder="Zip"
          value={[
            funeralHomePayload.zip_code,
            (value) => handleSetFuneralHomePayload('zip_code', value),
          ]}
          margin="0 0 0 16px"
        />
      </Flex>
      <Button
        center
        primary
        margin="0 0 24px 0"
        onClick={submit}
        disabled={funeralHomePayload.name.length === 0}
      >
        Sign up
      </Button>
    </>
  )
}
