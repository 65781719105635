import { useEffect } from 'react'

const useStonlyWidget = () => {
  useEffect(() => {
    const scriptId = 'stonly-widget-script'
    const varScriptId = 'stonly-widget-var-script'

    if (!document.getElementById(scriptId)) {
      const initScript = document.createElement('script')
      initScript.id = scriptId
      initScript.textContent = `
        window.STONLY_WID = "73a6c0b9-d106-11ee-bc11-06cb0cb2a85e";
        !function(s,t,o,n,l,y,w,g,d,e){
          s.StonlyWidget||((d=s.StonlyWidget=function(){
            d._api?d._api.apply(d,arguments):d.queue.push(arguments)
          }).scriptPath=n,d.apiPath=l,d.sPath=y,d.queue=[],
          (g=t.createElement(o)).async=!0,(e=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),
          e.onreadystatechange=function(){
            if(4===e.readyState){
              g.src=n+"stonly-widget.js?v="+(200===e.status?e.responseText:Date.now());
              (w=t.getElementsByTagName(o)[0]).parentNode.insertBefore(g,w)
            }
          },e.send())
        }(window,document,"script","https://stonly.com/js/widget/v2/");
      `
      document.head.appendChild(initScript)
    }

    // Cleanup: Remove scripts when the component is unmounted
    return () => {
      const varScript = document.getElementById(varScriptId)
      const initScript = document.getElementById(scriptId)

      if (varScript) varScript.remove()
      if (initScript) initScript.remove()

      delete window.STONLY_WID
      delete window.StonlyWidget
    }
  }, [])
}

export default useStonlyWidget
