import { PostHogProvider} from 'posthog-js/react'

const PostHogWrapper = ({ children }: { children: React.ReactNode }) => {
  const posthogApiKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY
  const posthogHost = process.env.REACT_APP_PUBLIC_POSTHOG_HOST
  const keyExists = !!posthogApiKey
  const hostExists = !!posthogHost
  const isProduction = process.env.NODE_ENV === 'production'
  const posthogEnabled = isProduction && keyExists && hostExists

  if (!posthogEnabled) {
    return <>{children}</>
  }

  const options = {
    api_host: posthogHost,
  }

  return (
    <PostHogProvider 
      apiKey={posthogApiKey as string}
      options={options}
    >
      {children}
    </PostHogProvider>
  )
}

export default PostHogWrapper
