import { logger, EventCategory } from 'utils/logger'

export const applySelectedFontSizeToElement = (
  editor: Editor,
  querySelector: string,
) => {
  if (!editor || !querySelector) return
  const fontSizeValue = editor.queryCommandValue('FontSize') as string
  const editorBody = editor.getBody()

  try {
    if (!editorBody) return

    const targetElement = editorBody.querySelector(
      querySelector,
    ) as HTMLElement | null

    if (!targetElement) return

    targetElement.setAttribute('data-persistent-font-size', fontSizeValue)
    updateStyle(targetElement, 'style', fontSizeValue)
    updateStyle(targetElement, 'data-style', fontSizeValue)
    updateStyle(targetElement, 'data-mce-style', fontSizeValue)

    logger.info({
      message: 'font_size_applied_manually_success',
      category: EventCategory.FORMAT,
      data: {
        fontSizeValue,
        editorBody,
      },
    })
  } catch (error) {
    logger.error({
      message: 'font_size_applied_manually_failed',
      category: EventCategory.ERROR,
      error: new Error('Failed to apply font size to element manually', {
        cause: error,
      }),
      data: {
        fontSizeValue,
        editorBody,
      },
    })
  }
}

const updateStyle = (
  targetElement: HTMLElement,
  attribute: string,
  fontSizeValue: string,
) => {
  if (!attribute) return
  const currentElementStyle = targetElement.getAttribute(attribute) || ''
  const updatedElementStyle = updateFontSizeStyleAttribute(
    currentElementStyle,
    fontSizeValue,
  )
  targetElement.setAttribute(attribute, updatedElementStyle)
}

const updateFontSizeStyleAttribute = (
  styleString: string,
  fontSize: string,
) => {
  const styles = styleString
    .split(';')
    .map((s) => s.trim())
    .filter((s) => s.length > 0 && !s.startsWith('font-size'))

  styles.push(`font-size: ${fontSize}`)
  return styles.join('; ')
}
