import { load } from 'cheerio'
import type { CheerioAPI } from 'cheerio'
import type { AcceptedElems } from 'cheerio'
import preserveStylingUtilities from 'utils/tinyMCE/updateDynamicText/preserveStylingUtilities'

const preserveStyling = ({
  initialElement,
  templateElement,
}: {
  initialElement: AcceptedElems<any>
  templateElement: CheerioAPI
}) => {
  const { extractCurrentStyle, parseCSS, stringifyCSS } =
    preserveStylingUtilities()

  const currentStyle = extractCurrentStyle(initialElement)
  const $ = load('')
  const $element = $(initialElement)
  const existingStyle = $element.attr('style')
  const savedStyle = $element.attr('data-style') ?? null
  const persistentFontSize = $element.attr('data-persistent-font-size') ?? null
  let styling = null

  if (existingStyle) {
    const savedStyleObj = parseCSS(savedStyle)
    const existingStyleObj = parseCSS(existingStyle)
    const currentStyleObj = parseCSS(currentStyle)

    /* This will merge the css inline styles taking as priority the currentStyle styles  */
    const mergedStyleObj = {
      ...savedStyleObj,
      ...existingStyleObj,
      ...currentStyleObj,
    }

    styling = stringifyCSS(mergedStyleObj)
  } else {
    styling = currentStyle || existingStyle || savedStyle
  }

  if (styling) {
    const targetDiv = templateElement('div')
    if (targetDiv.length > 0) {
      if (persistentFontSize) {
        const updatedStyling = styling
          .split(';')
          .filter((style) => !style.trim().startsWith('font-size'))
          .concat(`font-size: ${persistentFontSize}`)
          .join(';')

        targetDiv
          .attr('style', updatedStyling)
          .attr('data-style', updatedStyling)
          .attr('data-persistent-font-size', persistentFontSize)
      } else {
        targetDiv.attr('style', styling).attr('data-style', styling)
      }
    } else {
      console.warn('Target div not found in template element')
    }
  }
}

export default preserveStyling
