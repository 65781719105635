/**
 * Browser agnostic handling for Autography font in TinyMCE (Chrome, Firefox - safari handles this fine)
*/
export const setupAutographyFontHandling = (editor: Editor) => {
  const editorNotReady = !editor || !editor.selection
  if (editorNotReady) {
    console.warn('[Autography Fix] Editor not ready, aborting setup')
    return
  }
  
  const checkForAutographyFont = () => {
    try {
      const node = editor.selection.getNode() as HTMLElement;
      if (!node) return false
      
      const nodeStyle = node?.style
      const nodeFontFamily = nodeStyle?.fontFamily || ''
      const nodeIncludesAutography = nodeFontFamily.includes('Autography')
      if (nodeIncludesAutography) return true
      
      const children = node?.querySelectorAll('*');
      const hasAutographyChild = Array.from(children || []).some((child) => {
        const childElement = child as HTMLElement;
        const childStyle = childElement?.style
        const childFontFamily = childStyle?.fontFamily || ''
        return childFontFamily.includes('Autography')
      })
      if (hasAutographyChild) return true
      
      const computedStyle = window?.getComputedStyle(node);
      const computedFontFamily = computedStyle?.fontFamily || ''
      const computedIncludesAutography = computedFontFamily.includes('Autography')
      if (computedIncludesAutography) return true
      return false
    } catch (error) {
      return false;
    }
  };

  editor.on('keypress', (e) => {
    try {
      const spaceKeyPressed = e.keyCode === 32 || e.key === ' '
      const autographyFontSelected = checkForAutographyFont()
      const notAutographyFontOrSpaceKeyPressed = !spaceKeyPressed || !autographyFontSelected
      if (notAutographyFontOrSpaceKeyPressed) return;
      e.preventDefault();
      editor.execCommand('mceInsertContent', false, '&nbsp;');
    } catch (error) {
      console.warn('[Autography Font Spacing Fix] Space handler error:', error);
    }
  });
}
