import {
  editorPagesAtom,
  selectedPageAtom,
  objectSelector,
  itemIdAtom,
  itemAtomFamily,
  pageLayoutAtom,
  watermarkIdAtom,
  watermarkAtomFamily,
} from 'atoms'
import { default as _omit } from 'lodash/omit'
import { useRecoilValue, useRecoilCallback, useSetRecoilState } from 'recoil'
import { logger, EventCategory } from 'utils/logger'
import { useRecoilLogData } from 'utils/recoilLogger'

export default () => {
  const recoilLogData = useRecoilLogData()
  const selectedPage = useRecoilValue(selectedPageAtom)

  const setEditorPages = useSetRecoilState(
    objectSelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.items`,
    }),
  )

  const setEditorWatermarks = useSetRecoilState(
    objectSelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.watermarks`,
    }),
  )

  const setEditorPagesProduct = useSetRecoilState(
    objectSelector({
      atom: editorPagesAtom,
      property: `[${selectedPage - 1}].product.themeLayout`,
    }),
  )

  const saveItems = useRecoilCallback(({ snapshot }) => async () => {
    let release = snapshot.retain()
    try {
      await new Promise((resolve) => setTimeout(resolve, 50))

      const ids = await snapshot.getPromise(itemIdAtom)
      const allItems = []

      for (const id of ids) {
        const item = await snapshot.getPromise(itemAtomFamily(id))
        if (item?.type) {
          allItems.push(JSON.parse(JSON.stringify(item)))
        }
      }

      await new Promise((resolve) => {
        setEditorPages(allItems)
        setTimeout(resolve, 50)
      })

      const watermarks = await snapshot.getPromise(watermarkIdAtom)
      const watermarkItems = []
      for (const id of watermarks) {
        const item = await snapshot.getPromise(watermarkAtomFamily(id))
        if (item?.type) {
          watermarkItems.push(JSON.parse(JSON.stringify(item)))
        }
      }

      if (watermarkItems.length > 0) {
        await new Promise((resolve) => {
          setEditorWatermarks(watermarkItems)
          setTimeout(resolve, 50)
        })
      }

      const updatedThemeLayout = await snapshot.getPromise(pageLayoutAtom)

      const omit = [
        'archived',
        'available',
        'id',
        'layout_id',
        'paper_type',
        'product_id',
      ]

      const themeLayout = _omit(updatedThemeLayout, omit)

      await new Promise((resolve) => {
        setEditorPagesProduct(themeLayout)
        setTimeout(resolve, 50)
      })

      logger.info({
        message: 'editor_save_items_success',
        category: EventCategory.SAVE,
        data: {
          ...recoilLogData,
        },
      })
    } catch (error) {
      logger.error({
        message: 'editor_save_failed',
        category: EventCategory.ERROR,
        error: error,
        data: {
          ...recoilLogData,
        },
      })
    } finally {
      release()
    }
  })

  return () => saveItems()
}
