import axios from 'api/axios.config'

export const getCustomVerses = async (
  themeId,
  baseProductId,
  orderProductId,
) => {
  try {
    const params = {}

    if (themeId) params.themeId = themeId
    if (baseProductId) params.baseProductId = baseProductId
    if (orderProductId) params.orderProductId = orderProductId
    const response = await axios.get(`customVerses`, { params })
    return response
  } catch (error) {
    throw error
  }
}

export const uploadStylizedVerseImage = async (payload) => {
  try {
    const response = await axios.post(`uploadVerseImage`, payload, {
      headers: { 'content-type': 'form-data' },
    })
    return response
  } catch (error) {
    throw error
  }
}

export const removeStylizedVerseImage = async (verse_id) => {
  try {
    const response = await axios.delete(`customVerses/image/${verse_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const createCustomVerse = async (payload) => {
  try {
    const response = await axios.post(`createCustomVerse`, payload)
    return response

    // expected payload:
    // {
    //   html: <String> (REQUIRED)
    //   html_for_render: <String> (REQUIRED),
    //   reference: <String> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

export const updateCustomVerse = async (id, payload) => {
  try {
    const response = await axios.patch(`customVerses/${id}`, payload)
    return response

    // expected payload:
    // {
    //   html: <String> (REQUIRED)
    //   html_for_render: <String> (REQUIRED),
    //   reference: <String> (REQUIRED),
    // }
  } catch (error) {
    throw error
  }
}

export const deleteCustomVerse = async (id) => {
  try {
    const response = await axios.delete(`customVerses/${id}`)
    return response
  } catch (error) {
    throw error
  }
}
