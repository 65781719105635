import { useCallback, useEffect, useMemo, useState } from 'react'
import type { PropsWithChildren } from 'react'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { userAtom, funeralHomeAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import axios from 'api/axios.config'
import { user as userApi } from 'api/users'
import { getFuneralHome } from 'api/funeralHomes'
import {
  Archived,
  Landing,
  PdfRender,
  AlignmentSheet,
  SignUp,
  BetaWelcome,
  FamilyUpload,
  NewUser,
} from 'components'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import Auth from 'components/auth/Auth'
import { useAdminValidation } from 'hooks/utility/useAdminValidation'
import type { GetUserApiResponse } from 'types/user'
import type { AuthProviderProps, LogoutOptions } from 'types/auth'
import type { GetFuneralHomeApiResponse } from 'types/funeralHome'
import type { APIError } from 'types/api'
import { usePostHog } from 'posthog-js/react'
import { identifyUserInPosthog } from 'utils/global/posthog'

// TODO: remove this after converting PdfRender component to typescript
const PdfRenderAny = PdfRender as any

const AuthProvider = ({ children, isAuthenticated }: AuthProviderProps) => {
  const handleError = useErrorHandler('AuthProvider')
  const setUser = useSetRecoilState(userAtom)
  const setFuneralHome = useSetRecoilState(funeralHomeAtom)
  const [token, setToken] = useState('')
  const [error, setError] = useState('')
  const [headerSet, setHeaderSet] = useState(isAuthenticated)
  const logout = usePerformLogout()
  const posthog = usePostHog()

  const location = useMemo(() => [window.location.pathname], [])

  const { user, getAccessTokenSilently, isLoading } = useAuth0()

  const localStorageOrderIdToken = localStorage.getItem('messengerOrderIdToken')

  useEffect(() => {
    if (!isAuthenticated) {
      user && getAccessTokenSilently().then(setToken)
    }
  }, [user, getAccessTokenSilently, isAuthenticated, localStorageOrderIdToken])

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      setHeaderSet(true)
    }
  }, [token])

  useEffect(() => {
    if (!isAuthenticated) {
      ;(async () => {
        if (token) {
          try {
            const { data: loggedInUser, status } = (await userApi(
              token,
            )) as GetUserApiResponse
            // QUESTION: archived is not returned from userApi.
            // auth middleware in BE changes it, so model and what client receives are different
            // therefore, not sure how this functionality works, so we'll type check this way for now
            // remove QUESTION after confirmation
            if ('archived' in loggedInUser && loggedInUser.archived) {
              return logout({
                returnTo: `${window.location.origin}/archived`,
                redirect: true,
              })
            }
            const home = (await getFuneralHome(token).catch(function (
              error: APIError,
            ) {
              if (
                error.response &&
                'Missing funeral home identification.' ===
                  error.response.data?.message
              ) {
                logout({
                  returnTo: `${window.location.origin}/new-user`,
                  redirect: true,
                })
              }
            })) as GetFuneralHomeApiResponse

            // TODO: uncomment this when we have a backend fix
            // const { data: logos } = await getFuneralHomeLogos()

            if (loggedInUser.id) {
              setUser(loggedInUser)

              try {
                identifyUserInPosthog(posthog, loggedInUser, home?.id)
              } catch (error) {
                console.error('PostHog identification failed:', error)
              }
              
              // TODO: uncomment this when we have a backend fix
              // home.logo = logos && (logos.small || logos.medium || logos.large)
              // QUESTION: statusCode is not returned from userData, using perceived actual value from axios response (status) in addition to statusCode
              // Remove QUESTION after confirmation (and update code)
            } else if (
              ('statusCode' in loggedInUser &&
                loggedInUser.statusCode === 401) ||
              status === 401
            ) {
              setError('User error')
            } else {
              setError('Display a Toast?')
              logout({ returnTo: window.location.origin, redirect: true })
            }
            home?.id && setFuneralHome(home)
          } catch (error) {
            const err = error as APIError & Error
            handleError(err?.response?.data?.message || err.message, err)
          }
        }
      })()
    }
    // eslint-disable-next-line
  }, [token, logout, posthog])

  return (
    <>
      {location[0].includes('auth') ? (
        <Auth />
      ) : location[0].includes('new-user') ? (
        <NewUser />
      ) : location[0].includes('archived') ? (
        <Archived />
      ) : location[0].includes('pdfProduct') ? (
        <PdfRenderAny isPDF={true} />
      ) : location[0].includes('pdfProofProduct') ? (
        <PdfRenderAny proof={true} isPDF={true} />
      ) : location[0].includes('pdfAlign') ? (
        <AlignmentSheet />
      ) : location[0].includes('sign-up') ? (
        <SignUp />
      ) : location[0].includes('beta-welcome') ? (
        <BetaWelcome />
      ) : location[0].includes('familyUpload') ? (
        <FamilyUpload />
      ) : (!isAuthenticated && isLoading && !user?.id) ||
        (!headerSet && !isAuthenticated) ? (
        <Landing error={null} />
      ) : (
        children
      )}
      {error && <Landing error={error} />}
    </>
  )
}

const authEnvsAvailable = (): boolean => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN as string | undefined
  const clientId = process.env.REACT_APP_AUTH0_CLIENTID as string | undefined
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE as string | undefined

  if (!domain || !clientId || !audience) {
    throw new Error(
      'Missing required Auth0 environment variables. Check REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENTID, and REACT_APP_AUTH0_AUDIENCE',
    )
  }
  return true
}

const Auth0 = ({ children }: PropsWithChildren<unknown>) => {
  useAdminValidation()
  const localStorageOrderIdToken = localStorage.getItem('messengerOrderIdToken')
  const isOrderIdTokenSet = !!localStorageOrderIdToken

  if (!authEnvsAvailable()) {
    return null
  }

  return isOrderIdTokenSet ? (
    <AuthProvider isAuthenticated={true}>{children}</AuthProvider>
  ) : (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID!}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
      redirectUri={window.location.origin}
    >
      <AuthProvider isAuthenticated={false}>{children}</AuthProvider>
    </Auth0Provider>
  )
}

export default Auth0

export const usePerformLogout = () => {
  const { logout, user } = useAuth0()
  const setUser = useSetRecoilState(userAtom)

  return useCallback(
    ({ redirect = true, redirectTo = '/' }: LogoutOptions = {}) => {
      const localStorageOrderIdToken = localStorage.getItem(
        'messengerOrderIdToken',
      )
      const isAuthenticatedWithLocalStorage = !!localStorageOrderIdToken

      if (isAuthenticatedWithLocalStorage) {
        localStorage.removeItem('messengerOrderIdToken')
        setUser(null as any)
      }

      if (user) {
        logout({
          returnTo: window.location.origin,
        })
      } else if (redirect) {
        window.location.href = redirectTo
      }
    },
    [logout, setUser, user],
  )
}
