import { useState } from 'react'
import { uploadOptimizedCaseImage, deleteCaseImage } from 'api/cases'
import { FileDropzone } from 'components'
import { PersonalImageCard } from 'components/dashboard/CaseDetails'
import Flex from 'components/priority/Flex/Flex'
import { useRecoilState } from 'recoil'
import { caseValuesAtom } from 'atoms'
import { useSetToast } from 'hooks'
import optimizeImage from 'utils/editor/optimizeImage'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { SidebarMenuSaveSection } from 'components/editor/InsertMenu/SidebarMenuSaveSection'

export default ({ saveCase, case_id, name_of_deceased }) => {
  const handleError = useErrorHandler('ImagesAndFamilyMemorialsMenu')
  const [personalImages, setPersonalImages] = useRecoilState(
    caseValuesAtom('personal_images'),
  )

  const [imagesLoading, setImagesLoading] = useState(false)
  const setToast = useSetToast()

  const removeArrayInput = async (index) => {
    try {
      //this endpoint apparently also deletes the image from s3, something we should do more of, lol
      const resp = await deleteCaseImage(case_id, {
        filename: personalImages.value[index],
      })
      setPersonalImages({ ...personalImages, value: resp.data })
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const makeDefault = (index) => {
    let newArr = [...personalImages.value]
    const removedElement = newArr.splice(index, 1)
    newArr = [...removedElement, ...newArr]
    setPersonalImages({ ...personalImages, value: newArr })
  }

  const uploadCaseImages = async (filesArray) => {
    setImagesLoading(true)
    try {
      const newImages = await Promise.all(
        filesArray.map((file) => uploadCaseImage(file)),
      )
      await setPersonalImages((prevState) => {
        const updatedImages = [...prevState.value, ...newImages.flat()]
        return {
          ...prevState,
          value: updatedImages,
        }
      })
    } catch (error) {
      setToast({
        text: 'Some images failed to upload.',
      })
    } finally {
      setImagesLoading(false)
    }
  }

  const uploadCaseImage = async (fileObject) => {
    const processFile = async (fileObject) => {
      try {
        const image = new Image()
        const reader = new FileReader()
        reader.onload = (e) => {
          image.src = e.target.result
        }
        reader.onerror = (error) => {
          console.error('Error reading file:', error)
          setToast({ text: 'Failed to read the file.' })
          throw error
        }
        reader.readAsDataURL(fileObject)
        await new Promise((resolve, reject) => {
          image.onload = resolve
          image.onerror = reject
        })
        const optimizedImageDataURL = await optimizeImage(
          image,
          2560,
          1440,
          0.75,
        )
        const blob = await (await fetch(optimizedImageDataURL)).blob()
        let fd = new FormData()
        fd.append('file', blob, fileObject.name)
        const resp = await uploadOptimizedCaseImage(case_id, fd)
        setToast({
          text: resp.data ? 'Image Uploaded!' : 'Image upload failed.',
        })
        return resp.data
      } catch (error) {
        handleError(
          error?.response?.data?.message || error.message,
          error,
          null,
          'uploadCaseImage',
        )
        throw error
      }
    }

    return processFile(fileObject)
  }

  return (
    <>
      <Flex full center padding="8px 8px 100px 8px" gap="8px" column>
        <Flex full column>
          <FileDropzone
            height="200px"
            margin="0 24px 24px 24px"
            text={`Drag and drop file here to edit`}
            fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
            allowMultiple={true}
            onDrop={(files) => uploadCaseImages(files)}
            isLoading={imagesLoading}
          />
          {personalImages?.value?.map((image, index) => (
            <PersonalImageCard
              key={`personal-image-${image}`}
              image={image}
              index={index}
              makeDefault={makeDefault}
              deleteImage={removeArrayInput}
              margin="0 24px 24px 24px"
            />
          ))}
          {/*  <Text size="16px" weight="600" margin="0 24px 24px 24px">
            {'Memorials'}
          </Text>
          <Memorials />
          <Text size="16px" weight="600" margin="0 24px 24px 24px">
            {'Invite Family to Share Images'}
          </Text>
          <SendInvite case_id={case_id} name_of_deceased={name_of_deceased} /> */}
        </Flex>
      </Flex>
      <SidebarMenuSaveSection saveCase={saveCase} />
    </>
  )
}
