import * as S from './Grid.styled'
import { ReactNode } from 'react'
import type { SizingProps } from 'styles/sizing'
import type { SpacingProps } from 'styles/spacing'

type GridProps = {
  children: ReactNode
  autoWidth?: string
  columns?: number
  rows?: number
  gap?: string
} & SizingProps &
  SpacingProps

const Grid = ({ children, ...props }: GridProps) => (
  <S.Grid {...props}>{children}</S.Grid>
)

export default Grid
