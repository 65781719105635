import hexOpacity from 'utils/styling/hexOpacity'
import type { HexOpacityMap, DropShadow } from 'types/style'

const isValidDropShadow = (shadow: DropShadow | undefined) => {
  if (!shadow) return false
  const isObject = typeof shadow === 'object'
  const hasColor = 'color' in shadow
  const hasKeys = Object.keys(shadow).length > 0
  const isValid = isObject && hasColor && hasKeys
  if (!isValid) return false
  return isValid
} 

const getBoxShadow = (edits: {
  dropShadow?: DropShadow
  boxShadow?: string
  feather?: boolean
}) => {
  const isFeatheringEdges = edits?.feather
  if (isFeatheringEdges) return 'none'
  if (!isValidDropShadow(edits?.dropShadow)) return edits?.boxShadow
  const { xOffset = 0, yOffset = 0, blur = 0, spread = 0, color = '', opacity = 1 } = edits?.dropShadow as DropShadow
  return `${xOffset}px ${yOffset}px ${blur}px ${spread}px ${color}${
    hexOpacity[Math.floor(opacity * 100) as keyof HexOpacityMap] || ''
  }`
}

export default getBoxShadow
