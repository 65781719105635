import { useEffect } from 'react'

const useDynamicStyles = (cssString: string) => {
  useEffect(() => {
    if (typeof cssString !== 'string' || !cssString.trim()) {
      console.warn('Invalid cssString provided to useDynamicStyles')
      return
    }

    try {
      const style = document.createElement('style')
      style.textContent = cssString
      document.head.appendChild(style)

      return () => {
        document.head.removeChild(style)
      }
    } catch (error) {
      console.error('Error in useDynamicStyles:', error)
    }
  }, [cssString])
}

export default useDynamicStyles
