import { useState } from 'react'
import { Button, Input } from 'components'
import Flex from 'components/priority/Flex/Flex'
import Text from 'components/priority/Text/Text'
import Tooltip from 'components/global/Tooltip/Tooltip'
import {
  editorPagesAtom,
  editorLayoutAtom,
  themeLayoutAtom,
  themeIdAtom,
} from 'atoms/editorAtoms'
import { useRecoilValue } from 'recoil'
import { useSetToast } from 'hooks'
import { create } from 'api/pageSets'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { createPageSetPages } from 'utils'

export default ({ setShow, basePages }) => {
  const handleError = useErrorHandler()
  const [layoutIsDefault, setLayoutIsDefault] = useState(false)
  const [layoutName, setLayoutName] = useState(null)
  const [savingLayout, setSavingLayout] = useState(false)
  const editorPages = useRecoilValue(editorPagesAtom)
  const layout = useRecoilValue(editorLayoutAtom)
  const theme = useRecoilValue(themeLayoutAtom) || useRecoilValue(themeIdAtom)
  const setToast = useSetToast()

  const createPageSet = async () => {
    try {
      //TODO: for now all templates are home templates, add a toggle for super admins
      const pageSetPayload = {
        name: layoutName,
        layout_id: layout,
        theme_id: theme.theme_id || theme,
        home_template: true,
        default: layoutIsDefault,
      }
      const { data } = await create(pageSetPayload)
      return data
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const _createPageSetPages = async (pageSet) => {
    try {
      await createPageSetPages({
        basePages,
        editorPages,
        pageSet,
      })
      //do we need to do something with new pages?
      setToast({ text: 'Layout saved!' })
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const createSavedLayout = async () => {
    try {
      setSavingLayout(true)
      const pageSet = await createPageSet()
      await _createPageSetPages(pageSet)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    } finally {
      setSavingLayout(false)
    }
  }

  return (
    <Flex margin="16px 24px" column>
      <Flex column margin="24px 0">
        <Text>
          Create a new reusable custom layout using your current progress on
          this product.
        </Text>
      </Flex>
      <Flex
        justify="space-between"
        align="center"
        padding="16px 0 8px"
        borderTop="1px solid #B0B6BC"
      >
        <Tooltip text="Change name of this saved layout" margin="0 12px 0 0">
          <Input
            label="Layout Name"
            placeholder="Layout name"
            value={[layoutName, setLayoutName]}
          />
        </Tooltip>
        <Tooltip text="Make this layout default" margin="0 12px 0 0">
          <ToggleSwitch
            label="Default"
            value={[layoutIsDefault, setLayoutIsDefault]}
            margin="0 16px"
          />
        </Tooltip>
      </Flex>
      <Flex justify="right" align="center" margin="24px 16px">
        <Button disabled={savingLayout} onClick={createSavedLayout}>
          {savingLayout ? 'Saving layout...' : 'Create Saved Layout'}
        </Button>
      </Flex>
    </Flex>
  )
}
