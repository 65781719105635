import Flex from 'components/priority/Flex/Flex'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useRecoilState, useResetRecoilState } from 'recoil'
import {
  showRulerAtom,
  insetRulerAtom,
  outsideCanvasItemsVisibleAtom,
  showRedMarginAtom,
  itemsLockedAtom,
  selectedItemAtom,
  selectedItemsAtom,
} from 'atoms'

export default () => {
  const [showRuler, setShowRuler] = useRecoilState(showRulerAtom)
  const [lockItems, setLockItems] = useRecoilState(itemsLockedAtom)
  const [showRedMargin, setShowRedMargin] = useRecoilState(showRedMarginAtom)
  const [insetRuler, setInsetRuler] = useRecoilState(insetRulerAtom)
  const [outsideCanvasItemsVisible, setOutsideCanvasItemsVisible] =
    useRecoilState(outsideCanvasItemsVisibleAtom)
  const resetSelectedItemAtom = useResetRecoilState(selectedItemAtom)
  const resetSelectedItemsAtom = useResetRecoilState(selectedItemsAtom)

  const _lockItems = (toggledLockItemsState) => {
    setLockItems(toggledLockItemsState)
    resetSelectedItemAtom()
    resetSelectedItemsAtom()
  }

  return (
    <Flex column gap="32px" padding="0px 24px">
      <Flex column gap="12px">
        <ToggleSwitch
          value={[showRuler, setShowRuler]}
          label="Show ruler"
          labelRight
        />
        <ToggleSwitch
          value={[insetRuler, setInsetRuler]}
          label="Inset ruler"
          labelRight
          disabled={!showRuler}
        />
      </Flex>
      <ToggleSwitch
        value={[outsideCanvasItemsVisible, setOutsideCanvasItemsVisible]}
        label="Show Design Canvas"
        labelRight
      />
      <ToggleSwitch
        value={[showRedMargin, setShowRedMargin]}
        label="Show margins"
        labelRight
      />
      <ToggleSwitch
        value={[lockItems, _lockItems]}
        label="Lock items"
        labelRight
      />
    </Flex>
  )
}
