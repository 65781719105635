import get from 'lodash/get'
import { isEmpty } from 'lodash'
import isDate from 'utils/dates/isDate'
import { formFieldKeys } from '@mdl/shared-consts'

export default (dynamicTextProps) => ({
  getCaseDetail: (element) => {
    const caseDetailAttribute = element.attr('data-case-detail')
    const caseDetailTableAttribute = element.attr('data-case-detail-table')

    const attribute = caseDetailAttribute || caseDetailTableAttribute

    const path = attribute?.split(' ')?.[0]
    const initialText = element.text()

    const { caseDetails, funeralHomeDetails } = dynamicTextProps.editorProps

    const caseDetail = get(caseDetails, path)
    const funeralHomeDetail = get({ funeralHomeDetails }, path)
    let value = caseDetail || funeralHomeDetail || '&#8202;'

    // Apply formatting for nickname and maiden name
    if (path === formFieldKeys.nickName && value && value !== '&#8202;') {
      value = `"${value}"`
    } else if (
      path === formFieldKeys.maidenName &&
      value &&
      value !== '&#8202;'
    ) {
      value = `(${value})`
    }

    const isPlaceholder = initialText.includes('{$')

    let type = 'string'

    if (isEmpty(value)) type = 'empty'
    if (isDate(value)) type = 'date'
    if (isPlaceholder) type = 'placeholder'

    // Special handling for name fields
    const nameFields = [
      formFieldKeys.prefix,
      formFieldKeys.firstName,
      formFieldKeys.nickName,
      formFieldKeys.middleName,
      formFieldKeys.maidenName,
      formFieldKeys.lastName,
      formFieldKeys.suffix,
    ]
    if (nameFields.includes(path) && isEmpty(value)) {
      type = 'emptyName'
    }

    return { path, value, initialText, type }
  },

  updateDeepestSpanText: (element, text) => {
    let deepestSpan = element

    // Recursively drill down to the deepest span
    while (deepestSpan.find('span').length > 0) {
      deepestSpan = deepestSpan.find('span').first()
    }

    const textContent = String(text || '')

    // Handle empty name fields
    if (textContent === '&#8202;' && element.attr('data-case-detail')) {
      const nameFields = [
        formFieldKeys.prefix,
        formFieldKeys.firstName,
        formFieldKeys.nickName,
        formFieldKeys.middleName,
        formFieldKeys.maidenName,
        formFieldKeys.lastName,
        formFieldKeys.suffix,
      ]

      if (nameFields.includes(element.attr('data-case-detail'))) {
        // If the span is already hidden, don't process it
        if (element.css('display') === 'none') {
          return
        }

        // Check for and handle space between spans
        const parent = element.parent()
        const elementIndex = parent.contents().index(element)
        const prevNode = parent.contents().eq(elementIndex - 1)

        if (
          prevNode.length &&
          prevNode[0].nodeType === 3 &&
          prevNode.text().trim() === ''
        ) {
          // Store that this span had a space before it
          element.attr('data-had-space', 'true')
          // Remove the space node
          prevNode.remove()
        }

        // Hide the span and its whitespace
        element.css('display', 'none')
        const prevSibling = element.prev()
        if (
          prevSibling &&
          prevSibling.is('span') &&
          prevSibling.text().trim() === ''
        ) {
          prevSibling.css('display', 'none')
        }
        return
      }
    }

    // If we're showing a previously hidden span
    if (element.css('display') === 'none' && textContent !== '&#8202;') {
      // Check if this span previously had a space before it
      if (element.attr('data-had-space') === 'true') {
        // Add a space before the span
        element.before(' ')
      }
      // Remove the tracking attribute
      element.removeAttr('data-had-space')
    }

    // Replace the text content of the deepest span
    deepestSpan
      .contents()
      .filter(function () {
        return this.type === 'text'
      })
      .replaceWith(textContent)
  },
})
