import { applySelectedFontSizeToElement } from 'utils/tinyMCE/updateDynamicText/applySelectedFontSizeToElement'

export default (editorProps) => {
  const { editor, updateHeight } = editorProps
  if (!updateHeight) return

  editor.on('ExecCommand', (e) => {
    const commandIsAbsent = !e || !e.command
    if (commandIsAbsent) return

    const command = e.command.toLowerCase()
    const approvedCommands = ['fontsize', 'lineheight', 'fontname']

    const isApprovedCommand = approvedCommands.includes(command)
    if (!isApprovedCommand) return

    const isFontSize = command === 'fontsize'

    if (isFontSize) {
      applySelectedFontSizeToElement(editor, '[data-case-detail="obituary"]')
    }

    updateHeight()
  })
}
