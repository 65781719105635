import getThemeStylesForTemplate from '../templates/getThemeStylesForTemplate'
import { DBTheme } from 'types/DBTheme'

const caseDetailSpan = (key: string, selectedTheme: DBTheme | null = null) => {
  let themeStyles = ''
  if (selectedTheme) themeStyles = getThemeStylesForTemplate({ selectedTheme })
  return `&#8202;<span class="mceNonEditable" data-case-detail="${key}" style="${themeStyles}">{$${key}}</span>&#8202;`
}

export default caseDetailSpan
