import { useCallback } from 'react'
import { useSetToast } from 'hooks'

export const useErrorHandler = (componentName: string) => {
  const setToast = useSetToast()

  const handleError = useCallback(
    (text: string, error: Error, icon: string = 'info') => {
      setToast({
        text:
          text || 'An unexpected error has occurred. Please try again later.',
        icon,
      })
      console.error(`An error ocurred in ${componentName}`, error)
    },
    [setToast, componentName],
  )

  return handleError
}
