import { useState, useEffect } from 'react'
import {
  funeralHomeAtom,
  objectKeySelector,
  showPinturaAtom,
  caseAtom,
  itemSelector,
  fhLogoAtom,
  rightMenuAtom,
} from 'atoms'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import * as S from './PageImage.styled'
import regEx from 'utils/global/regEx'
import getImageDimensions from 'utils/editor/getImageDimensions'
import { ImageFitter } from 'components/page/ImageFitter/ImageFitter'
import { useFlags } from 'launchdarkly-react-client-sdk'

export default ({
  itemRef,
  image: { url, editedUrl } = {},
  properties: { subtype, caseImage } = {},
  images,
  id,
  maintainAspectRatio,
  width,
  height,
  type,
  assetLocked,
  selected,
  itemLocked,
  cropX,
  cropY,
  ...props
}) => {
  const { imageFitter: imageFitterFlag } = useFlags()
  const setShowPintura = useSetRecoilState(showPinturaAtom)
  const activeMenu = useRecoilValue(rightMenuAtom)
  const isBackgroundMenu = activeMenu === 'Background'
  const showPintura = () => setShowPintura(true)
  const [imgSrc, setImgSrc] = useState(null)
  const [isFitting, setIsFitting] = useState(false)
  const fhLogo = useRecoilValue(fhLogoAtom)
  const logo = useRecoilValue(
    objectKeySelector({ atom: funeralHomeAtom, property: 'logo' }),
  )
  const _case = useRecoilValue(caseAtom)
  const { personal_images, filledImages } = _case || {}
  const [itemWidth, setItemWidth] = useRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'width' }),
  )
  const [itemHeight, setItemHeight] = useRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'height' }),
  )
  const setCropX = useSetRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'cropX' }),
  )
  const setCropY = useSetRecoilState(
    itemSelector({ atomFamily: type, id: id, path: 'cropY' }),
  )
  const cropXCoordinate = cropX || props?.cropX
  const cropYCoordinate = cropY || props?.cropY
  const isImageFitterEnabled = imageFitterFlag && !itemLocked && selected

  const isImageCropped = !!cropXCoordinate || !!cropYCoordinate

  const setImage = () => {
    let final
    const indexURL = `${filledImages?.[caseImage]}`
    const logoSrc = `${process.env.REACT_APP_S3BUCKET_FH_LOGOS}${
      logo || fhLogo
    }`
    const extractedUrl = url?.includes('url(')
      ? url
          .match(regEx.enclosedInParentheses)?.[1]
          ?.replace(regEx.singleDoubleQuote, '')
      : null

    if (url?.includes('placeholder') && filledImages?.[caseImage]) {
      final = indexURL
    } else if (subtype === 'logoImage' && (logo || fhLogo)) {
      final = logoSrc
    } else if (url?.includes('url(') && extractedUrl) {
      final = extractedUrl
    } else if (editedUrl) {
      final = editedUrl
    } else {
      final = url
    }

    setImgSrc(final)
  }

  const determineImageDimensions = async (width, src) => {
    try {
      const shouldMaintainAspectRatio = maintainAspectRatio || isImageCropped
      setItemWidth(width)
      if (shouldMaintainAspectRatio) {
        setItemHeight(height)
      } else {
        const imageDimensions = await getImageDimensions(src)
        setItemHeight(width * (imageDimensions.height / imageDimensions.width))
      }
    } catch (err) {
      console.log(src, err)
    }
  }

  useEffect(() => {
    setImage()
    //eslint-disable-next-line
  }, [
    personal_images,
    caseImage,
    subtype,
    url,
    editedUrl,
    logo,
    fhLogo,
    filledImages,
  ])

  useEffect(() => {
    if (!imgSrc) return
    determineImageDimensions(width, imgSrc)
    //eslint-disable-next-line
  }, [imgSrc])

  const handleDoubleClick = () => {
    const lockedAsset = assetLocked || isBackgroundMenu
    const isStylizedVerse = subtype === 'stylizedVerse'

    const pinturaDisabled = lockedAsset || isStylizedVerse
    if (pinturaDisabled) return

    showPintura()
  }

  return (
    <S.Image
      onDoubleClick={handleDoubleClick}
      ref={itemRef}
      imgSrc={imgSrc}
      width={itemWidth}
      height={itemHeight}
      isFitting={isFitting}
      type={type}
      {...props}
    >
      {isImageFitterEnabled && (
        <ImageFitter
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          setItemWidth={setItemWidth}
          setItemHeight={setItemHeight}
          setCropX={setCropX}
          setCropY={setCropY}
          setIsFitting={setIsFitting}
          isFitting={isFitting}
        />
      )}
    </S.Image>
  )
}
