import { useState, useEffect } from 'react'
import Flex from 'components/priority/Flex/Flex'
import { InsertImageGrid, LoadingInsertImages } from 'components'
import { getFuneralHomeLogos } from 'api/funeralHomes'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ setShow }) => {
  const handleError = useErrorHandler('FuneralHomeLogosMenu')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const sanitize = (data) => {
    delete data?.funeral_home_id
    return Object.entries(data).map(([key, value]) => ({
      name: key,
      image: value,
    }))
  }

  const getItems = async () => {
    try {
      setLoading(true)

      const { data } = await getFuneralHomeLogos()
      setItems(sanitize(data))

      setLoading(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    getItems()
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <LoadingInsertImages />
  ) : (
    <Flex full center>
      <InsertImageGrid
        items={items}
        srcPrefix={process.env.REACT_APP_S3BUCKET_FH_LOGOS}
        imageKey="image"
        nameKey="name"
        setShow={setShow}
      />
    </Flex>
  )
}
